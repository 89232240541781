import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import TargetTopicURL from "../../../../data/TargetTopicURL.json";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import { Link } from "react-router-dom";

export function UnfurledParentNode(props) {
  const { contentRef } = props;

  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const sectionDescription = displaySectionDescription(
    props.selectedNode.SectionContent,
  );
  const stateChosen = localStorage.getItem("uSStateLocalStorage");

  function displaySectionDescription(sectionContent) {
    if (sectionContent === undefined || sectionContent === null) {
      return false;
    }
    if (sectionContent.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function copyToClickBoard(e) {
    document.execCommand("copy");
  }

  const updatedWindowChange = () => {
    initialDisplayScrollArrow();
  };
  useEffect(() => {
    window.addEventListener("resize", updatedWindowChange);
    return () => {
      window.removeEventListener("resize", updatedWindowChange);
    };
  }, []);

  useEffect(() => {
    setScrollHeight(getScrollHeight);
    initialDisplayScrollArrow();
    return () => {};
  }, [contentRef]);

  function initialDisplayScrollArrow() {
    var hasVerticalScrollbar =
      contentRef.current.scrollHeight > contentRef.current.clientHeight;
    if (hasVerticalScrollbar) {
      setShowScrollArrow(true);
    } else {
      setShowScrollArrow(false);
    }
  }

  function displayOnPartScroll() {
    if (contentRef.current !== null) {
      var scrollClientDifference = Math.abs(
        contentRef.current.scrollHeight - contentRef.current.clientHeight,
      );

      var scrollDissapearPoint = scrollClientDifference * 0.8;
      if (contentRef.current.scrollTop <= scrollDissapearPoint) {
        setShowScrollArrow(true);
      } else {
        setShowScrollArrow(false);
      }
    }
  }

  function getScrollHeight() {
    let scrollRefStyle = window.getComputedStyle(contentRef.current);

    let verticalPadding =
      //parseFloat(scrollRefStyle.getPropertyValue("padding-top")) +
      parseFloat(scrollRefStyle.getPropertyValue("padding-bottom"));

    return contentRef.current.scrollHeight - verticalPadding;
  }

  function checkCurriculumStandard(subTopic) {
    if (subTopic.CurriculumStandardFlag) {
      for (
        let index = 0;
        index < subTopic.CurriculumStandards.length;
        index++
      ) {
        if (
          subTopic.CurriculumStandards[index].selected_state === stateChosen
        ) {
          return true;
        }
      }
    }
  }

  return (
    <Grid container className={props.classes.InnerParentGrid}>
      <div className={props.classes.ExitWindow}>
        <div style={{ position: "absolute", right: "16px" }}>
          <IconButton
            onClick={props.handleClose}
            style={{
              color: "white",
              padding: "0",
              display: "block",
            }}
          >
            <CancelPresentationIcon className={props.classes.CancelIcon} />
          </IconButton>
        </div>
      </div>
      <Grid
        ref={contentRef}
        direction="row"
        container
        item
        justify="space-between"
        alignItems="center"
        className={props.classes.ContentDefinition}
        onScroll={() => {
          displayOnPartScroll();
        }}
      >
        {showScrollArrow && (
          <div
            className={props.classes.ScrollArrowPosition}
            style={{
              height: scrollHeight,
            }}
          >
            <div
              style={{
                position: "-webkit-sticky",
                position: "sticky",
                top: "100%",
              }}
            >
              <ArrowDownwardIcon
                className={props.classes.DownwardArrowScroll}
              />
            </div>
          </div>
        )}
        <Grid
          container
          item
          justify="center"
          className={props.classes.SectionTitle}
        >
          <Grid justify="center" item>
            <Typography
              variant={props.lessThan750px ? "h4" : "h3"}
              style={{ fontFamily: "'Bebas Neue', cursive" }}
            >
              {props.selectedNode.SectionTitle}
            </Typography>
          </Grid>
        </Grid>
        {sectionDescription ? (
          <Grid
            container
            item
            justify="center"
            style={
              {
                //paddingBottom: "1rem",
              }
            }
          >
            <Typography
              variant="body1"
              style={{
                color: "white",
                fontFamily: "'Open Sans', sans-serif",
                maxWidth: "1000px",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: props.selectedNode.SectionContent,
                }}
              ></div>
            </Typography>
          </Grid>
        ) : (
          <div></div>
        )}
        {sectionDescription ? (
          <Divider
            variant="middle"
            light="true"
            style={{
              //flexGrow: "1",
              margin: "auto",
              width: "87%",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
          />
        ) : (
          <div></div>
        )}

        {props.selectedNode.SubTopics.map((subTopic, i) => {
          // let selectedTopicImage = require("../Images/Thumbnails/" +
          //   subTopic.Image);
          let selectedTopicImage = subTopic.Image;

          let lastItem = false;

          if (props.selectedNode.SubTopics.length === i + 1) {
            lastItem = true;
          }
          return (
            <Grid
              container
              item
              xs={12}
              justify="center"
              className={props.classes.OpenParentContent}
            >
              <Grid
                item
                container
                direction="column"
                className={props.classes.OpenParentImageContainer}
                style={
                  {
                    // minHeight: "1px",
                    // msFlexNegative: 0,
                  }
                }
              >
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  style={{
                    backgroundImage: "url(" + selectedTopicImage + ")",
                    backgroundPosition: "50% 50%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  className={props.classes.OpenParentImage}
                >
                  {props.CreateButton("PlayOnImage", subTopic)}
                </Grid>
                <Grid
                  container
                  item
                  style={{
                    alignSelf: "center",
                    padding: "1rem 0 0rem 0",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {checkCurriculumStandard(subTopic) ? (
                    <Grid item style={{ visibility: "hidden" }}>
                      <IconButton
                        //onClick={props.handleClose}
                        style={{
                          color: "red",
                          paddingLeft: "16px",

                          //display: "block",
                        }}
                      >
                        <InfoIcon
                          //className={props.classes.CancelIcon}
                          style={{ fontSize: "50px" }}
                        />
                      </IconButton>
                    </Grid>
                  ) : (
                    <div></div>
                  )}

                  <Grid item>
                    {props.CreateButton("RectangleButton", subTopic)}
                  </Grid>
                  {checkCurriculumStandard(subTopic) ? (
                    <Grid item>
                      <Tooltip
                        title={"This activity meets a curricular standard in " + stateChosen + "."}
                        placement="right"
                      >
                        <IconButton
                          component={Link}
                          to={{
                            pathname: `/curriculum-guide`,
                            state: {
                              selectedTopic: subTopic,
                              currentState: stateChosen,
                              learningCenterTopics: props.learningCenterTopics,
                            },
                          }}
                          style={{
                            color: "red",
                            paddingLeft: "16px",
                          }}
                        >
                          <InfoIcon style={{ fontSize: "50px" }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                className={props.classes.OpenParentDescription}
              >
                <Grid item style={{ width: "100%" }}>
                  <Typography
                    variant={props.lessThan750px ? "h6" : "h5"}
                    style={{
                      color: "white",
                      marginBottom: "1rem",
                      textDecoration: "underline",
                      fontFamily: "'Open Sans', sans-serif",
                    }}
                  >
                    {subTopic.SubtopicTitle}
                    <Tooltip title={"Copy URL for " + subTopic.Title}>
                      <Button
                        style={{ color: "white" }}
                        onClick={() =>
                          navigator.clipboard.writeText(
                            TargetTopicURL.letssleepDomain +
                              props.role +
                              "/" +
                              subTopic.Title.replace(/ /g, "_")
                                .replace(/[?!]/g, "")
                                .replace(/[:-]/g, "")
                                .replace("/", "_"),
                          )
                        }
                      >
                        <FileCopyIcon
                          fontSize="small"
                          style={{ verticalAlign: "middle" }}
                        />
                      </Button>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Typography
                    variant="body1"
                    className={props.classes.UnfurledDescription}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: subTopic.Content }}
                    ></div>
                  </Typography>
                </Grid>
              </Grid>
              {lastItem === false ? (
                <Divider
                  variant="middle"
                  light="true"
                  style={{
                    //flexGrow: "1",
                    margin: "auto",
                    width: "87%",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                  }}
                />
              ) : (
                <div></div>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
