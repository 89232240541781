import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LinkIcon from "@material-ui/icons/Link";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Tooltip from "@material-ui/core/Tooltip";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    minWidth: 300,
    borderRadius: "10px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#FAC313",
  },
}));

function VideoCards({ Video }) {
  const videoEl = useRef(null);
  const twitterRefEl = useRef(null);
  const FB = window.FB;
  const twttr = window.twttr;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function generateLinkURL() {
    let pathTitle = Video.VideoTitle;
    pathTitle = pathTitle.replace(/[ ]/g, "_");
    pathTitle = pathTitle.replace(/[?!,]/g, "");
    pathTitle = pathTitle.replace(/[:-]/g, "");
    pathTitle = pathTitle.replace("/", "_");

    let rolePath = window.location.pathname.split("/");
    let videoURL =
      window.location.protocol +
      "//" +
      window.location.host +
      "/" +
      rolePath[1] +
      "/videos/" +
      pathTitle;
    return videoURL;
  }

  function shareTR() {
    twttr.widgets.createShareButton(
      "https://letssleep.org/Students/",
      twitterRefEl.current,
      {
        count: "none",
        text: "Hello World",
      },
    );
  }

  function shareTwitterPopup() {
    let twitterShareURL = "https://twitter.com/share";
    let textToShare = "?text=Check out this video: " + Video.VideoTitle;
    let urlToShare = "&url=" + generateLinkURL();
    let fullURL = twitterShareURL + textToShare + urlToShare;

    window.open(fullURL, "Share this video", "popup");
  }

  function shareFB() {
    FB.ui(
      {
        display: "popup",
        method: "share",
        href: generateLinkURL(),
        quote:
          //"Check out the video \n " +
          Video.VideoTitle + "\n (" + generateLinkURL() + ")",
        //")\n \n" +
        //Video.VideoDescription,
        app_id: "427724155395803",
      },
      function (response) {},
    );
  }

  return (
    <Card raised="true" className={classes.root}>
      <CardHeader
        title={Video.StyleCategory}
        //subheader="September 14, 2016"
        disableTypography={true}
        subheaderTypographyProps={{ color: "white" }}
        style={{
          backgroundColor: "#2D3F5E",
          color: "white",
          fontSize: "0.875rem",
          textAlign: "center",
          fontFamily: "'Open Sans', sans-serif",
          padding: "8px",
          //letterSpacing: "0.04071em",
        }}
      />
      <CardMedia
        //className={classes.media}
        //image={}
        style={{
          height: 0,
          overflow: "hidden",
          paddingTop: "56.25%",
          background: "white",
          position: "relative",
        }}
      >
        {/* <video
          controls
          autoPlay
          style={{ width: "100%", height: "300px", position: "relative" }}
        >
          <source src={Video.Link} type="video/mp4" />
        </video> */}

        <iframe
          src={Video.Link}
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderWidth: 0,
            // width: "100%",
            // height: "300px",
          }}
        ></iframe>
      </CardMedia>
      <CardContent style={{ height: "57px" }}>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          style={{ fontFamily: "'Open Sans', sans-serif", color: "black" }}
        >
          {Video.VideoTitle}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Tooltip title={"Click to share to Facebook"}>
          <IconButton
            // href={
            //   "https://www.facebook.com/sharer/sharer.php?u=" +
            //   generateLinkURL() +
            //   "&quote=" +
            //   "Check out the video" +
            //   Video.VideoTitle +
            //   ": " +
            //   Video.VideoDescription
            // }
            // target="_blank"
            onClick={shareFB}
            aria-label="add to Facebook"
          >
            <FacebookIcon style={{ color: "#406296" }}></FacebookIcon>
          </IconButton>
        </Tooltip>

        <Tooltip title={"Click to share to Twitter"}>
          {/* <a
            //target="_blank"
            target="_blank"
            // onClick="window.open('https://twitter.com/share?text=this&url=https://letssleep.org/Students/', '_blank, 'width=600, height=600, scrollbars=no,resizable=no'); return false;"
            href="https://twitter.com/share?text=this&url=https://letssleep.org/Students/"
            class="twitter-share-button"
          > */}
          <IconButton aria-label="add to Twitter" onClick={shareTwitterPopup}>
            <TwitterIcon style={{ color: "#406296" }} />
          </IconButton>
          {/* </a> */}
        </Tooltip>
        <Tooltip title={"Click to copy URL of this video to your clipboard"}>
          <IconButton
            aria-label="share"
            onClick={() => {
              navigator.clipboard.writeText(generateLinkURL());
            }}
          >
            <script
              async
              src="https://platform.twitter.com/widgets.js"
              charset="utf-8"
            ></script>
            <script
              async
              src="https://platform.twitter.com/widgets.js"
              charset="utf-8"
            ></script>
            <LinkIcon style={{ color: "#406296" }} />
          </IconButton>
        </Tooltip>

        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          style={{ color: "#406296" }}
        >
          <ExpandMoreIcon style={{ fontSize: "2.1rem" }} />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{ fontFamily: "'Open Sans', sans-serif", color: "black" }}
          >
            {Video.VideoDescription}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{ textAlign: "right", fontSize: "0.75rem" }}
          >
            <br />
            Video Duration: {Video.VideoLength}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default VideoCards;
