import React, { useState, useEffect } from "react";
import axios from "axios";
import { convertJsonToReadableArray } from "../API/convertJsonToReadableArray";

function useLearningCenterAPI() {
  const [roleBackendData, setRoleBackendData] = useState({
    studentData: [],
    teacherData: [],
    adminData: [],
    parentData: [],
  });
  const backendStudentRoute =
    "https://backendwp.letssleep.org/wp-json/wp/v2/student_learning_center_v2/?per_page=100";
  const backendTeacherRoute =
    "https://backendwp.letssleep.org/wp-json/wp/v2/teacher_learning_center_v2/?per_page=100";
  const backendAdminRoute =
    "https://backendwp.letssleep.org/wp-json/wp/v2/admin_learning_center/?per_page=100";
  const backendParentRoute =
    "https://backendwp.letssleep.org/wp-json/wp/v2/parent_learning_center/?per_page=100";

  useEffect(() => {
    async function fetchMyAPI() {
      const [studentResponse, teacherResponse, adminResponse, parentResponse] =
        await Promise.all([
          axios.get(backendStudentRoute),
          axios.get(backendTeacherRoute),
          axios.get(backendAdminRoute),
          axios.get(backendParentRoute),
        ]);
      
      setRoleBackendData({
        studentData: convertJsonToReadableArray(studentResponse.data),
        teacherData: convertJsonToReadableArray(teacherResponse.data),
        adminData: convertJsonToReadableArray(adminResponse.data),
        parentData: convertJsonToReadableArray(parentResponse.data)
      });
    }
    fetchMyAPI();

    return () => {};
  }, []);

  return roleBackendData;
}

export default useLearningCenterAPI;
