import { makeStyles } from "@material-ui/core/styles";

const LearningCenterDialogMS = makeStyles((theme) => ({

  sliderNodesFormatting: {
    "& div.slick-list": {
      maxHeight: (props) => props.CurrentSliderHeight,
      // maxHeight: (props) => props.CurrentSliderHeight !== null ? props.CurrentSliderHeight : "714px",
      //currentSliderHeight
    },
  },

}));

export default LearningCenterDialogMS;
