import { useEffect } from "react";

export function useContactRouteLoadedEffect(
  contactLoaded,
  handleGoToContactSection,
  setContactLoaded) {
  useEffect(() => {
    if (contactLoaded) {
      {
        setTimeout(() => {
          handleGoToContactSection();
        }, 500);
      }
      setContactLoaded(false);
    }
  }, [contactLoaded]);
}
