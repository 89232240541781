import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import AboutTeamJson from "../../../data/AboutTeam.json";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ClassNameMap } from "@material-ui/styles";

type Props = {
  classes: ClassNameMap;
};

function TeamBiosSection({ classes }: Props) {
  //CSS/makeStyles from parent component AboutUs.js

  const smallDevices = useMediaQuery("(max-width:625px)");

  return (
    <Grid
      container
      direction="row"
      justify="center"
      className={
        classes.InnerSectionTopMargin + " " + classes.InnerSectionBottomMargin
      }
      style={{
        maxWidth: "100%",
      }}
    >
      <Grid container item justify="center" xs={12}>
        <Typography variant="h5" display="block" style={{ color: "black" }}>
          Our Team
        </Typography>
      </Grid>
      <Grid
        container
        item
        justify="space-evenly"
        xs={12}
        className={classes.InnerSectionBottomMargin}
        style={
          {
            //backgroundColor: "Green"
          }
        }
      >
        {AboutTeamJson.map((teamMember) => {
          let memberImage = require("../../../images/AboutUs/" +
            teamMember.Picture).default;

          if (smallDevices) {
            return (
              <Grid
                container
                item
                direction="row"
                justify="center"
                className={classes.WholePersonBioSection}
              >
                <Grid container item justify="center" xs={12}>
                  <Grid
                    item
                    className={classes.BioPicture}
                    style={{
                      backgroundImage: "url(" + memberImage + ")",
                      backgroundPosition: "50% 20%",
                      //alternative, I can do backgroundSize: "contain"
                      //to get whole picture, but pictures will look
                      //less consistent
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></Grid>
                </Grid>
                <Grid
                  container
                  item
                  justify="center"
                  xs={12}
                  className={classes.TextBioBox}
                >
                  <Grid item className={classes.TextBio}>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      style={{ color: "black" }}
                    >
                      {teamMember.Name}
                    </Typography>
                    <Divider
                      variant="middle"
                      light={true}
                      style={{
                        //flexGrow: "1",
                        margin: "auto",
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgba(0, 0, 0, 1)",
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      display="block"
                      style={{ color: "black" }}
                    >
                      {teamMember.Role}
                    </Typography>
                    <Divider
                      variant="middle"
                      light={true}
                      style={{
                        //flexGrow: "1",
                        margin: "auto",
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgba(0, 0, 0, 1)",
                      }}
                    />
                    <Typography
                      variant="body2"
                      display="block"
                      style={{ color: "black", marginTop: "0.25rem" }}
                    >
                      {teamMember.Biography.split("\n").map((text, index) => (
                        <React.Fragment>
                          {text}
                          <br />
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Grid
                container
                item
                className={classes.WholePersonBioSection}
                direction="row"
                style={{
                  display: "flex",
                  height: "200px",
                  maxWidth: "600px",
                  flexWrap: "wrap",
                }}
              >
                <Grid
                  item
                  className={classes.BioPicture}
                  style={{
                    backgroundImage: "url(" + memberImage + ")",
                    backgroundPosition: "50% 20%",
                    //alternative, I can do backgroundSize: "contain"
                    //to get whole picture, but pictures will look
                    //less consistent
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></Grid>
                <Grid item className={classes.TextBioBox}>
                  <Grid item className={classes.TextBio}>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      style={{ color: "black" }}
                    >
                      {teamMember.Name}
                    </Typography>
                    <Divider
                      variant="middle"
                      light={true}
                      style={{
                        //flexGrow: "1",
                        margin: "auto",
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgba(0, 0, 0, 1)",
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      display="block"
                      style={{ color: "black" }}
                    >
                      {teamMember.Role}
                    </Typography>
                    <Divider
                      variant="middle"
                      light={true}
                      style={{
                        //flexGrow: "1",
                        margin: "auto",
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgba(0, 0, 0, 1)",
                      }}
                    />
                    <Typography
                      variant="body2"
                      display="block"
                      style={{ color: "black", marginTop: "0.25rem" }}
                    >
                      {teamMember.Biography.split("\n").map((text, index) => (
                        <React.Fragment>
                          {text}
                          <br />
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          }
        })}

        <Grid
          container
          item
          direction="row"
          style={{
            display: "flex",
            height: "0px",
            maxWidth: "600px",
          }}
        />
      </Grid>
    </Grid>
  );
}

export default TeamBiosSection;
