import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AboutUs from "./aboutUs/AboutUs";
import HeaderSection from "./headerSection/HeaderSection";
import LearningCenterSection from "./learningCenterSection/LearningCenterSection";
import VideoSection from "./videoSection/VideoSection";
import StateInformationSection from "./stateInformationSection/StateInformationSection";
import ContactUsSection from "./contactUsSection/ContactUsSection";
import { ThemeProvider } from "@material-ui/core/styles";
import USStateData from "../../data/USStateData.json";
import SponshorshipMainPage from "./sponsorshipMainPage/SponshorshipMainPage";
import PartnersPage from "./partnersPage/PartnersPage";
import CurriculumGuide from "./curriculumGuide/CurriculumGuide";
import UserRoleData from "../../data/UserRole.json";
import useLearningCenterAPI from "../../hooks/useLearningCenterAPI";
import useLearningCenterJson from "../../hooks/useLearningCenterJson";
import useFirstPathname from "../../hooks/useFirstPathname";
import { useCheckRolePathEffect } from "../../hooks/useCheckRolePathEffect";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useGoogleAnalyticsEffect } from "../../hooks/useGoogleAnalyticsEffect";
import { LetsSleepTheme } from "../makeStyles/LetsSleepTheme";
import { useContactRouteLoadedEffect } from "../../hooks/useContactRouteLoadedEffect";
import { useResetContactMoveEffect } from "../../hooks/useResetContactMoveEffect";
import { useAllLearningRoutes } from "../../hooks/useAllLearningRoutes";
import { useConfirmAllAPILoaded } from "../../hooks/useConfirmAllAPILoaded";
import { useGenerateRoleRoutesEffect } from "../../hooks/useGenerateRoleRoutesEffect";
import LetsSleepLandingPage from "./LetsSleepLandingPage";

/*This is the of the website. In this application, we are using this component to (SRP definition):
  1) Generate all the routes that will be needed in the app.
  2) Gather API related to generating routes.
  3) Saving local data from the app - Student and State most important
  4) ??? Anything else.
*/
function SiteGenerator() {
  const [alertIE, setAlertIE] = useState(false);
  const [openWebsiteDialog, setOpenWebsiteDialog] = useState(false);
  const [stateChosen, setStateChosen] = useState(
    localStorage.getItem("uSStateLocalStorage") || "",
  );
  const [roleChosen, setRoleChosen] = useState(
    localStorage.getItem("roleLocalStorage") || "",
  );
  const [stateInfo, setStateObject] = useState({});
  const [goToContact, setGoToContact] = useState(false);
  const [, windowHeight] = useWindowSize();
  //API from backend:
  const generatedRoleRoutes = useLearningCenterAPI();
  //Versus JSON saved locally if API is not dependable:
  //const generatedRoleRoutes = useLearningCenterJson();
  //console.log(generatedRoleRoutes2)

  const functionCallbacksForRoutes = {
    handleSetStateAndRole: handleSetStateAndRole,
    handleGoToContactSection: handleGoToContactSection,
    goToContact: goToContact,
    handleChangeRole: handleChangeRole,
  };
  const roleRoutes = useGenerateRoleRoutesEffect(
    generatedRoleRoutes,
    functionCallbacksForRoutes,
    stateInfo,
    windowHeight,
  );
  const allLearningRoutes = useAllLearningRoutes(roleRoutes);
  const allAPIRelatedLoaded = useConfirmAllAPILoaded(
    roleRoutes,
    allLearningRoutes,
  );
  const [contactLoaded, setContactLoaded] = useState(false);
  const rolePath = useFirstPathname();

  function checkForIE() {
    if (window.MSCompatibleInfo != null) {
      return true;
    } else {
      return false;
    }
  }

  function ieBrowser() {
    return (
      <div style={{ textAlign: "center", fontSize: "32px", marginTop: "2rem" }}>
        This website is inaccessible to IE. Please use a modern browser such as
        Chrome, Microsoft Edge, or Firefox.
        {ieBrowserAlert()}
      </div>
    );
  }

  function ieBrowserAlert() {
    if (!alertIE) {
      setAlertIE(true);
      alert(
        "This website is inaccessible to IE. Please use a modern browser such as Chrome, Microsoft Edge, or Firefox.",
      );
    }
  }

  useCheckRolePathEffect(
    rolePath,
    handleChangeRole,
    setRoleAndStateInLocationStorage,
    stateChosen,
  );

  useGoogleAnalyticsEffect();

  useContactRouteLoadedEffect(
    contactLoaded,
    handleGoToContactSection,
    setContactLoaded,
  );

  useResetContactMoveEffect(goToContact, setGoToContact);

  function learningCenterRoutes() {
    //console.log(allLearningRoutes)
    return allLearningRoutes;
  }

  function handleSetStateAndRole(state, role) {
    setStateChosen(state);
    setRoleChosen(role);
    setOpenWebsiteDialog(false);
  }

  function handleChangeRole(role) {
    setRoleChosen(role);
  }

  const clearLocalStorageForTestPurposes = () => {
    localStorage.clear();
  };

  function handleGoToContactSection() {
    setGoToContact(true);
  }

  function findStateObject(defaultState = false) {
    if (defaultState) {
      let stateChosenObject = USStateData.filter(
        (state) => state.state === "California",
      );
      setStateObject(stateChosenObject[0]);
    } else {
      let stateChosenObject = USStateData.filter(
        (state) => state.state === stateChosen,
      );
      setStateObject(stateChosenObject[0]);
    }
  }

  useEffect(() => {
    setRoleAndStateInLocationStorage(roleChosen, stateChosen);

    if (!stateChosen || !roleChosen) {
      setOpenWebsiteDialog(true);
    }

    if (stateChosen && roleChosen) {
      findStateObject();
    }

    //Old: clears local storage for testing purposes.
    //clearLocalStorageForTestPurposes();

    return () => {};
  }, [stateChosen, roleChosen]);

  function setRoleAndStateInLocationStorage(role, state) {
    localStorage.setItem("uSStateLocalStorage", state);
    localStorage.setItem("roleLocalStorage", role);
  }

  const generateDefaultRoleRoutes = () => {
    let userRoleArray = [];
    for (let index = 0; index < UserRoleData.length; index++) {
      const user = UserRoleData[index];
      userRoleArray.push(
        <Route path={"/" + user.Role + "/"} exact>
          <HeaderSection
            roleChosen={user.Role}
            stateInfo={stateInfo}
            resetStateAndRole={handleSetStateAndRole}
            goToContactSection={handleGoToContactSection}
            defaultRoleAndState={checkEmptyRoleState()}
          />
          <LearningCenterSection
            windowHeight={windowHeight}
            roleChosen={user.Role}
            handleChangeRole={handleChangeRole}
            studentLearningCenterData={generatedRoleRoutes.studentData}
            teacherLearningCenterData={generatedRoleRoutes.teacherData}
            adminLearningCenterData={generatedRoleRoutes.adminData}
            parentLearningCenterData={generatedRoleRoutes.parentData}
          />
          <VideoSection />
          <StateInformationSection stateInfo={stateInfo} />
          <ContactUsSection scrollDown={goToContact} role={stateChosen} />
          <SponshorshipMainPage />
        </Route>,
      );
    }
    return userRoleArray;
  };

  function isEmpty(obj) {
    return !obj || Object.keys(obj).length === 0;
  }

  const checkEmptyRoleState = () => {
    if (roleChosen === "" || isEmpty(stateInfo)) {
      return true;
    } else {
      return false;
    }
  };

  const generateContactRoute = () => {
    let contactRouteArray = [];

    contactRouteArray.push(
      <Route
        path={"/" + "ContactUs/"}
        render={() => (
          <Redirect
            to={{
              pathname: "/" + addRole() + "/",
            }}
          >
            {setContactLoaded(true)}
          </Redirect>
        )}
      ></Route>,
    );

    return contactRouteArray;
  };

  const addRole = () => {
    if (roleChosen === "") {
      handleSetStateAndRole("California", "Students");
      return "Students";
    } else {
      return roleChosen;
    }
  };

  const generatePeripheryMenus = () => {
    let userPeripheryArray = [];

    userPeripheryArray.push(
      <Route path="/about">
        <HeaderSection
          roleChosen={roleChosen}
          stateInfo={stateInfo}
          resetStateAndRole={handleSetStateAndRole}
          goToContactSection={handleGoToContactSection}
          defaultRoleAndState={checkEmptyRoleState()}
        />

        <AboutUs />
      </Route>,
    );
    userPeripheryArray.push(
      <Route path="/partners">
        <HeaderSection
          roleChosen={roleChosen}
          stateInfo={stateInfo}
          resetStateAndRole={handleSetStateAndRole}
          goToContactSection={handleGoToContactSection}
          defaultRoleAndState={checkEmptyRoleState()}
        />
        <PartnersPage goToContactSection={handleGoToContactSection} />
      </Route>,
    );

    userPeripheryArray.push(
      <Route path="/curriculum-guide">
        <HeaderSection
          roleChosen={roleChosen}
          stateInfo={stateInfo}
          resetStateAndRole={handleSetStateAndRole}
          goToContactSection={handleGoToContactSection}
          defaultRoleAndState={checkEmptyRoleState()}
        />

        <CurriculumGuide />
      </Route>,
    );

    return userPeripheryArray;
  };

  const generateBaseURLRedirect = () => {
    if (roleChosen === "") {
      return (
        // <LandingDialog
        //   open={openWebsiteDialog}
        //   onClose={handleSetStateAndRole}
        // />
        <LetsSleepLandingPage
          open={openWebsiteDialog}
          onClose={handleSetStateAndRole}
        />
      );
    } else {
      if (allAPIRelatedLoaded) {
        return (
          <Route
            render={() => (
              <Redirect to={{ pathname: "/" + roleChosen + "/" }}></Redirect>
            )}
          />
        );
      }
    }
  };

  return (
    <ThemeProvider theme={LetsSleepTheme}>
      <div>
        {
          //Anything above the Switch component below from React Router Dom will show no matter the route.
        }
        {/* {checkForIE()} */}

        {checkForIE() ? (
          ieBrowser()
        ) : (
          <Switch>
            {generatePeripheryMenus()}
            {generateContactRoute()}
            {learningCenterRoutes()}
            {generateDefaultRoleRoutes()}
            {generateBaseURLRedirect()}
            {/* access below by trying localhost:3000 */}
            {/* {clearLocalStorageForTestPurposes()} */}
          </Switch>
        )}
      </div>
    </ThemeProvider>
  );
}

export default SiteGenerator;
