import { makeStyles } from "@material-ui/core/styles";
import TabletPhone from "../../images/PlayfulImages/tabletphone.png";
import TeaCoffee from "../../images/PlayfulImages/teacoffee.png";
import GraduationCap from "../../images/PlayfulImages/universitycap.png";

export const videoStyles = makeStyles((theme) => ({
  WholeGridContainer: {
    paddingRight: "2rem",
    paddingLeft: "2rem",
    position: "relative",
  },
  SubWholeGridContainer: {
    paddingTop: "3.125rem",
    paddingBottom: "3.125rem",
    maxWidth: 1280,
  },
  TitleSection: {
    marginBottom: "1.125rem",
    position: "relative",
  },
  TabletPhoneImgLeft: {
    [theme.breakpoints.down("sm")]: {
      top: "-20px",
      left: "-70px",
      width: "70px",
      height: "70px",
    },
    [theme.breakpoints.down(795)]: {
      width: "0px",
      height: "0px",
    },
    position: "absolute",
    top: "-30px",
    left: "-120px",
    //transform: "translate(0,-100%)",
    backgroundImage: `url(${TabletPhone})`,
    width: "100px",
    height: "100px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    //filter: "invert(1)",
    zIndex: -1,
    // [theme.breakpoints.down(1650)]: {
    //   width: "200px",
    //   height: "200px",
    // },
  },
  TabletPhoneImgRight: {
    [theme.breakpoints.down("sm")]: {
      top: "-20px",
      right: "-70px",
      width: "70px",
      height: "70px",
    },
    [theme.breakpoints.down(795)]: {
      width: "0px",
      height: "0px",
    },
    position: "absolute",
    top: "-30px",
    right: "-120px",
    transform: "scaleX(-1)",
    backgroundImage: `url(${TabletPhone})`,
    width: "100px",
    height: "100px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    //filter: "invert(1)",
    zIndex: -1,
    // [theme.breakpoints.down(1650)]: {
    //   width: "200px",
    //   height: "200px",
    // },
  },
  TeaCoffeeImg: {
    position: "absolute",
    top: "620px",
    right: "1000px",
    // transform: "scaleX(-1)",
    backgroundImage: `url(${TeaCoffee})`,
    width: "75px",
    height: "75px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    //filter: "invert(1)",
    zIndex: -1,
    // [theme.breakpoints.down(1650)]: {
    //   width: "200px",
    //   height: "200px",
    // },
  },
  GraduationCapImg: {
    position: "absolute",
    top: "10px",
    right: "250px",
    // transform: "scaleX(-1)",
    backgroundImage: `url(${GraduationCap})`,
    width: "200px",
    height: "150px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    //filter: "invert(1)",
    zIndex: -1,
    // [theme.breakpoints.down(1650)]: {
    //   width: "200px",
    //   height: "200px",
    // },
  },
  VideoSectionTabs: {
    flexGrow: 1,
    //backgroundColor: "blue",
    width: "100%"
  },
  ContentCategoryTab: {
    backgroundColor: "#406296",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingBottom: "0px",
    paddingTop: "0px",
    margin: "6px",
    color: "white",
    borderRadius: "5px",
    border: "2px solid black",
    "&.MuiTab-textColorInherit": {
      opacity: 1
    },
    "&.MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "#2D3F5E",
      color: "white"
    }
  },
  TabPanelGrid: {
    //How to justify items center?
  }
}));
