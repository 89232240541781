import React, { useState, useRef, useEffect, SyntheticEvent, BaseSyntheticEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import withWidth from "@material-ui/core/withWidth";
import { RoleFormControl } from "../helperFunctions/RoleFormControl";
import { StateFormControl } from "../helperFunctions/StateFormControl";
import UserRoleData from "../../../data/UserRole.json";
import { useStyles as HeaderSectionMS } from "../../makeStyles/HeaderSectionMS";
import { LogoSection } from "./LogoSection";
import { BylineSection } from "./BylineSection";
import { LgUpSocialMediaSection } from "./LgUpSocialMediaSection";
import { HeaderMenuSection } from "./HeaderMenuSection";

type Props = {
  roleChosen: string;
  stateInfo: { state: string };
  resetStateAndRole: (state: string, role: string) => {};
  goToContactSection: () => {};
  defaultRoleAndState: boolean;
};

function HeaderSection({
  roleChosen,
  stateInfo,
  resetStateAndRole,
  goToContactSection,
  defaultRoleAndState,
}: Props) {

  const classes = HeaderSectionMS();

  const [anchorEl, setAnchorEl] = useState(null);
  const [localRole, setLocalRole] = useState("");
  const [localuSState, setLocalUSState] = useState("");
  const [stateRoleChanged, setStateRoleChanged] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    if (defaultRoleAndState) {
      resetStateAndRole("California", "Students");
    }
    return () => {};
  }, [defaultRoleAndState]);

  useEffect(() => {
    if (stateInfo == null) {
      return;
    }
    // if (localRole === roleChosen || localuSState === stateInfo.state) {
    //   return;
    // }
    setLocalRole(roleChosen);
    setLocalUSState(Object.keys(stateInfo).length === 0 ? "" : stateInfo.state);

    return () => {};
  }, [roleChosen, stateInfo]);

  //The logic behind resetting role and state needs to be redone
  useEffect(() => {
    if (localRole !== "" && localuSState !== "") {
      if (initialLoad) {
        setInitialLoad(!initialLoad);
      } else {
        setStateRoleChanged(true);
      }
    }

    return () => {};
  }, [localRole, localuSState]);

  useEffect(() => {
    if (stateRoleChanged === true) {
      setStateRoleChanged(false);
      handleParentStateRoleReset();
    }

    return () => {};
  }, [stateRoleChanged]);

  const handleClickMenu = (event: React.ChangeEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  //this might need to get wrapped somewhere else that lets everyone
  //know that we got some major role change stuff going on here.
  //We need to make sure this is a safe path to move from one role to another.
  const handleParentStateRoleReset = () => {
    let currentRolePath = location.pathname.split("/")[1];
    //var currentPathName = document.location.pathname;
    for (let index = 0; index < UserRoleData.length; index++) {
      const user = UserRoleData[index];
      if (currentRolePath === user.Role) {
        history.push("/" + localRole + "/");
      }
      // if (currentPathName === "/" + user.Role + "/") {
      //   history.push("/" + localRole + "/");
      // } else if (currentPathName === "/" + user.Role) {
      //   history.push("/" + localRole + "/");
      // }
    }
    //history.push("/" + localRole + "/");
    resetStateAndRole(localuSState, localRole);
  };

  const handleRoleChange = (event: React.ChangeEvent<any>) => {
    setLocalRole(event.target.value);
  };

  const handleUSStateChange = (event: React.ChangeEvent<any>) => {
    setLocalUSState(event.target.value);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleContactMove = () => {
    setAnchorEl(null);
    goToContactSection();
    history.push("/" + localRole);
  };

  return (
    <AppBar color="inherit" className={classes.AppBar}>
      <Grid container justify="center" direction="row">
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          className={classes.TopHeaderGrid}
        >
          <LogoSection localuSState={localuSState}></LogoSection>
          <BylineSection LogoAndText={classes.LogoAndText}></BylineSection>
          <MdUpStateAndRoleSection
            classes={classes}
            localRole={localRole}
            localuSState={localuSState}
            handleRoleChange={handleRoleChange}
            handleUSStateChange={handleUSStateChange}
          ></MdUpStateAndRoleSection>
          <HeaderMenuSection
            anchorEl={anchorEl}
            handleClickMenu={handleClickMenu}
            handleCloseMenu={handleCloseMenu}
            handleContactMove={handleContactMove}
          ></HeaderMenuSection>
          <LgUpSocialMediaSection
            classes={classes}
            localuSState={localuSState}
          ></LgUpSocialMediaSection>
        </Grid>
        <MdDownStateAndRoleSection
          classes={classes}
          localRole={localRole}
          localuSState={localuSState}
          handleRoleChange={handleRoleChange}
          handleUSStateChange={handleUSStateChange}
        ></MdDownStateAndRoleSection>
      </Grid>
    </AppBar>
  );
}

//Problems refactoring to separate file.
const MdUpStateAndRoleSection = (props: any) => (
  <Hidden smDown>
    <Grid item className={props.classes.RoleStateButtons}>
      <Grid container direction="row" alignItems="center">
        <Typography
          variant="subtitle2"
          display="block"
          style={{
            color: "black",
            display: "baseline",
            marginRight: "4px",
          }}
        >
          Info for
        </Typography>
        <StateFormControl
          classes={props.classes}
          localuSState={props.localuSState}
          handleUSStateChange={props.handleUSStateChange}
          TypographySize={"subtitle2"}
        ></StateFormControl>
        &nbsp;
        <RoleFormControl
          classes={props.classes}
          localRole={props.localRole}
          handleRoleChange={props.handleRoleChange}
          TypographySize={"subtitle2"}
        ></RoleFormControl>
      </Grid>
    </Grid>
  </Hidden>
);

//Problems refactoring to separate file.
const MdDownStateAndRoleSection = (props: any) => (
  <Hidden mdUp>
    <Grid
      container
      justify="center"
      className={props.classes.SecondRoleStateBox} //ToDo:
      //Idea, but not my favorite: Move the I am a Student Grid item below
      //to the left at less than 400px and make the burger icon
      //Idea: Scale down the text at below 400px and choose not to care at below 300px
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <Typography
          variant="subtitle2"
          display="block"
          style={{
            color: "#2D3F5E",
            display: "baseline",
          }}
        >
          Info for &nbsp;
        </Typography>
        <StateFormControl
          classes={props.classes}
          localuSState={props.localuSState}
          handleUSStateChange={props.handleUSStateChange}
          TypographySize={"subtitle2"}
        ></StateFormControl>
        &nbsp;
        <RoleFormControl
          classes={props.classes}
          localRole={props.localRole}
          handleRoleChange={props.handleRoleChange}
          TypographySize={"subtitle2"}
        ></RoleFormControl>
      </Grid>
    </Grid>
  </Hidden>
);

export default withWidth()(HeaderSection);
