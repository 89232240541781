import htmlentities from "he";

export function convertVideoJsonToReadableArray(jsonVideoData) {
  var readableVideoData = [];
  readableVideoData = constructVideoArray(jsonVideoData);
  return readableVideoData;
}

function constructVideoArray(jsonVideoData) {
  var readableData = [];
  for (let index = 0; index < jsonVideoData.length; index++) {
    //console.log(jsonVideoData[index])
    readableData.push({
      VideoTitle: jsonVideoData[index].acf.video_title,
      ContentCategory: jsonVideoData[index].acf.content_category,
      StyleCategory: jsonVideoData[index].acf.style_category,
      Link: jsonVideoData[index].acf.link,
      VideoDescription: jsonVideoData[index].acf.video_description,
      VideoLength: jsonVideoData[index].acf.video_length,
      InternalVideoURL: makeInternalURL(jsonVideoData, index),
    });
  }
  return readableData;
}


function makeInternalURL (jsonVideoData, index) {
  let url = "/video/" + jsonVideoData[index].acf.video_title;
  url = url.replace(/[ ]/g, "_");
  url = url.replace(/[?!]/g, "");

  return url;
}