import { makeStyles } from "@material-ui/core/styles";
import PeopleHeaderImg from "../../images/Partners/PeopleHoldingHands.jpg";

export const PartnersPageMS = makeStyles((theme) => ({
  HeaderImg: {
    backgroundImage: `url(${PeopleHeaderImg})`,
    backgroundPosition: "50% 60%",
    backgroundSize: "auto",
    [theme.breakpoints.up(1920)]: {
      backgroundSize: "cover",
    },
    backgroundRepeat: "no-repeat",
    height: "350px",
  },
  SponsorsMainItem: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
    maxWidth: 1280,
  },
  TextSponsorItem: {
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      width: "auto",
      minWidth: "auto",
      textAlign: "center",
    },
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "1.5rem",
    color: "black",
    height: "60px",
    minWidth: "385px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  ImageSponsorItem: {
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      maxHeight: "40px",
      width: "auto",
    },
    [theme.breakpoints.down(300)]: {
      maxHeight: "30px",
    },
    //padding: "8px",
    width: "385px",
    height: "auto",
  },
}));
