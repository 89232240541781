import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

type Props = {
  handleClickMenu: (event: React.ChangeEvent<any>) => void,
  anchorEl: any,
  handleCloseMenu: () => void,
  handleContactMove: () => void
};

export const HeaderMenuSection = ({
  handleClickMenu,
  anchorEl,
  handleCloseMenu,
  handleContactMove,
}: Props) => (
  <Grid item>
    <Hidden smDown>
      <Grid
        container
        justify="space-between"
        style={{
          alignItems: "center",
        }}
      >
        <Button
          aria-controls="About Menu"
          aria-haspopup="true"
          onClick={handleClickMenu}
          size="medium"
          style={{
            color: "#2D3F5E",
          }}
        >
          About{" "}
          <ArrowDropDownIcon
            style={{
              fontSize: "40px",
            }}
          />
        </Button>
      </Grid>
    </Hidden>
    <Hidden mdUp>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleClickMenu}
        size="medium"
      >
        <MenuIcon fontSize="large" />
      </IconButton>
    </Hidden>
    <Menu
      id="About Menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
    >
      <MenuItem component={Link} to="/about" onClick={handleCloseMenu}>
        What is Let's Sleep?
      </MenuItem>
      <MenuItem
        onClick={() => {
          window.open("http://sleep101.letssleep.org/");
          handleCloseMenu();
        }}
      >
        Sleep 101 Program
      </MenuItem>
      <MenuItem component={Link} to="/partners" onClick={handleCloseMenu}>
        Partners
      </MenuItem>
      <MenuItem
        onClick={() => {
          window.open("https://www.startschoollater.net/donate.html");
          handleCloseMenu();
        }}
      >
        Donate
      </MenuItem>
      <MenuItem onClick={handleContactMove}>Contact Us</MenuItem>
    </Menu>
  </Grid>
);
