import { makeStyles } from "@material-ui/core/styles";
import { BorderBottom, Translate } from "@material-ui/icons";

const LearningCenterDialogMS = makeStyles((theme) => ({
  DialogBox: {
    backgroundColor: "rgba(0,0,0,0.0)",

    //I'm manually getting top from the HeaderSection which defines height: 76 from TopHeaderGrid.
    [theme.breakpoints.up("lg")]: {
      left: (props) =>
        props.activeLearningRect !== null ? props.activeLearningRect.left : 0,
      minWidth: (props) =>
        props.activeLearningRect !== null ? props.activeLearningRect.width : 0,
      minHeight: (props) =>
        props.activeLearningRect !== null ? props.activeLearningRect.height : 0,
      margin: 0,
      top: "76px",
    },
    [theme.breakpoints.down("md")]: {
      //top: 0,
      minWidth: "95vw",
      minHeight: (props) => props.windowHeight * 0.95,
      //minHeight: `${console.log(useWindowSize())}`,
      maxHeight: "1080px",
      margin: 0,
    },
    overflow: "hidden",
    position: "absolute",
  },
  InnerParentGrid: {
    display: "flex",
    //Maybe left isn't working properly in IE11? Or needs to be added to the breakpoint below.
    [theme.breakpoints.up("lg")]: {
      left: (props) =>
        props.activeLearningRect !== null ? props.activeLearningRect.left : 0,
      width: (props) =>
        props.activeLearningRect !== null ? props.activeLearningRect.width : 0,
      height: (props) =>
        props.activeLearningRect !== null ? props.activeLearningRect.height : 0,
      //Consider a maxwidth to look better on 1920 and larger resolutions for
      //widescreens,etc
      //Experiment Start:
      maxHeight: "601.594px",
      maxWidth: "1260px",
      margin: "auto auto",
      //Experiment End

      //Old way:
      //padding: "2rem 4rem",
      //margin: 0,

      top: "76px",
    },
    [theme.breakpoints.down("md")]: {
      //top: 0,
      width: "95vw",
      //height: "95vh",
      height: (props) => props.windowHeight * 0.95,
      maxHeight: "1080px",
      //margin: "auto",
    },
    overflow: "hidden",
  },
  InnerParentPlayable: {
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      left: (props) =>
        props.activeLearningRect !== null ? props.activeLearningRect.left : 0,
      width: (props) =>
        props.activeLearningRect !== null ? props.activeLearningRect.width : 0,
      height: (props) =>
        props.activeLearningRect !== null ? props.activeLearningRect.height : 0,
      margin: 0,
      top: "76px",
    },
    [theme.breakpoints.down("md")]: {
      //top: 0,
      width: "95vw",
      height: (props) => props.windowHeight * 0.95,
      maxHeight: "1080px",
      margin: "auto",
    },
    overflow: "hidden",
  },
  InnerParentPlayableVideo: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  FullWidthScreenVideo: {
    //I'm manually getting top from the HeaderSection which defines height: 76 from TopHeaderGrid.
    [theme.breakpoints.up("lg")]: {
      minWidth: "95vw",
      minHeight: (props) => props.windowHeight * 0.95,
      margin: 0,
    },
    [theme.breakpoints.down("md")]: {
      //top: 0,
      minWidth: "95vw",
      minHeight: (props) => props.windowHeight * 0.95,
      maxHeight: "1080px",
      margin: "auto",
    },
    overflow: "hidden",
    position: "relative",
  },
  ContentDefinition: {
    padding: "2rem",
    [theme.breakpoints.down(750)]: {
      padding: "1rem",
    },
    height: "100%",
    //minHeight: 0,
    color: "white",
    border: "1px solid #78c7ff",
    backgroundColor: "rgba(0,0,0,0.85)",
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    flex: "1",
    position: "relative",
    //"scrollbar-width": "10px",
    "scrollbar-color": "white #7a7a7a",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#a3a3a3",
      border: "1px solid #f0f0f0",
    },
    "&::-webkit-scrollbar-button:single-button": {
      backgroundColor: "white",
      display: "block",
      borderStyle: "solid",
      height: "12px",
      width: "16px",
    },
    "&::-webkit-scrollbar-button:single-button:vertical:decrement": {
      borderWidth: "0 5px 15px 5px",
      borderColor: "transparent transparent #828282 transparent",
    },
    "&::-webkit-scrollbar-button:single-button:vertical:increment": {
      borderWidth: "15px 5px 0px 5px",
      borderColor: "#828282 transparent transparent transparent",
    },
  },
  SliderContentDefinition: {
    padding: "2rem",
    [theme.breakpoints.down(750)]: {
      padding: "1rem",
    },
    height: "100%",
    //minHeight: 0,
    color: "white",
    border: "1px solid #78c7ff",
    backgroundColor: "rgba(0,0,0,0.85)",
    overflowY: "scroll",
    overflowX: "hidden",
    display: "flex",
    flex: "1",
    position: "relative",
    alignItems: "center",
    //"scrollbar-width": "thin",
    "scrollbar-color": "white #7a7a7a",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#a3a3a3",
      border: "1px solid #f0f0f0",
    },
    "&::-webkit-scrollbar-button:single-button": {
      backgroundColor: "white",
      display: "block",
      borderStyle: "solid",
      height: "12px",
      width: "16px",
    },
    "&::-webkit-scrollbar-button:single-button:vertical:decrement": {
      borderWidth: "0 5px 15px 5px",
      borderColor: "transparent transparent #828282 transparent",
    },
    "&::-webkit-scrollbar-button:single-button:vertical:increment": {
      borderWidth: "15px 5px 0px 5px",
      borderColor: "#828282 transparent transparent transparent",
    },
  },
  HorizontalTeacherNode: {
    [theme.breakpoints.down(1280)]: {
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
      display: "flex",
      flexWrap: "nowrap",
      //Centers content. Works because parent Grid element is flex.
      margin: "auto auto",
    },
  },
  TeacherNodeSlide: {
    // "& div.slick-list": {
    //   //height: "714px",
    //   //height needs to be a variable passed as an argument of the height of the first Grid in the map.
    //   //height: "100%",

    // },
    display: "flex",
    flexWrap: "nowrap",
    //Centers content. Works because parent Grid element is flex.
    margin: "auto auto",
  },
  ExternalLinkButton: {
    color: "white",
    borderColor: "white",
    backgroundColor: "#406296",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#5481c4",
    },
  },
  Single16By9Image: {
    [theme.breakpoints.up(750)]: {
      width: "600px",
      height: "337.5px",
    },
    [theme.breakpoints.between(600, 750)]: {
      width: "500px",
      height: "281.25px",
    },
    [theme.breakpoints.between(475, 600)]: {
      width: "400px",
      height: "225px",
    },
    [theme.breakpoints.between(420, 475)]: {
      width: "300px",
      height: "168.75px",
    },
    flexGrow: "1",
    width: "250px",
    height: "140.63px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "rgba(120, 199, 255, 0.75)",
  },
  ImageTitle: {
    [theme.breakpoints.up(750)]: {
      width: "600px",
      height: "337.5px",
    },
    [theme.breakpoints.between(600, 750)]: {
      width: "500px",
      height: "281.25px",
    },
    [theme.breakpoints.between(475, 600)]: {
      width: "400px",
      height: "225px",
    },
    [theme.breakpoints.between(420, 475)]: {
      width: "300px",
      height: "168.75px",
    },
    flexGrow: "1",
    width: "250px",
    height: "140.63px",
  },
  TeacherMultipleImagesContainer: {
    // alignItems: "flex-start",
    // justifyContent: "center",
    // flexGrow: "1",
    // flexShrink: "1",
    width: "100%",
    //minWidth: "300px",
    height: "auto",
  },
  BlockQuoteFormat: {
    "& a": {
      backgroundColor: "white",
    },
  },
  SlickSliderWidth: {
    "& .slick-slider": { width: "100%" },
  },
  SliderFigures: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "rgba(120, 199, 255, 0.75)",
    },
  },
  ClickableLinks: {
    "&:hover": {
      color: "yellow",
    },
    "&:active": {
      color: "white",
    },
    color: "white",
    cursor: "pointer",
    overflowWrap: "break-word",
  },
  SingleTeacherImage: {},
  SingleDescriptionBox: {
    [theme.breakpoints.up(750)]: {
      width: "600px",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    [theme.breakpoints.between(600, 750)]: {
      width: "500px",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    [theme.breakpoints.between(475, 600)]: {
      width: "400px",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    [theme.breakpoints.between(420, 475)]: {
      width: "300px",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    width: "250px",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  ExitWindow: {
    //New:
    position: "relative",
    width: "100%",
    height: 0,
    zIndex: "100",

    //Old:
    // position: "-webkit-sticky",
    // position: "sticky",
    // left: "calc(100% - 61px)",
    // zIndex: "100",
  },
  ExitWindowVideo: {
    position: "absolute",
    [theme.breakpoints.down(750)]: {
      right: "4rem",
    },
    top: "0rem",
    right: "0.2rem",
  },
  CancelIcon: {
    [theme.breakpoints.down(750)]: {
      fontSize: "2rem",
    },
    fontSize: "2.5rem",
  },
  SectionTitle: {
    textAlign: "center",
    //paddingBottom: "1rem",
    marginRight: "auto",
    marginLeft: "auto",
  },
  SectionTitleImage: {
    [theme.breakpoints.up(2100)]: {
      marginBottom: "6rem",
    },
    maxWidth: "100%",
    height: "auto",
    marginBottom: "2rem",
  },
  TeacherDescriptionText: {
    [theme.breakpoints.up(2100)]: {
      marginBottom: "6rem",
    },
    marginBottom: "2rem",
  },
  OpenParentContent: {
    width: "100%",
    flexDirection: "row",
    color: "black",
    // width: "900px",
    marginTop: "1rem",
  },
  OpenParentImageContainer: {
    [theme.breakpoints.up(1541)]: {
      width: "500px",
    },
    [theme.breakpoints.between(1280, 1541)]: {
      width: "380px",
    },
    [theme.breakpoints.between(650, 1280)]: {
      width: "500px",
    },
    [theme.breakpoints.between(470, 650)]: {
      width: "400px",
    },
    [theme.breakpoints.down(1169)]: {
      marginLeft: "0.5rem",
      marginTop: "1rem",
    },
    width: "250px",
    marginRight: "0.5rem",
    marginBottom: "1rem",
  },
  OpenParentImage: {
    [theme.breakpoints.up(1541)]: {
      height: "281.25px",
    },
    [theme.breakpoints.between(1280, 1541)]: {
      height: "213.75px",
    },
    [theme.breakpoints.between(650, 1280)]: {
      height: "281.25px",
    },
    [theme.breakpoints.between(470, 650)]: {
      height: "225px",
    },
    height: "140.63px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "rgba(120, 199, 255, 0.75)",
  },
  OpenParentDescription: {
    [theme.breakpoints.up(1541)]: {
      width: "500px",
    },
    [theme.breakpoints.between(1280, 1541)]: {
      width: "380px",
    },
    [theme.breakpoints.between(650, 1280)]: {
      width: "500px",
    },
    [theme.breakpoints.between(470, 650)]: {
      width: "400px",
    },
    width: "250px",
    [theme.breakpoints.down(1169)]: {
      order: -1,
      marginRight: "0.5rem",
    },
    marginLeft: "0.5rem",
  },
  UnfurledDescription: {
    color: "white",
    fontFamily: "'Open Sans', sans-serif",
    wordBreak: "break-word",
    "& a:link": {
      color: "#FAC313"
    },
    "& a:visited": {
      color: "#FAC313"
    },
    "& a:hover": {
      color: "white"
    },
    "& a:active": {
      color: "white"
    }
  },
  ScrollArrowPosition: {
    [theme.breakpoints.between(600, 900)]: {
      left: "93%",
    },
    [theme.breakpoints.between(400, 600)]: {
      left: "92%",
    },
    [theme.breakpoints.down(400)]: {
      left: "90%",
    },
    position: "absolute",
    width: "0%",
    left: "95%",
    top: 0
  },
  DownwardArrowScroll: {
    [theme.breakpoints.down(600)]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down(750)]: {
      animation: `$fadeInAndOutShortWidth 3000ms 5`,
      "-webkit-animation-name": `$fadeInAndOutShortWidth`,
      "-webkit-animation-duration": "3000ms",
      "-webkit-animation-iteration-count": "5",
    },
    color: "#FAC313",
    padding: "0",
    display: "block",
    fontSize: "2rem",
    animation: `$fadeInAndOut 3000ms 5`,
    "-webkit-animation-name": `$fadeInAndOut`,
    "-webkit-animation-duration": "3000ms",
    "-webkit-animation-iteration-count": "5",
  },
  "@keyframes fadeInAndOut": {
    "0%": {
      opacity: 0,
      transform: "translateY(-100px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  "@-webkit-keyframes fadeInAndOut": {
    "0%": {
      opacity: 0,
      transform: "translateY(-100px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  "@keyframes fadeInAndOutShortWidth": {
    "0%": {
      opacity: 0,
      transform: "translateY(-100px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(-15px)",
    },
  },
  "@-webkit-keyframes fadeInAndOutShortWidth": {
    "0%": {
      opacity: 0,
      transform: "translateY(-100px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(-15px)",
    },
  },
}));

export default LearningCenterDialogMS;
