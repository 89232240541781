import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import ContactUsForm from "./ContactUsForm";
import SocialMediaLinks from "../../../data/SocialMediaLinks.json";
import PropTypes from 'prop-types';

type Props = {
  scrollDown: boolean,
  role: string
}

const useStyles = makeStyles((theme) => ({
  WholeGridContainer: {
    paddingTop: "3.125rem",
    paddingBottom: "3.125rem",
    backgroundColor: "white",
    paddingRight: "1.875rem",
    paddingLeft: "1.875rem",
    color: "black",
  },
  TitleSection: {
    marginBottom: "3.125rem",
  },
  DescriptionSection: {
    marginBottom: "1.563rem",
  },
}));

function ContactUsSection({scrollDown, role} : Props) {
  const classes = useStyles();
  const contactRefEl = useRef<HTMLInputElement>(null);
  
  useEffect(() => {
    //window.scrollTo(0, contactRefEl.current.offsetTop)

    if (scrollDown) {
      contactRefEl?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }

    return () => {};
  }, [scrollDown]);

  function findAppropriateTwitter() {
    if (role === "California") {
      return SocialMediaLinks.TwitterCA;
    } else {
      return SocialMediaLinks.Twitter;
    }
  }

  return (
    <form action="#">
      <Grid
        container
        alignItems="center"
        direction="column"
        justify="center"
        className={classes.WholeGridContainer}
        id={"contactUsSection"}
        ref={contactRefEl}
      >
        <Grid item xs={12} className={classes.TitleSection}>
          <Typography variant="h5" display="block" style={{ color: "black" }}>
            CONTACT US
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.DescriptionSection}>
          <Typography
            variant="body1"
            display="block"
            style={{ color: "black" }}
          >
            To reach out to the <b>Let's Sleep!</b> team please contact us
            below.
          </Typography>
        </Grid>
        <ContactUsForm />
        <Hidden lgUp>
          <Grid
            item
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "1.5rem",
            }}
          >
            <IconButton
              onClick={() => {
                window.open(findAppropriateTwitter());
              }}
            >
              <TwitterIcon style={{ color: "#1DA1F2", fontSize: "2rem" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                window.open(SocialMediaLinks.YouTube);
              }}
            >
              <YouTubeIcon style={{ color: "#FF0000", fontSize: "2rem" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                window.open(SocialMediaLinks.Facebook);
              }}
            >
              <FacebookIcon style={{ color: "#4267B2", fontSize: "2rem" }} />
            </IconButton>
          </Grid>
        </Hidden>
      </Grid>
    </form>
  );
}

ContactUsSection.propTypes = {
  role: PropTypes.string.isRequired,
}

export default ContactUsSection;
