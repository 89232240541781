import React from "react";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import UserRoleData from "../../../data/UserRole.json";
import InputLabel from "@material-ui/core/InputLabel";

export const RoleFormControl = (props) => (
  <FormControl variant="outlined" className={props.classes.FormControlRole}>
    <InputLabel className={props.classes.InputLabelForm}>Role</InputLabel>
    <Select
      label="Role"
      value={props.localRole}
      onChange={props.handleRoleChange}
      className={props.classes.SelectButtons}
      inputProps={{
        classes: {
          root: props.classes.InnerSelectButtons,
        },
      }}
    >
      {UserRoleData.map((role, index) => (
        <MenuItem key={index} value={role.Role} value={role.Role}>
          <Typography
            variant={props.TypographySize}
            className={props.classes.SelectButtonText}
          >
            {role.Role}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
