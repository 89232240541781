import React from "react";
import Grid from "@material-ui/core/Grid";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import IconButton from "@material-ui/core/IconButton";

export function LCDPlayableVideo(props) {
  return (
    <Grid
      container
      justify="center"
      className={props.classes.InnerParentGrid}
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <div className={props.classes.ExitWindowVideo}>
        <IconButton
          onClick={props.handleClose}
          style={{
            color: "white",
            backgroundColor: "black",
            padding: "0",
            display: "block",
            zIndex: 100,
          }}
        >
          <CancelPresentationIcon
            style={{
              fontSize: "2.5rem",
            }}
          />
        </IconButton>
      </div>
      <Grid item className={props.classes.InnerParentPlayableVideo}>
        <iframe
          className={props.classes.InnerParentPlayable}
          src={props.Video + "?autoplay=1&autopause=0"}
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        {/* old code:
         <video
          controls
          autoPlay
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <source
            src={process.env.PUBLIC_URL + "/Videos/" + props.Video}
            type="video/mp4"
          />
        </video> */}
      </Grid>
    </Grid>
  );
}
