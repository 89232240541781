import React from "react";
import { Link, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SleepNumberLogo from "../../../images/Partners/SleepNumberLogo.png";
import AASMLogo from "../../../images/Partners/AASMLogo.png";
import { PartnersPageMS } from "../../makeStyles/PartnersPageMS";

type Props = {
  goToContactSection: () => void;
};

function PartnersPage({ goToContactSection }: Props) {
  const classes = PartnersPageMS();

  let history = useHistory();

  const handleContactMove = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    goToContactSection();
    history.push("/");
  };

  return (
    <div>
      <div className={classes.HeaderImg}></div>
      <Grid
        container
        alignItems="center"
        direction="column"
        justify="center"
        style={{
          backgroundColor: "white",
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <Grid
          item
          xs={12}
          //className={classes.TitleSection}
          style={{ marginBottom: "3.125rem" }}
        >
          <Typography
            display="block"
            style={{
              color: "black",
              fontFamily: "'Open Sans', sans-serif",
              fontSize: "1.75rem",
              textAlign: "center",
            }}
          >
            Thank you to our partner organizations and sponsors!
          </Typography>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="center"
          spacing={10}
          className={classes.SponsorsMainItem}
        >
          <Grid
            container
            item
            sm={12}
            md={5}
            justify="center"
            alignItems="center"
            style={{ border: "1px solid black", margin: "1rem" }}
          >
            <Typography variant="body1" className={classes.TextSponsorItem}>
              <b>The Abbaszadeh Foundation</b>
            </Typography>
          </Grid>
          <Grid
            container
            item
            sm={12}
            md={5}
            justify="center"
            style={{ border: "1px solid black", margin: "1rem" }}
          >
            <a href="https://www.sleepnumber.com/science">
              <img
                src={SleepNumberLogo}
                alt="Sleep Number Logo"
                className={classes.ImageSponsorItem}
              ></img>
            </a>
            <Typography variant="subtitle2" style={{ textAlign: "center" }}>
              <b>
                Sleep Number’s goal is to improve the lives of 1 million youth
                through quality sleep by 2025.
              </b>
            </Typography>
          </Grid>
          <Grid
            container
            item
            sm={12}
            md={5}
            justify="center"
            style={{ border: "1px solid black", margin: "1rem" }}
          >
            <a href="https://aasm.org/">
              <img
                src={AASMLogo}
                alt="Sleep Number Logo"
                className={classes.ImageSponsorItem}
              ></img>
            </a>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          //className={classes.TitleSection}
          style={{ marginTop: "3.125rem" }}
        >
          <Typography
            variant="h5"
            display="block"
            style={{
              color: "black",
              fontFamily: "'Open Sans', sans-serif",
              fontSize: "1rem",
              textAlign: "center",
            }}
          >
            <i>
              If you would like to discuss partnership/sponsorship opportunities
              with us, please{" "}
              <a href="#" onClick={handleContactMove}>
                contact us
              </a>
              .
            </i>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default PartnersPage;
