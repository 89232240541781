import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

const WholeDialogMS = makeStyles((theme) => ({
  DialogBox: {
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 5,
    overflow: "hidden",
    //position: "absolute",
  },
  ExitWindow: {
    position: "absolute",
    [theme.breakpoints.down(750)]: {
      right: "4rem",
    },
    top: "0rem",
    right: "0.2rem",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function VideoBoxDialog(props) {
  const { onClose, open, video, videoSource } = props;
  const classes = WholeDialogMS(props);
  const [openCheck, setOpenCheck] = useState(false);

  useEffect(() => {
    if (open) {
      setOpenCheck(true);
    }
    return () => {};
  }, [open]);

  const handleClose = () => {
    setOpenCheck(false);
    onClose();
  };


  const checkInternal = () => {
    if (videoSource === "internal") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Dialog
      fullScreen
      onExited={handleClose}
      //disableBackdropClick={true}
      open={openCheck}
      //disableEscapeKeyDown={true}
      TransitionComponent={Transition}
      PaperProps={{
        classes: { root: classes.DialogBox },
      }}
    >
      <div className={classes.ExitWindow}>
        <IconButton
          onClick={handleClose}
          style={{
            color: "white",
            backgroundColor: "black",
            padding: "0",
            display: "block",
            zIndex: 100,
          }}
        >
          <CancelPresentationIcon style={{ fontSize: "2.5rem" }} />
        </IconButton>
      </div>
      {checkInternal() ? (
        <video controls autoPlay style={{ width: "100%", height: "100%" }}>
          <source
            src={process.env.PUBLIC_URL + "/Videos/" + video}
            type="video/mp4"
          />
        </video>
      ) : (
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingBottom: "56.25%",
            //margin: "auto",
          }}
        >
          <iframe
            src={video}
            allowFullScreen
            allow='autplay'
            style={{
              display: "block",
              position: "absolute",
              top: "4%",
              left: "2.5%",
              width: "95vw",
              height: "90vh",
              border: 0,
              //transform: "translate(-50%, -55%)",
            }}
          ></iframe>
        </div>
      )}
    </Dialog>
  );
}

export default VideoBoxDialog;
