import React, { useState, useEffect } from "react";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import IconButton from "@material-ui/core/IconButton";

export function LCDArticulatePlayable(props) {
  const [articulateSRC, setArticulateSRC] = useState();

  useEffect(() => {
    if (typeof props.selectedNode.ButtonLink != "undefined") {
      setArticulateSRC(
        props.selectedNode.ButtonLink.replace(/<\/?[^>]+>/gi, ""),
      );
    }
    return () => {};
  }, [articulateSRC, props]);

  return (
    <div
      style={{
        display: "flex",
        margin: "auto auto",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: props.lessThan750px ? "auto" : "0",
          bottom: props.lessThan750px ? "0.2rem" : "auto",
          left: "0.2rem",
        }}
      >
        <IconButton
          onClick={props.handleClose}
          style={{
            color: "black",
            backgroundColor: "white",
            padding: "0",
            display: "block",
          }}
        >
          <CancelPresentationIcon
            style={{
              fontSize: "2.5rem",
            }}
          />
        </IconButton>
      </div>
      {/* {ShowArticulateStoryline} */}

      <iframe
        className={props.InnerParentPlayable}
        src={
          articulateSRC
          //Old Way:
          // process.env.PUBLIC_URL +
          // "/ArticulatePlayables/" +
          // props.Title +
          // "/story_html5.html"
        }
      ></iframe>
    </div>
  );
}
