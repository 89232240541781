import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import LSLogo from "../../../images/lslogo.jpg";
import LSLogoCal from "../../../images/LSCALOGO.png";

type Props = {
  localuSState: string
}

export const LogoSection = ({localuSState}: Props) => (
  <Grid
    item
    style={{
      height: "100%",
    }}
  >
    <Link to="/">
      {localuSState === "California" ? (
        <img
          src={LSLogoCal}
          alt="Let's Sleep Logo"
          style={{
            maxHeight: "100%",
          }} />
      ) : (
        <img
          src={LSLogo}
          alt="Let's Sleep Logo"
          style={{
            maxHeight: "100%",
          }} />
      )}
    </Link>
  </Grid>
);
