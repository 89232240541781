import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
//import LearningTree from "../Data/LearningCenterTree.json";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LearningCenterDialogMS from "../../../makeStyles/LearningCenterDialogMS";
import LCDialogExceptionsMS from "../../../makeStyles/LCDialogExceptionsMS";
import { LCDPlayableVideo } from "./LCDPlayableVideo";
import { LCDPlayableImage } from "./LCDPlayableImage";
import { LCDArticulatePlayable } from "./LCDArticulatePlayable";
import { UnfurledParentNode } from "./UnfurledParentNode";
import { MainTopicNode } from "./MainTopicNode";
import { SubTopicNode } from "./SubTopicNode";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TargetTopicURL from "../../../../data/TargetTopicURL.json"
import "../../../css/arrows.css";
import Divider from "@material-ui/core/Divider";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import IconButton from "@material-ui/core/IconButton";
import {
  TeacherSliderVerticalSettings,
  RenderArrows,
} from "../../../sliderSettings/TeacherSliderVerticalSettings";
import { SetSliderImageSettings } from "../../../sliderSettings/ImageQuoteSliderSettings";
import { PlayableRectangleButton } from "./PlayableButton";
import PlayableButton from "./PlayableButton";

//This LearningCenterDialog will be redesigned to be a lot more streamlined towards a centralized systems that matches the admin section design. 
//Separate branch started.


function LearningCenterDialog(props) {
  const classes = LearningCenterDialogMS(props);
  const {
    onClose,
    open,
    subjectTitle,
    windowHeight,
    LearningTree,
    role,
    changeRoleCallback,
  } = props;
  const [selectedNode, setSelectedNode] = useState({});
  const [openParent, setOpenParent] = useState(false);
  const [openArticulatePlayable, setOpenArticlatePlayable] = useState(false);
  const [openBrowserPlayable, setOpenBrowserPlayable] = useState(false);
  const lessThan750px = useMediaQuery("(max-width:749px)");
  const contentRef = useRef(null);
  const sliderEl1 = useRef(null);
  const sliderEl2 = useRef(null);
  const [sliderNodes, setSliderNodes] = useState([]);
  const [currentSliderHeight, setCurrentSliderHeight] = useState();
  const [styleProps, setStyleProps] = useState({
    CurrentSliderHeight: currentSliderHeight,
  });
  const [changeSliderHeight, setChangeSliderHeight] = useState(false);
  const [firstSliderHeight, setFirstSliderHeight] = useState(true);
  const [firstsliderHeightTimer, setFirstSliderHeightTimer] = useState(false);
  const classesExceptions = LCDialogExceptionsMS(styleProps);

  const verticalSliderSettings = TeacherSliderVerticalSettings(
    setCurrentSliderHeight,
    setFirstSliderHeightTimer,
    sliderEl1,
    firstSliderHeight,
  );

  useEffect(() => {
    setChangeSliderHeight(true);

    return () => {};
  }, [currentSliderHeight]);

  useEffect(() => {
    if (changeSliderHeight) {
      setChangeSliderHeight(false);
      setStyleProps({
        CurrentSliderHeight: currentSliderHeight,
      });
    }

    return () => {};
  }, [changeSliderHeight]);

  useEffect(() => {
    if (firstsliderHeightTimer) {
      const timer = setTimeout(() => {
        setFirstSliderHeight(false);
        setFirstSliderHeightTimer(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [firstsliderHeightTimer]);

  useEffect(() => {
    if (selectedNode === "undefined") {
    }

    if (selectedNode !== "undefined") {
      // if (selectedNode.SliderFlag === "Yes") {
      //   console.log("Main node has a slider flag set to yes.");
      // }
    }

    return () => {};
  }, [selectedNode]);

  useEffect(() => {
    findNode(subjectTitle);

    return () => {};
  }, [subjectTitle]);

  useEffect(() => {
    if (openParent) {
      contentRef.current.scrollTo({ top: 0 });
    }
    return () => {};
  }, [openParent]);

  const buildLearningCenterContent = () => {
    if (openBrowserPlayable) {
      return buildBrowserPlayables();
    } else if (openArticulatePlayable) {
      return buildArticulatePlayables();
    } else if (role === "Students") {
      return buildStudentLearningContent();
    } else if (role === "Teachers") {
      //We learned that selectedNode needs, at a top if level, to be checked for not being undefined by NodeIsMainTopic or its family.
      return buildTeacherLearningContent();
    } else if (role === "Administrators") {
      return buildAdminLearningContent();
    }
  };

  const buildBrowserPlayables = () => {
    if (role === "Administrators") {
      var videoPlayable;
      videoPlayable = selectedNode.ButtonLink;
      return (
        <LCDPlayableVideo
          classes={classes}
          Video={videoPlayable}
          handleClose={handleClose}
        />
      );
    }

    if (role === "Students" || role === "Teachers") {
      if (
        selectedNode.sample_act_type === "Video" ||
        selectedNode.PlayableContent.Video
      ) {
        var videoPlayable;

        if (typeof selectedNode.PlayableContent != "undefined") {
          videoPlayable = selectedNode.PlayableContent.Video;
        } else if (typeof selectedNode.link != "undefined") {
          videoPlayable = selectedNode.link.url;
        }
        return (
          <LCDPlayableVideo
            classes={classes}
            Video={videoPlayable}
            handleClose={handleClose}
          />
        );
      } else {
        let playableContentImage = selectedNode.PlayableContent.Image;
        return (
          <LCDPlayableImage
            classes={classes}
            handleClose={handleClose}
            playableContentImage={playableContentImage}
            lessThan750px={lessThan750px}
            selectedNode={selectedNode}
          />
        );
      }
    }
  };

  const buildArticulatePlayables = () => {
    return (
      <LCDArticulatePlayable
        InnerParentPlayable={classes.InnerParentPlayable}
        //Title={selectedNode.PlayableContent.Title}
        selectedNode={selectedNode}
        lessThan750px={lessThan750px}
        handleClose={handleClose}
      />
    );
  };

  const buildStudentLearningContent = () => {
    if (openParent) {
      return (
        <UnfurledParentNode
          classes={classes}
          handleClose={handleClose}
          contentRef={contentRef}
          lessThan750px={lessThan750px}
          selectedNode={selectedNode}
          //CreatePlayableButton={CreatePlayableButton}
          CreateButton={createPlayableButtonDirective}
          role={role}
        />
      );
    } else if (nodeIsMainTopic()) {
      //Old way before WordPress Headless CMS:
      // let selectedNodeImage = require("../Images/Thumbnails/" +
      //   selectedNode.Image);
      let selectedNodeImage = selectedNode.Image;

      return (
        <MainTopicNode
          classes={classes}
          handleClose={handleClose}
          lessThan750px={lessThan750px}
          selectedNode={selectedNode}
          selectedNodeImage={selectedNodeImage}
          //CreatePlayableButton={CreatePlayableButton}
          CreateButton={createPlayableButtonDirective}
          role={role}
        />
      );
    } else if (nodeIsSubTopic()) {
      //Old way before WordPress Headless CMS:
      // let selectedNodeImage = require("../Images/Thumbnails/" +
      //   selectedNode.Image);
      let selectedNodeImage = selectedNode.Image;

      return (
        <SubTopicNode
          classes={classes}
          handleClose={handleClose}
          lessThan750px={lessThan750px}
          selectedNode={selectedNode}
          selectedNodeImage={selectedNodeImage}
          CreateButton={createPlayableButtonDirective}
          role={role}
        />
      );
    }
  };

  const buildAdminLearningContent = () => {
    if (nodeIsSubTopic()) {
      let selectedNodeImage = selectedNode.Image;

      return (
        <SubTopicNode
          classes={classes}
          handleClose={handleClose}
          lessThan750px={lessThan750px}
          selectedNode={selectedNode}
          selectedNodeImage={selectedNodeImage}
          CreateButton={createPlayableButtonDirective}
          role={role}
        />
      );
    } else if (nodeIsMainTopic()) {
      return (
        <UnfurledParentNode
          classes={classes}
          handleClose={handleClose}
          contentRef={contentRef}
          lessThan750px={lessThan750px}
          selectedNode={selectedNode}
          CreateButton={createPlayableButtonDirective}
          role={role}
        />
      );
    }
  };

  const buildTeacherLearningContent = () => {
    if (nodeIsMainTopic() || nodeIsSubTopic()) {
      return (
        <Grid container justify="center" className={classes.InnerParentGrid}>
          <div className={classes.ExitWindow}>
            <div style={{ position: "absolute", right: "16px" }}>
              <IconButton
                onClick={handleClose}
                style={{
                  color: "white",
                  padding: "0",
                  display: "block",
                }}
              >
                <CancelPresentationIcon className={classes.CancelIcon} />
              </IconButton>
            </div>
          </div>
          {sliderNodes.length > 0 && (
            <Grid
              container
              item
              xs={12}
              className={classes.SliderContentDefinition}
            >
              <RenderArrows sliderReference={sliderEl1} />
              <Slider
                className={classesExceptions.sliderNodesFormatting}
                ref={sliderEl1}
                {...verticalSliderSettings}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              >
                {sliderNodes.map((node, index) => {
                  return (
                    <div>
                      <Grid
                        container
                        item
                        justify="space-between"
                        alignItems="center"
                        direction="column"
                      >
                        {node}
                      </Grid>
                    </div>
                  );
                })}
              </Slider>
            </Grid>
          )}
          {sliderNodes.length === 0 && (
            <Grid container item xs={12} className={classes.ContentDefinition}>
              {decideDirectionalLayout(selectedNode)}
            </Grid>
          )}
        </Grid>
      );
    }
  };

  const generateTeacherSection = (node) => {
    let arrayOfSections = [];
    if (node.TypeOfTopic === "Vertical Layout") {
      if (node.SectionTitleFlag === "Yes") {
        if (node.SectionTitleType === "Text") {
          arrayOfSections.push(
            <Grid Item className={classes.SectionTitle}>
              <Typography
                variant={lessThan750px ? "h4" : "h3"}
                style={{ fontFamily: "'Bebas Neue', cursive" }}
              >
                {node.SectionTitleText}

                <Tooltip title={"Copy URL for " + node.Title}>
                  <Button
                    style={{ color: "white" }}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        TargetTopicURL.letssleepDomain +
                          role +
                          "/" +
                          node.Title.replace(/ /g, "_").replace(/[?!]/g, ""),
                      )
                    }
                  >
                    <FileCopyIcon
                      fontSize="small"
                      style={{ verticalAlign: "middle" }}
                    />
                  </Button>
                </Tooltip>
              </Typography>
            </Grid>,
          );
        } else if (node.SectionTitleType === "Image") {
          arrayOfSections.push(
            <div>
              <img
                src={node.SectionTitleImage.url}
                className={classes.SectionTitleImage}
              ></img>
              <Tooltip title={"Copy URL for " + node.Title}>
                <Button
                  style={{ color: "white" }}
                  onClick={() =>
                    navigator.clipboard.writeText(
                      TargetTopicURL.letssleepDomain +
                        role +
                        "/" +
                        node.Title.replace(/ /g, "_").replace(/[?!]/g, ""),
                    )
                  }
                >
                  <FileCopyIcon
                    fontSize="small"
                    style={{ verticalAlign: "middle" }}
                  />
                </Button>
              </Tooltip>
            </div>,
          );
        }
      }
      if (node.DescriptionFlag === "Yes") {
        arrayOfSections.push(
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", marginBottom: "2rem" }}
          >
            <Typography
              variant="h4"
              style={{ fontFamily: "'Open Sans', sans-serif" }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: node.DescriptionText,
                }}
              ></div>
            </Typography>
          </Grid>,
        );
      }
      if (node.ShareableFlag === "Yes") {
        arrayOfSections.push(
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", marginBottom: "2rem" }}
          >
            <Typography
              variant="h4"
              style={{
                fontFamily: "'Open Sans', sans-serif",
                border: "2px solid white",
                padding: "1rem",
              }}
              onClick={() => navigator.clipboard.writeText(node.ShareableURL)}
            >
              <div
                className={classes.ClickableLinks}
                dangerouslySetInnerHTML={{
                  __html: node.ShareableURL,
                }}
              ></div>
            </Typography>
            <Typography variant="subtitle1" className={classes.ClickableLinks}>
              CLICK TO COPY URL
            </Typography>
          </Grid>,
        );
      }
      if (node.ImageQuoteFlag === "Yes") {
        //Use Single15By9Image as a base.
        //Up to four items in a row if we can get it.
        //Use map to map out each item in our node.imageQuoteList if we
        //can do that.
        //Use the logic above to place images node.ContentList[0].childNodes[0].outerHTML with its caption if need be.
        arrayOfSections.push(
          //Here, I put a slider component <Slider> with arrow above it. I think everything else will be taken care of.
          <Grid
            item
            container
            direction="row"
            justify="space-evenly"
            xs={11}
            className={
              (classes.TeacherMultipleImagesContainer, classes.SlickSliderWidth)
            }
          >
            <Slider
              ref={sliderEl2}
              {...SetSliderImageSettings(node.ImageQuoteList.length)}
              style={{
                width: "100%",
                height: "auto",
              }}
            >
              {node.ImageQuoteList.map((element, index) => {
                return (
                  <Grid
                    item
                    container
                    //md={6}
                    className={classes.TeacherMultipleImagesContainer}
                  >
                    {handleImageCaptionOrientation(node, element, index) ===
                      "Above" && (
                      <figure className={classes.SliderFigures}>
                        <div
                          style={{ textAlign: "center" }}
                          dangerouslySetInnerHTML={{
                            __html: element.childNodes[1].outerHTML,
                          }}
                        ></div>
                        <div
                          style={{ textAlgin: "center" }}
                          dangerouslySetInnerHTML={{
                            __html: element.childNodes[0].outerHTML,
                          }}
                        ></div>
                      </figure>
                    )}

                    {handleImageCaptionOrientation(node, element, index) ===
                      "Below" && (
                      <figure className={classes.SliderFigures}>
                        <div
                          style={{ textAlign: "center" }}
                          dangerouslySetInnerHTML={{
                            __html: element.childNodes[0].outerHTML,
                          }}
                        ></div>
                        <div
                          style={{ textAlign: "center" }}
                          dangerouslySetInnerHTML={{
                            __html: element.childNodes[1].outerHTML,
                          }}
                        ></div>
                      </figure>
                    )}

                    {handleImageCaptionOrientation(node, element, index) ===
                      "No Caption" && (
                      <figure className={classes.SliderFigures}>
                        <div
                          style={{ textAlign: "center" }}
                          dangerouslySetInnerHTML={{
                            __html: element.childNodes[0].outerHTML,
                          }}
                        ></div>
                      </figure>
                    )}
                    {handleImageCaptionOrientation(node, element, index) ===
                      "Blockquote" && (
                      <div
                        className={classes.BlockquoteFormat}
                        style={{
                          textAlign: "center",
                          fontSize: "2rem",
                          color: "white",
                          overflowWrap: "break-word",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: element.outerHTML,
                        }}
                      ></div>
                    )}
                  </Grid>
                );
              })}
            </Slider>
          </Grid>,
        );
      }
      if (node.ButtonLinkFlag === "Yes") {
        arrayOfSections.push(
          <Button
            variant="contained"
            onClick={() => {
              window.open(`${node.ButtonLink}`);
            }}
            style={{
              marginTop: "2rem",
              backgroundColor: "orange",
              color: "white",
              border: "2px solid white",
            }}
          >
            {node.ButtonLinkText}
          </Button>,
        );
      }
    } else if (node.TypeOfTopic === "Horizontal Layout") {
      let selectedTopicImage = node.HorizTopicImage.url;

      arrayOfSections.push(
        <Grid container item justify="center" className={classes.SectionTitle}>
          <Grid justify="center" item>
            <Typography
              variant={lessThan750px ? "h4" : "h3"}
              style={{ fontFamily: "'Bebas Neue', cursive" }}
            >
              {node.SectionTitleText}
              <Tooltip title={"Copy URL for " + node.SectionTitle}>
                <Button
                  style={{ color: "white" }}
                  onClick={() =>
                    navigator.clipboard.writeText(
                      TargetTopicURL.letssleepDomain +
                        role +
                        "/" +
                        node.SectionTitle.replace(/ /g, "_").replace(
                          /[?!]/g,
                          "",
                        ),
                    )
                  }
                >
                  <FileCopyIcon
                    fontSize="small"
                    style={{ verticalAlign: "middle" }}
                  />
                </Button>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>,
      );

      arrayOfSections.push(
        <Grid
          container
          item
          xs={12}
          justify="center"
          className={classes.OpenParentContent}
        >
          <Grid
            item
            container
            direction="column"
            className={classes.OpenParentImageContainer}
          >
            <Grid
              item
              style={{
                backgroundImage: "url(" + selectedTopicImage + ")",
                backgroundPosition: "50% 50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "rgba(120, 199, 255, 0.75)",
              }}
              className={classes.OpenParentImage}
            ></Grid>
            {node.ButtonLinkFlag === "Yes" && (
              <Grid
                item
                style={{
                  alignSelf: "center",
                  padding: "1rem 0 0rem 0",
                }}
              >
                {/* {CreatePlayableButton(node)} */}
                {createPlayableButtonDirective("RectangleButton", node)}
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            direction="column"
            className={classes.OpenParentDescription}
          >
            {/* <Grid item>
              <Typography
                variant={lessThan750px ? "h6" : "h5"}
                style={{
                  color: "white",
                  marginBottom: "1rem",
                  textDecoration: "underline",
                  fontFamily: "'Open Sans', sans-serif",
                }}
              >
                {node.SectionTitleText}
              </Typography>
            </Grid> */}
            <Grid item>
              <Typography
                variant="body1"
                style={{
                  color: "white",
                  fontFamily: "'Open Sans', sans-serif",
                }}
              >
                {node.DescriptionText}
              </Typography>
            </Grid>
          </Grid>
        </Grid>,
      );
    } else if (node.TypeOfTopic === "Sample Activity Post") {
      let lastItem = false;
      let sampleIndex = 0;

      arrayOfSections.push(
        <Grid container item justify="center" className={classes.SectionTitle}>
          <Grid justify="center" item>
            <Typography
              variant={lessThan750px ? "h4" : "h3"}
              style={{ fontFamily: "'Bebas Neue', cursive" }}
            >
              {node.SectionTitleText}
              <Tooltip title={"Copy URL for " + node.Title}>
                <Button
                  style={{ color: "white" }}
                  onClick={() =>
                    navigator.clipboard.writeText(
                      TargetTopicURL.letssleepDomain +
                        role +
                        "/" +
                        node.Title.replace(/ /g, "_").replace(/[?!]/g, ""),
                    )
                  }
                >
                  <FileCopyIcon
                    fontSize="small"
                    style={{ verticalAlign: "middle" }}
                  />
                </Button>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>,
      );

      {
        Object.entries(node.SampleActivities).map(([key, value]) => {
          sampleIndex++;
          if (sampleIndex === Object.keys(node.SampleActivities).length) {
            lastItem = true;
          }
          // let selectedTopicImage = subTopic.Image;
          // let lastItem = false;

          // if (node.SubTopics.length === i + 1) {
          //   lastItem = true;
          // }

          let selectedTopicImage = value.image.url;
          // console.log(value);
          // console.log(Object.keys(node.SampleActivities).length);

          //console.log(value.title) value. is what gives us the ability to access
          //our fields.
          arrayOfSections.push(
            <Grid
              container
              item
              xs={12}
              justify="center"
              className={classes.OpenParentContent}
            >
              <Grid
                item
                container
                direction="column"
                className={classes.OpenParentImageContainer}
                style={
                  {
                    // minHeight: "1px",
                    // msFlexNegative: 0,
                  }
                }
              >
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  style={{
                    backgroundImage: "url(" + selectedTopicImage + ")",
                    backgroundPosition: "50% 50%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  className={classes.OpenParentImage}
                >
                  <PlayableButton
                    subTopicObject={value}
                    setSelectedNode={setSelectedNode}
                    setOpenBrowserPlayable={setOpenBrowserPlayable}
                    setOpenArticlatePlayable={setOpenArticlatePlayable}
                    classes={classes}
                    role={role}
                  ></PlayableButton>
                </Grid>
                <Grid
                  item
                  style={{
                    alignSelf: "center",
                    padding: "1rem 0 0rem 0",
                  }}
                >
                  {createPlayableButtonDirective("RectangleButton", value)}
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                className={classes.OpenParentDescription}
              >
                <Grid item>
                  <Typography
                    variant={lessThan750px ? "h6" : "h5"}
                    style={{
                      color: "white",
                      marginBottom: "1rem",
                      textDecoration: "underline",
                      fontFamily: "'Open Sans', sans-serif",
                    }}
                  >
                    {value.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{
                      color: "white",
                      fontFamily: "'Open Sans', sans-serif",
                    }}
                  >
                    {value.description}
                  </Typography>
                </Grid>
              </Grid>
              {lastItem === false ? (
                <Divider
                  variant="middle"
                  light="true"
                  style={{
                    //flexGrow: "1",
                    margin: "auto",
                    width: "85%",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                  }}
                />
              ) : (
                <div></div>
              )}
            </Grid>,
          );
        });
      }
    } else if (node.TypeOfTopic === "FAQ Section") {
      arrayOfSections.push(
        <Grid item container>
          <Grid Item className={classes.SectionTitle}>
            <Typography
              variant={lessThan750px ? "h4" : "h3"}
              style={{ fontFamily: "'Bebas Neue', cursive" }}
            >
              Frequently Asked questiONs
              <Tooltip title={"Copy URL for " + node.Title}>
                <Button
                  style={{ color: "white" }}
                  onClick={() =>
                    navigator.clipboard.writeText(
                      TargetTopicURL.letssleepDomain +
                        role +
                        "/" +
                        node.Title.replace(/ /g, "_").replace(/[?!]/g, ""),
                    )
                  }
                >
                  <FileCopyIcon
                    fontSize="small"
                    style={{ verticalAlign: "middle" }}
                  />
                </Button>
              </Tooltip>
            </Typography>
          </Grid>
          <div
            style={{ textAlign: "left", fontSize: "1rem" }}
            dangerouslySetInnerHTML={{
              __html: node.FaqText,
            }}
          ></div>
        </Grid>,
      );
    } else if (node.TypeOfTopic === "Topic Link") {
      if (
        node.SectionTitleText === "Student Website" ||
        node.SectionTitle === "Student Website"
      ) {
        changeRoleCallback("Students");
        handleClose();
        return;
      }
      //console.log(node.ExternalTopicLink)
      window.open(node.ExternalTopicLink);
      handleClose();
    }
    return arrayOfSections;
  };

  const decideDirectionalLayout = (node) => {
    if (node.TypeOfTopic === "Horizontal Layout") {
      {
        // return GenerateTeacherSection(node);
        return (
          <Grid container item className={classes.HorizontalTeacherNode}>
            {generateTeacherSection(node)}
          </Grid>
        );
      }
    } else {
      return (
        <Grid
          container
          item
          justify="space-between"
          alignItems="center"
          direction="column"
          className={classes.TeacherNodeSlide}
        >
          {generateTeacherSection(node)}
        </Grid>
      );
    }
  };

  const handleImageCaptionOrientation = (node, element, index) => {
    if (node.ImageQuoteList[index].localName === "blockquote") {
      return "Blockquote";
    }

    //if (element.ImageQuoteList[index])
    if (node.Image_caption_orientation === "Above") return "Above";
    if (node.Image_caption_orientation === "Below") return "Below";
    return "No Caption";
  };

  const handleClose = () => {
    onClose();
    resetStates();
  };

  const resetStates = () => {
    setSelectedNode({});
    setSliderNodes([]);
    setOpenParent(false);
    setOpenBrowserPlayable(false);
    setOpenArticlatePlayable(false);
    setFirstSliderHeight(true);
  };

  const handleUnfurlParent = () => {
    setOpenParent(!openParent);
  };

  function createPlayableButtonDirective(button, alternativeNode = null) {
    let node;
    if (alternativeNode !== null) {
      node = alternativeNode;
    } else {
      node = selectedNode;
    }
    if (button === "PlayOnImage") {
      return (
        <PlayableButton
          subTopicObject={node}
          setSelectedNode={setSelectedNode}
          setOpenBrowserPlayable={setOpenBrowserPlayable}
          setOpenArticlatePlayable={setOpenArticlatePlayable}
          classes={classes}
          role={role}
        ></PlayableButton>
      );
    } else if (button === "RectangleButton") {
      return PlayableRectangleButton(
        node,
        setSelectedNode,
        setOpenBrowserPlayable,
        setOpenArticlatePlayable,
        handleUnfurlParent,
        classes,
        role,
      );
    }
  }

  const nodeIsMainTopic = () => {
    if (LearningTree.some((section) => section === selectedNode)) {
      return true;
    }
    return false;
  };

  const nodeIsSubTopic = () => {
    for (let index = 0; index < LearningTree.length; index++) {
      for (let j = 0; j < LearningTree[index].SubTopics.length; j++) {
        if (LearningTree[index].SubTopics[j] === selectedNode) {
          return true;
        }
      }
    }
    return false;
  };

  const findNode = (title) => {
    let node;
    let sliderFlag;
    let sliderNodes = [];

    for (let index = 0; index < LearningTree.length; index++) {
      if (LearningTree[index].SectionTitle === title) {
        node = LearningTree[index];
        if (LearningTree[index].SliderFlag === "Yes") {
          sliderFlag = true;
        }
        break;
      }
      for (let j = 0; j < LearningTree[index].SubTopics.length; j++) {
        if (LearningTree[index].SubTopics[j].Title === title) {
          node = LearningTree[index].SubTopics[j];
          break;
        }
      }
    }

    if (sliderFlag) {
      sliderNodes.push(generateTeacherSection(node));
      for (let index = 0; index < node.SubTopics.length; index++) {
        sliderNodes.push(generateTeacherSection(node.SubTopics[index]));
      }
      setSliderNodes(sliderNodes);
    }
    setSelectedNode(node);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
      //disableEscapeKeyDown={true}
      style={{
        overflowY: "auto",
        overflowX: "hidden",
      }}
      PaperProps={{
        classes: { root: classes.DialogBox },
      }}
    >
      {buildLearningCenterContent()}
    </Dialog>
  );
}

export default LearningCenterDialog;
