import { useEffect, useState } from "react";

export function useConfirmAllAPILoaded(roleRoutes, allLearningRoutes) {
  const [allRelatedAPILoaded, setAllRelatedAPILoaded] = useState(false);
  useEffect(() => {
    if (
      roleRoutes.studentRoutes.length > 0 &&
      roleRoutes.teacherRoutes.length > 0 &&
      roleRoutes.adminRoutes.length > 0 &&
      allLearningRoutes.length > 0
    ) {
      setAllRelatedAPILoaded(true);
    } else {
      setAllRelatedAPILoaded(false);
    }
    return () => {};
  }, [roleRoutes, allLearningRoutes]);

  return allRelatedAPILoaded;
}
