import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import PeopleHeaderImg from "../../../images/AboutUs/PeopleDiscussionHeader.jpg";
import Divider from "@material-ui/core/Divider";
import WhoWeAreDescription from "./WhoWeAreDescription";
import TeamBiosSection from "./TeamBiosSection";

const AboutUsStyles = makeStyles((theme) => ({
  InnerSectionTopMargin: {
    marginTop: "3.125rem",
  },
  InnerSectionBottomMargin: {
    marginBottom: "3.125rem",
  },
  SectionSidePadding: {
    paddingRight: "1.875rem",
    paddingLeft: "1.875rem",
  },
  WholePersonBioSection: {
    marginBottom: "2rem",
    marginTop: "2rem",
  },
  HeaderImg: {
    backgroundImage: `url(${PeopleHeaderImg})`,
    backgroundPosition: "35% 92%",
    backgroundSize: "auto",
    [theme.breakpoints.up(1920)]: {
      backgroundSize: "cover",
    },
    backgroundRepeat: "no-repeat",
    //width: "100%",
    height: "350px",
    //maxHeight: "350px",
  },
  TextBioBox: {
    [theme.breakpoints.down(625)]: {
      width: "100%",
      maxHeight: "350px",
      marginLeft: "1.875rem",
      marginRight: "1.875rem",
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    width: "65%",
    maxHeight: "200px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    //border: "1px solid #2D3F5E",
    overflow: "hidden",
    backgroundColor: "#dde2eb",
    paddingLeft: "0.5rem",
  },
  TextBio: {
    [theme.breakpoints.down(625)]: {
      height: "350px",
    },
    height: "200px",
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: "0.5rem",
    "scrollbar-width": "thin",
    "scrollbar-color": "#b5b5b5 #d4d4d4",
    "&::-webkit-scrollbar": {
      width: "0.4rem",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#b5b5b5",
    },
    //Adds arrows for chrome. Probably don't need.
    // "&::-webkit-scrollbar-button": {
    //   border: "solid",
    //   height: "16px",
    //   //width: "px"
    // },
    // "&::-webkit-scrollbar-button:vertical:decrement": {
    //   borderWidth: "0 4px 16px 4px",
    //   borderColor: "transparent transparent black transparent",
    // },
    // "&::-webkit-scrollbar-button:vertical:increment": {
    //   borderWidth: "16px 4px 0px 4px",
    //   borderColor: "black transparent transparent transparent",
    // },
  },
  BioPicture: {
    [theme.breakpoints.down(625)]: {

      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
      marginBottom: "0.5rem"
    },
    width: "200px",
    height: "200px",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    //border: "1px solid #2D3F5E",
    backgroundColor: "#e8e8e8",
  },
}));

function AboutUs() {
  const classes = AboutUsStyles();

  return (
    <div>
      <div className={classes.HeaderImg}></div>
      <Grid
        container
        justify="center"
        style={{
          width: "100%",
          height: "100vh",
          //backgroundColor: "#2D3F5E",
        }}
      >
        <Grid item style={{ width: "1280px", backgroundColor: "white" }}>
          <WhoWeAreDescription classes={classes}/>
          <Divider
            variant="middle"
            light={true}
            style={{
              //flexGrow: "1",
              margin: "auto",
              width: "100%",
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 1)",
              zIndex: 100,
            }}
          />
          <TeamBiosSection classes={classes}/>
        </Grid>
      </Grid>
    </div>
  );
}

export default AboutUs;



