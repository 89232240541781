import { useEffect } from "react";
import UserRoleData from "../data/UserRole.json";

export function useCheckRolePathEffect(
  rolePath,
  handleChangeRole,
  SetRoleAndStateInLocationStorage,
  stateChosen,
) {
  useEffect(() => {
    if (rolePath) {
      for (var i = 0; i < UserRoleData.length; i++) {
        if (UserRoleData[i].Role === rolePath) {
          handleChangeRole(rolePath);
          SetRoleAndStateInLocationStorage(rolePath, stateChosen);
        }
      }
    }

    return () => {};
  }, [rolePath]);
}
