import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import React from "react";
import Keys from "../../../images/car_keys.png";
import Pill from "../../../images/pill.png";

type Props = {
  classes: ClassNameMap
}

function OverlayPictures({classes}: Props) {
  return  (
    <div>
      <img
        src={Keys}
        alt="Car Keys"
        style={{
          maxWidth: "10%",
          maxHeight: "10%",
          position: "absolute",
          display: "block",
          transform: "translate(-50%,-50%)",
          top: "15%",
          left: "88%",
        }} />
      <img src={Pill} alt="Pills" className={classes.PillPlacement} />
    </div>
  );
}

export default OverlayPictures;