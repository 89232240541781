import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Brain from "../../../images/PlayfulImages/brain.png";
import useStateInfoAPI from "../../../hooks/useStateInfoAPI";

const useStyles = makeStyles((theme) => ({
  WholeGridContainer: {
    paddingTop: "3.125rem",
    paddingBottom: "3.125rem",
    backgroundColor: "#2D3F5E",
    paddingRight: "1.875rem",
    paddingLeft: "1.875rem",
    color: "black",
  },
  TitleSection: {
    marginBottom: "1rem",
  },
  BrainImg: {
    [theme.breakpoints.down("sm")]: {
      width: "0px",
      height: "0px",
    },
    position: "absolute",
    top: "-15px",
    left: "75px",
    //transform: "scaleX(-1)",
    transform: "scaleX(1)",

    backgroundImage: `url(${Brain})`,
    width: "160px",
    height: "150px",
    filter: "invert(1)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    //filter: "invert(1)",
    // [theme.breakpoints.down(1650)]: {
    //   width: "200px",
    //   height: "200px",
    // },
  },
  AllOtherStatesQuoate: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
    paddingLeft: "320px",
    position: "relative",
  },
  StateInformationStyling: {
    "& a": {
      color: "#FAC313",
    },
  },
}));

type Props = {
  stateInfo: { state: string };
};

function StateInformationSection({ stateInfo }: Props) {
  //const { stateInfo } = props;
  const classes = useStyles();
  const backendStateInfo: any = useStateInfoAPI();
  const [chosenBackendState, setChosenState] = useState("");
  const [chosenStateInfo, setChosenStateInfo] = useState("");

  useEffect(() => {
    for (let index = 0; index < backendStateInfo.length; index++) {
      //console.log(backendStateInfo[index].StateName);

      if (backendStateInfo[index].StateName === stateInfo.state) {
        setChosenState(backendStateInfo[index].StateName);
        setChosenStateInfo(backendStateInfo[index].StateInformation);
      } else {
        setChosenState("");
        setChosenStateInfo("");
      }
    }
    return () => {};
  }, [stateInfo, backendStateInfo]);

  const enterStateDetails = () => {
    if (chosenBackendState !== "") {
      return (
        //if state information is empty, then put the below default text and image in.
        //if not empty, then put the text in from the json and a component that parses the text to make it readable.

        <Typography
          display="block"
          variant="body1"
          style={{
            color: "white",
            //fontFamily: "'Open Sans', sans-serif",
          }}
          className={classes.StateInformationStyling}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: chosenStateInfo,
            }}
          ></div>
        </Typography>
      );
    } else {
      return (
        <div className={classes.AllOtherStatesQuoate}>
          <div className={classes.BrainImg}></div>

          <Typography variant="h6" display="block" style={{ color: "white" }}>
            &quot;
            <i>
              A loss of one hour of sleep [in adolescents] is equivalent to the
              loss of two years of cognitive maturation and development.
            </i>
            &quot;
            <br />
            <div style={{ textAlign: "right" }}>
              —Dr. Avi Sadeh, Tel Aviv University
            </div>
          </Typography>
        </div>
      );
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      justify="center"
      className={classes.WholeGridContainer}
    >
      {chosenBackendState !== "" && (
        <Grid item xs={12} className={classes.TitleSection}>
          <Typography variant="h5" display="block" style={{ color: "white" }}>
            WHAT'S GOING ON IN{" "}
            {stateInfo.state ? stateInfo.state.toUpperCase() : ""}?
          </Typography>
        </Grid>
      )}
      <Grid item style={{ maxWidth: 1280 }}>
        {enterStateDetails()}
      </Grid>
    </Grid>
  );
}

export default StateInformationSection;
