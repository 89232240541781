import React, { useState, useRef, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/arrows.css";
import { videoStyles } from "../../makeStyles/videoStyles";
import VideoCards from "./VideoCards";
import useVideoSectionAPI from "../../../hooks/useVideoSectionAPI";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import VideoBoxDialog from "./VideoBoxDialog";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

VideoSection.propTypes = {
  generateVideoURLS: PropTypes.func.isRequired,
};

function VideoSection({ openedVideo, openDialog, scrollToVideoSection }) {
  const [value, setValue] = useState(0);
  const videoSectionList = useVideoSectionAPI();
  const classes = videoStyles();
  const [contentCategories, setContentCategories] = useState([]);
  const [generatedTabPanels, setGeneratedTabPanels] = useState(null);
  const [openVideoURL, setOpenVideoURL] = useState("");
  const [openVideoBoxDialog, setOpenVideoBoxDialog] = useState(false);
  const videoSecRefEl = useRef(null);

  useEffect(() => {
    if (videoSectionList.length > 0) {
      generateContentCategories();
      //console.log(openedVideo, openDialog);
    }
    return () => {};
  }, [videoSectionList]);

  useEffect(() => {
    if (contentCategories.length > 0) {
      setGeneratedTabPanels(generateTabPanels());
    }
    return () => {};
  }, [contentCategories]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      //console.log(openedVideo, openDialog);
      if (openDialog) {
        handleVideoBoxDialogOpen();
      }
    }, 500);
    return () => {};
  }, []);

  useEffect(() => {
    //console.log(openedVideo);
    if (openedVideo !== undefined) {
      setOpenVideoURL(openedVideo.Link);
    }
    return () => {};
  }, [openedVideo]);

  useEffect(() => {
    if (contentCategories.length > 0 && openedVideo !== undefined) {
      for (let index = 0; index < contentCategories.length; index++) {
        if (contentCategories[index] === openedVideo.ContentCategory[0]) {
          setValue(index);
          return;
        }
      }
    }
    return () => {};
  }, [contentCategories]);

  useEffect(() => {
    if (scrollToVideoSection) {
      videoSecRefEl?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "end",
      });
    }
    return () => {};
  }, [scrollToVideoSection]);

  const handleVideoBoxDialogOpen = () => {
    setOpenVideoBoxDialog(true);
  };

  const handleVideoBoxDialogClose = () => {
    setOpenVideoBoxDialog(false);
  };

  function generateContentCategories() {
    let contentCategories = [];
    let orderedContentCategories = [
      "Featured",
      "Webinar Archive",
      "Mental & Physical Health",
      "Academic & Athletic Performance",
      "Sleep Hygiene",
      "Safety",
      "Made By Teens",
      "Adolescent Sleep",
      "School Start Times",
      "Sleep Science",
      "Perspectives",
      "Judgment and Health Risk Behavior",
      "Advocacy",
    ];
    videoSectionList.forEach((video) => {
      if (video.ContentCategory.length > 1) {
        video.ContentCategory.forEach((category) => {
          if (!contentCategories.includes(category)) {
            contentCategories.push(category);
          }
        });
      } else if (!contentCategories.includes(video.ContentCategory[0])) {
        contentCategories.push(video.ContentCategory[0]);
      }
    });

    contentCategories = contentCategories.filter(function (value, index, arr) {
      return value !== "Featured";
    });
    contentCategories.unshift("Featured");

    for (let index = 0; index < orderedContentCategories.length; index++) {
      if (!contentCategories.includes(orderedContentCategories[index])) {
        orderedContentCategories.splice(index, 1);
        index--;
      }
    }
    setContentCategories(orderedContentCategories);
  }

  //Maybe I should do this work in the generation of the video section?
  //Also, now trying to standardize the video sizes
  function generateTabPanels() {
    let tabPanels = [];
    for (let index = 0; index < contentCategories.length; index++) {
      tabPanels.push(
        <TabPanel style={{ width: "100%" }} value={index} index={index}>
          <Grid
            container
            item
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing="5"
          >
            {produceVideos(index)}
          </Grid>
        </TabPanel>,
      );
    }
    return tabPanels;
  }

  //Consider simplifying this function.
  function produceVideos(categoryIndex) {
    if (videoSectionList <= 0) {
      return;
    }
    let relevantVideos = [];
    //console.log("produceVideos running")
    videoSectionList.forEach((video, index) => {
      video.ContentCategory.forEach((category) => {
        if (contentCategories[categoryIndex] === category) {
          relevantVideos.push(
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              //style={{ minWidth: "400px", maxWidth: "400px" }}
            >
              <VideoCards Video={video}></VideoCards>
            </Grid>,
          );
        }
      });
    });
    if (categoryIndex === 0) {
      if (relevantVideos.length < 9) {
        for (let index = relevantVideos.length; index <= 8; index++) {
          let randomVideo = searchForFeaturedVideos(
            videoSectionList[randomNumber(0, videoSectionList.length - 1)],
            relevantVideos,
          );
          relevantVideos.push(
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              //style={{ minWidth: "400px", maxWidth: "400px" }}
            >
              <VideoCards Video={randomVideo}></VideoCards>
            </Grid>,
          );
        }
      }
    }

    return relevantVideos;
  }

  function searchForFeaturedVideos(randomVideo, relevantVideos) {
    for (let index = 0; index < relevantVideos.length; index++) {
      let establishedVideoTitle =
        relevantVideos[index].props.children.props.Video.VideoTitle;
      if (establishedVideoTitle === randomVideo.VideoTitle) {
        return searchForFeaturedVideos(
          videoSectionList[randomNumber(0, videoSectionList.length - 1)],
          relevantVideos,
        );
      }
    }
    return randomVideo;
  }

  function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  function showGeneratedVideos() {
    if (generatedTabPanels === null) {
      return;
    } else {
      return generatedTabPanels[value];
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid
      ref={videoSecRefEl}
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.WholeGridContainer}
    >
      <Grid
        container
        alignItems="center"
        direction="column"
        justify="center"
        className={classes.SubWholeGridContainer}
      >
        <Grid item xs={12} className={classes.TitleSection}>
          <Typography
            variant="h5"
            display="block"
            style={{
              color: "black",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Featured Videos and Webinar Archive
          </Typography>
          <Typography
            variant="body1"
            display="block"
            style={{ color: "black", textAlign: "center", marginTop: "0.5rem" }}
          >
            Select a topic to search video library
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.VideoSectionTabs}>
          <AppBar
            position="static"
            elevation="0"
            style={{ color: "black", backgroundColor: "white" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable force tabs example"
            >
              {contentCategories.map((element, index) => {
                return (
                  <Tab
                    label={element}
                    {...a11yProps(index)}
                    className={classes.ContentCategoryTab}
                    style={{
                      color: "white",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  />
                );
              })}
            </Tabs>
          </AppBar>
          {showGeneratedVideos()}
        </Grid>
      </Grid>
      <VideoBoxDialog
        open={openVideoBoxDialog}
        onClose={handleVideoBoxDialogClose}
        video={openVideoURL}
        videoSource={"external"}
      />
    </Grid>
  );
}

export default VideoSection;
