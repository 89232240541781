import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import TargetTopicURL from "../../../../data/TargetTopicURL.json";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import { Link } from "react-router-dom";

export function SubTopicNode(props) {
  const scrollRef = useRef(null);
  const playButtonRef = useRef(null);

  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [playButtonInfo, setPlayButtonInfo] = useState({
    height: 0,
    width: 0,
  });
  const stateChosen = localStorage.getItem("uSStateLocalStorage");

  function copyToClickBoard(e) {
    document.execCommand("copy");
  }

  const updatedWindowChange = () => {
    initialDisplayScrollArrow();
  };
  useEffect(() => {
    window.addEventListener("resize", updatedWindowChange);
    return () => {
      window.removeEventListener("resize", updatedWindowChange);
    };
  }, []);

  useEffect(() => {
    setScrollHeight(getScrollHeight);
    initialDisplayScrollArrow();
    return () => {};
  }, [scrollRef]);

  // useEffect(() => {
  //   setPlayButtonInfo({
  //     height: playButtonRef.current.clientHeight,
  //     width: playButtonRef.current.clientWidth,
  //   });
  //   return () => {};
  // }, [playButtonRef]);

  function initialDisplayScrollArrow() {
    var hasVerticalScrollbar =
      scrollRef.current.scrollHeight > scrollRef.current.clientHeight;
    if (hasVerticalScrollbar) {
      setShowScrollArrow(true);
    } else {
      setShowScrollArrow(false);
    }
  }

  function displayOnPartScroll() {
    if (scrollRef.current !== null) {
      var scrollClientDifference = Math.abs(
        scrollRef.current.scrollHeight - scrollRef.current.clientHeight,
      );

      var scrollDissapearPoint = scrollClientDifference * 0.8;
      if (scrollRef.current.scrollTop <= scrollDissapearPoint) {
        setShowScrollArrow(true);
      } else {
        setShowScrollArrow(false);
      }
    }
  }

  function getScrollHeight() {
    let scrollRefStyle = window.getComputedStyle(scrollRef.current);

    let verticalPadding =
      //parseFloat(scrollRefStyle.getPropertyValue("padding-top")) +
      parseFloat(scrollRefStyle.getPropertyValue("padding-bottom"));

    return scrollRef.current.scrollHeight - verticalPadding;
  }

  function checkCurriculumStandard() {
    if (props.selectedNode.CurriculumStandardFlag) {
      for (
        let index = 0;
        index < props.selectedNode.CurriculumStandards.length;
        index++
      ) {
        if (
          props.selectedNode.CurriculumStandards[index].selected_state ===
          stateChosen
        ) {
          return true;
        }
      }
    }
  }

  return (
    <Grid container className={props.classes.InnerParentGrid}>
      <div className={props.classes.ExitWindow}>
        <div style={{ position: "absolute", right: "16px" }}>
          <IconButton
            onClick={props.handleClose}
            style={{
              color: "white",
              padding: "0",
              display: "block",
            }}
          >
            <CancelPresentationIcon className={props.classes.CancelIcon} />
          </IconButton>
        </div>
      </div>
      <Grid
        direction="column"
        container
        item
        ref={scrollRef}
        className={props.classes.ContentDefinition}
        onScroll={() => {
          displayOnPartScroll();
        }}
      >
        <Grid
          container
          item
          justify="space-between"
          alignItems="center"
          direction="column"
          style={{
            flexWrap: "nowrap",
            display: "flex",
            //Works because parent Grid element is flex.
            margin: "auto auto",
          }}
        >
          {showScrollArrow && (
            <div
              className={props.classes.ScrollArrowPosition}
              style={{
                height: scrollHeight,
              }}
            >
              <div
                style={{
                  position: "-webkit-sticky",
                  position: "sticky",
                  top: "100%",
                }}
              >
                <ArrowDownwardIcon
                  className={props.classes.DownwardArrowScroll}
                />
              </div>
            </div>
          )}
          <Grid container item justify="center">
            <Grid item style={{ paddingBottom: "1rem" }}>
              <Typography
                variant={props.lessThan750px ? "h4" : "h3"}
                style={{
                  fontFamily: "'Bebas Neue', cursive",
                  textAlign: "center",
                }}
              >
                {/* &nbsp; &nbsp; &nbsp;  */}
                {props.selectedNode.Title}
                <Tooltip title={"Copy URL for " + props.selectedNode.Title}>
                  <Button
                    style={{ color: "white" }}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        TargetTopicURL.letssleepDomain +
                          props.role +
                          "/" +
                          props.selectedNode.Title.replace(/ /g, "_")
                            .replace(/[?!]/g, "")
                            .replace(/[:-]/g, "")
                            .replace("/", "_"),
                      )
                    }
                  >
                    <FileCopyIcon
                      fontSize="small"
                      style={{ verticalAlign: "middle" }}
                    />
                  </Button>
                </Tooltip>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justify="center"
            alignItems="center"
            className={props.classes.Single16By9Image}
            style={{
              backgroundImage: "url(" + props.selectedNodeImage + ")",
              backgroundPosition: "50% 50%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* {props.CreatePlayImageButton(props.selectedNode)} */}
            {props.CreateButton("PlayOnImage")}
          </Grid>
          <Grid
            container
            item
            style={{
              alignSelf: "center",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "0rem",
            }}
            className={props.classes.SingleDescriptionBox}
          >
            {checkCurriculumStandard() ? (
              <Grid item style={{ visibility: "hidden" }}>
                <IconButton
                  //onClick={props.handleClose}
                  style={{
                    color: "red",
                    paddingLeft: "16px",

                    //display: "block",
                  }}
                >
                  <InfoIcon
                    //className={props.classes.CancelIcon}
                    style={{ fontSize: "50px" }}
                  />
                </IconButton>
              </Grid>
            ) : (
              <div></div>
            )}
            <Grid item>{props.CreateButton("RectangleButton")}</Grid>
            {checkCurriculumStandard() ? (
              <Grid item>
                <Tooltip
                  title={
                    "This activity meets a curricular standard in " +
                    stateChosen +
                    "."
                  }
                  placement="right"
                >
                  <IconButton
                    component={Link}
                    to={{
                      pathname: `/curriculum-guide`,
                      state: {
                        selectedTopic: props.selectedNode,
                        currentState: stateChosen,
                        learningCenterTopics: props.learningCenterTopics,
                      },
                    }}
                    style={{
                      color: "red",
                      paddingLeft: "16px",
                    }}
                  >
                    <InfoIcon style={{ fontSize: "50px" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : (
              <div></div>
            )}
          </Grid>
          <Grid
            item
            style={{ paddingTop: "0rem" }}
            className={props.classes.SingleDescriptionBox}
          >
            <Typography
              variant="body1"
              className={props.classes.UnfurledDescription}
            >
              {
                //Old way before we brought in json data from WordPress API:
                /* {props.selectedNode.Content.split("\n").map((text, index) => (
                <React.Fragment>
                  {text}
                  <br />
                  <br />
                </React.Fragment>
              ))} */
              }
              <div
                dangerouslySetInnerHTML={{ __html: props.selectedNode.Content }}
              ></div>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
