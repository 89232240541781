import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import useLearningCenterAPI from "../../../hooks/useLearningCenterAPI";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  StandardAPIChange: {
    "& p": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  CurriculumDescription: {
    [theme.breakpoints.down(1300)]: {
      width: "90%",
    },
    fontFamily: "'Open Sans', sans-serif",
    textAlign: "center",
    width: "60%",
  },
  StateAndActivityButtons: {
    [theme.breakpoints.down(1300)]: {
      width: "100%",
    },
    width: "70%",
  },
  StateAndActivityDisplays: {
    [theme.breakpoints.down(1300)]: {
      width: "100%",
    },
    width: "70%",
  },
  MainContentBox: {
    [theme.breakpoints.down(1300)]: {
      width: "90%",
    },
    width: "70%",
    //height: "800px",
    border: "1px solid #627083",
    backgroundColor: "#EAF2FA",
    marginTop: "0.5rem",
  },
  StateButton: {
    [theme.breakpoints.down(960)]: {
      order: 1,
      width: "90%",
    },
    color: "#2D3F5E",
    border: "1px solid #627083",
    borderRadius: "0px",
    width: "20%",
  },
  ActivityButton: {
    [theme.breakpoints.down(960)]: {
      order: 3,
      width: "90%",
    },
    color: "#2D3F5E",
    border: "1px solid #627083",
    borderRadius: "0px",
    width: "60%",
  },
  StateButtonOutput: {
    [theme.breakpoints.down(960)]: {
      order: 2,
      width: "90%",
    },
    width: "20%",
    border: "1px solid #627083",
    backgroundColor: "#5B9BD5",
  },
  ActivityButtonOutput: {
    [theme.breakpoints.down(960)]: {
      order: 4,
      width: "90%",
      maxHeight: "104px",
    },
    maxHeight: "104px",
    width: "60%",
    border: "1px solid #627083",
    backgroundColor: "#5B9BD5",
  },
  ActivityImage: {
    [theme.breakpoints.down(960)]: {
      width: 300,
    },
    [theme.breakpoints.down(600)]: {
      width: 200,
    },
    width: 400,
  },
}));

function CurriculumGuide(props) {
  const classes = useStyles();
  const allUSStates = usStates;
  const generatedRoleRoutes = useLearningCenterAPI();
  const [teacherData, setTeacherData] = useState([]);
  const location = useLocation();
  const [stateSelected, setStatedSelected] = useState();
  const [selectedActivity, setSelectedActivity] = useState();
  const [curriculumStandards, setCurriculumStandards] = useState();
  const [allActivities, setAllActivities] = useState();
  const [anchorElState, setAnchorElState] = useState(null);
  const [anchorElActivity, setAnchorElActivity] = useState(null);
  const stateSelectBox = useRef();
  const activitySelectBox = useRef();
  const [attainedActivityGeneration, setAttainedActivityGeneration] =
    useState(false);
  const [loadingActivities, setLoadingActivities] = useState(true);

  //Happens before everything else.
  useEffect(() => {
    let specificState = new URLSearchParams(location.search).get(
      "specific_state",
    );

    if (location.state === undefined) {
      setStatedSelected("All");
      //Find the first activity in allActivities for setSelectedActivity
      setSelectedActivity({ Title: "All" });
    } else {
      setStatedSelected(location.state.currentState);
      setSelectedActivity(location.state.selectedTopic);
    }
    //I can then do a check of specificState against the list of acceptable states as a function to make sure that it matches exactly and you can't put
    //screw strings there.
    //http://localhost:3000/curriculum-guide?specific_state=Maryland
    if (specificState !== null && checkURLStateValiditiy(specificState)) {
      setStatedSelected(specificState);
    }

    return () => {};
  }, [location.state]);

  useEffect(() => {
    setTeacherData(generatedRoleRoutes.teacherData);

    return () => {};
  }, [generatedRoleRoutes]);

  useEffect(() => {
    if (teacherData.length > 0) {
      setAllActivities(ascertainAllActivities(teacherData));
    }

    return () => {};
  }, [teacherData]);

  useEffect(() => {
    if (selectedActivity !== undefined) {
      setCurriculumStandards(selectedActivity.CurriculumStandards);
    }

    return () => {};
  }, [selectedActivity]);

  function checkURLStateValiditiy(state) {
    for (let index = 0; index < usStates.length; index++) {
      if (state === usStates[index]) {
        console.log("Valid");
        return true;
      }
    }
  }

  function ascertainAllActivities(tData) {
    let curriculumActivities = [];

    for (let index = 0; index < tData.length; index++) {
      if (tData[index].SubTopics === undefined) {
        return;
      }
      if (tData[index].SubTopics.length > 0) {
        const subTopicLength = tData[index].SubTopics.length;
        const allSubTopics = tData[index].SubTopics;
        for (let index = 0; index < subTopicLength; index++) {
          if (allSubTopics[index].CurriculumStandardFlag) {
            curriculumActivities.push(allSubTopics[index]);
          }
        }
      }
    }
    //console.log(curriculumActivities);
    return curriculumActivities;
  }

  function ascertainRelevantStandards() {}

  const handleStateClick = (event) => {
    setAnchorElState(event.currentTarget);
  };

  const handleStateClose = (state) => {
    if (typeof state === "string") {
      changeState(state);
    }
    setAnchorElState(null);
  };

  function changeState(state) {
    setStatedSelected(state);
  }

  const handleActivityClick = (event) => {
    setAnchorElActivity(event.currentTarget);
  };

  const handleActivityClose = (activity) => {
    if (typeof activity.Title === "string") {
      changeActivity(activity);
    }
    setAnchorElActivity(null);
  };

  function changeActivity(activity) {
    setSelectedActivity(activity);
  }

  function determineLoading(loading) {
    if (loading) {
      return <CircularProgress />;
    } else {
      return <div></div>;
    }
  }

  function GenerateSelectionContent() {
    if (selectedActivity === undefined) {
      return;
    }
    if (allActivities === undefined) {
      return;
    }
    // if (attainedActivityGeneration){
    //   return;
    // }
    let displayedActivities = [];

    if (selectedActivity.Title === "All") {
      if (stateSelected === "All") {
        //Display all activities and all state standards for each activity.
        allActivities.map((activity, i) => {
          displayedActivities.push(generateActivityTitle(activity));
          displayedActivities.push(generateActivityImage(activity));
          displayedActivities.push(generateActivityContent(activity));
          displayedActivities.push(
            <Grid container xs={12} justify="center">
              <Grid
                style={{
                  width: "80%",
                  backgroundColor: "#E7E6E6",
                  marginBottom: "1rem",
                  padding: "1rem",
                  maxHeight: "800px",
                  overflow: "auto"
                }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "'Open Sans', sans-serif",
                  }}
                >
                  <b>Relevant Health Education Standards</b>
                </Typography>
                {allUSStates.map((state) => {
                  let generatedStandards = [];

                  activity.CurriculumStandards.map((standard) => {
                    if (state === standard.selected_state) {
                      generatedStandards.push(
                        <Grid container>
                          <Typography
                            style={{ fontFamily: "'Open Sans', sans-serif" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: standard.learning_standard_description,
                              }}
                              className={classes.StandardAPIChange}
                            ></div>
                          </Typography>
                        </Grid>,
                      );
                    }
                  });
                  return generatedStandards;
                })}
              </Grid>
            </Grid>,
          );
          if (i < allActivities.length - 1) {
            displayedActivities.push(generateDivider());
          }
        });
      } else {
        //Display all activities but only the state standards for a specific state.
        allActivities.map((activity, i) => {
          let generateActivity = false;
          activity.CurriculumStandards.map((standard) => {
            if (stateSelected === standard.selected_state) {
              generateActivity = true;
            }
          });

          if (generateActivity) {
            displayedActivities.push(generateActivityTitle(activity));
            displayedActivities.push(generateActivityImage(activity));
            displayedActivities.push(generateActivityContent(activity));
            displayedActivities.push(
              <Grid container xs={12} justify="center">
                <Grid
                  style={{
                    width: "80%",
                    backgroundColor: "#E7E6E6",
                    marginBottom: "1rem",
                    padding: "1rem",
                    maxHeight: "800px",
                    overflow: "auto"
                  }}
                >
                  <Typography
                    style={{
                      textAlign: "center",
                      fontFamily: "'Open Sans', sans-serif",
                    }}
                  >
                    <b>Relevant Health Education Standards</b>
                  </Typography>
                  {activity.CurriculumStandards.map((standard) => {
                    if (standard.selected_state === stateSelected) {
                      return (
                        <Grid container>
                          <Typography
                            style={{ fontFamily: "'Open Sans', sans-serif" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: standard.learning_standard_description,
                              }}
                              className={classes.StandardAPIChange}
                            ></div>
                          </Typography>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Grid>,
            );
          }
          if (i < allActivities.length - 1) {
            displayedActivities.push(generateDivider());
          }
        });
      }
    } else {
      if (stateSelected === "All") {
        //Display that specific activity but show all state curriculum standards.
        displayedActivities.push(generateActivityTitle(selectedActivity));
        displayedActivities.push(generateActivityImage(selectedActivity));
        displayedActivities.push(generateActivityContent(selectedActivity));
        if (selectedActivity.CurriculumStandards !== undefined) {
          displayedActivities.push(
            <Grid container xs={12} justify="center">
              <Grid
                style={{
                  width: "80%",
                  backgroundColor: "#E7E6E6",
                  marginBottom: "1rem",
                  padding: "1rem",
                  maxHeight: "800px",
                  overflow: "auto"
                }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "'Open Sans', sans-serif",
                  }}
                >
                  <b>Relevant Health Education Standards</b>
                </Typography>
                {selectedActivity.CurriculumStandards.map((standard) => {
                  return (
                    <Grid container>
                      <Typography
                        style={{ fontFamily: "'Open Sans', sans-serif" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: standard.learning_standard_description,
                          }}
                          className={classes.StandardAPIChange}
                        ></div>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>,
          );
        }
      } else {
        //Display specific state and specific activity
        displayedActivities.push(generateActivityTitle(selectedActivity));
        displayedActivities.push(generateActivityImage(selectedActivity));
        displayedActivities.push(generateActivityContent(selectedActivity));
        if (selectedActivity.CurriculumStandards !== undefined) {
          displayedActivities.push(
            <Grid container xs={12} justify="center">
              <Grid
                style={{
                  width: "80%",
                  backgroundColor: "#E7E6E6",
                  marginBottom: "1rem",
                  padding: "1rem",
                  maxHeight: "800px",
                  overflow: "auto"
                }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "'Open Sans', sans-serif",
                  }}
                >
                  <b>Relevant Health Education Standards</b>
                </Typography>
                {selectedActivity.CurriculumStandards.map((standard) => {
                  if (standard.selected_state === stateSelected) {
                    return (
                      <Grid container>
                        <Typography
                          style={{ fontFamily: "'Open Sans', sans-serif" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: standard.learning_standard_description,
                            }}
                            className={classes.StandardAPIChange}
                          ></div>
                        </Typography>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Grid>,
          );
        }
      }
    }
    //setAttainedActivityGeneration(true)
    
    //setLoadingActivities(false);

    if (loadingActivities){
      setLoadingActivities(false)
    }
    return displayedActivities;

    function generateDivider() {
      return (
        <Divider
          variant="middle"
          light="true"
          style={{
            //flexGrow: "1",
            margin: "auto",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 1)",
            height: "1px",
          }}
        />
      );
    }

    function generateActivityContent(activity) {
      return (
        <Grid container xs={12} justify="center">
          <Typography
            style={{
              width: "80%",
              fontFamily: "'Open Sans', sans-serif",
              hyphens: "auto",
              overflowWrap: "break-word",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: activity.Content }}></div>
          </Typography>
        </Grid>
      );
    }

    function generateActivityImage(activity) {
      return (
        <Grid
          container
          xs={12}
          justify="center"
          style={{ marginBottom: "1rem" }}
        >
          <img src={activity.Image} className={classes.ActivityImage}></img>
        </Grid>
      );
    }

    function generateActivityTitle(activity) {
      return (
        <Grid
          container
          xs={12}
          justify="center"
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          <Typography
            variant="h5"
            style={{ fontFamily: "'Bebas Neue', cursive", textAlign: "center" }}
          >
            {activity.Title}
          </Typography>
        </Grid>
      );
    }
  }

  return (
    <div>
      {/* {console.log(stateSelected)}
      {console.log(selectedActivity)}
      {console.log(curriculumStandards)}
      {console.log(allActivities)} */}
      <Grid xs={12} style={{ width: "100%", marginTop: "2rem" }}>
        <Typography
          variant="h5"
          style={{ fontFamily: "'Open Sans', sans-serif", textAlign: "center" }}
        >
          <b>Sleep Health Curriculum Standard Guide</b>
        </Typography>
      </Grid>
      <Grid
        container
        xs={12}
        justify="center"
        style={{ width: "100%", marginTop: "1rem" }}
      >
        <Typography variant="body1" className={classes.CurriculumDescription}>
          Select your state and/or an activity to view relevant sleep health
          curricula resources, relevant grade levels, and learning objectives.
          Note that some activities meet more than one health curricular
          standard in each state.
        </Typography>
      </Grid>
      <Grid
        container
        xs={12}
        justify="center"
        style={{ width: "100%", marginTop: "1rem", marginBottom: "1rem" }}
      >
        <Grid
          container
          justify="center"
          item
          className={classes.StateAndActivityButtons}
        >
          <Button
            ref={stateSelectBox}
            aria-controls="StateLocation"
            aria-haspopup="true"
            onClick={handleStateClick}
            size="medium"
            className={classes.StateButton}
          >
            <Typography
              variant="h6"
              style={{ fontFamily: "'Bebas Neue', cursive" }}
            >
              State/Location
            </Typography>
            <ArrowDropDownIcon
              style={{
                fontSize: "40px",
                color: "#FF0000",
              }}
            />
          </Button>
          <Button
            ref={activitySelectBox}
            aria-controls="Activity"
            aria-haspopup="true"
            onClick={handleActivityClick}
            size="medium"
            className={classes.ActivityButton}
          >
            <Typography
              variant="h6"
              style={{ fontFamily: "'Bebas Neue', cursive" }}
            >
              Activity
            </Typography>
            <ArrowDropDownIcon
              style={{
                fontSize: "40px",
                color: "#FF0000",
              }}
            />
          </Button>
          <Menu
            id="StateLocation"
            anchorEl={anchorElState}
            keepMounted
            open={Boolean(anchorElState)}
            onClose={handleStateClose}
          >
            {allUSStates.map((state) => {
              return (
                <MenuItem onClick={() => handleStateClose(state)}>
                  {state}
                </MenuItem>
              );
            })}
            ;
          </Menu>
          <Menu
            id="Activity"
            anchorEl={anchorElActivity}
            keepMounted
            open={Boolean(anchorElActivity)}
            onClose={handleActivityClose}
          >
            <MenuItem onClick={() => handleActivityClose({ Title: "All" })}>
              All
            </MenuItem>
            {allActivities !== undefined ? (
              allActivities.map((activity) => {
                return (
                  <MenuItem onClick={() => handleActivityClose(activity)}>
                    {activity.Title}
                  </MenuItem>
                );
              })
            ) : (
              <div></div>
            )}
          </Menu>
          <Grid item style={{ flexBasis: "100%", height: 0 }}></Grid>
          <Grid
            container
            className={classes.StateButtonOutput}
            style={{
              height: stateSelectBox.current
                ? stateSelectBox.current.clientHeight
                : "0px",
            }}
          >
            <Typography
              variant="body1"
              style={{
                display: "block",
                // height: "100%",
                // width: "100%"
                margin: "auto",
                fontFamily: "'Open Sans', sans-serif",
              }}
            >
              {stateSelected}
            </Typography>
          </Grid>
          {selectedActivity ? (
            <Grid
              container
              className={classes.ActivityButtonOutput}
              style={{
                minHeight: activitySelectBox.current
                  ? activitySelectBox.current.clientHeight
                  : "0px",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  display: "block",
                  // height: "100%",
                  // width: "100%"
                  maxHeight: "104px",
                  margin: "auto",
                  fontFamily: "'Open Sans', sans-serif",
                }}
              >
                {selectedActivity.Title}
              </Typography>
            </Grid>
          ) : (
            <div></div>
          )}
        </Grid>
        {/* <Grid
          container
          justify="center"
          item
          className={classes.StateAndActivityDisplays}
        >
         
        </Grid> */}
        <Grid container item justify="center" className={classes.MainContentBox}>
          {determineLoading(loadingActivities)}
          {GenerateSelectionContent()}
        </Grid>
      </Grid>
      <div style={{ height: "3rem" }}></div>
    </div>
  );
}

export default CurriculumGuide;

const usStates = [
  "All",
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "District of Columbia",
];
