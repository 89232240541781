import React from "react";
import SiteGenerator from "./siteGenerator/SiteGenerator";
import "./css/App.css";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <SiteGenerator className="App" />
    </Router>
  );
}

export default App;
