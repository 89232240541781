import { makeStyles } from "@material-ui/core/styles";
import SleepingBanana from "../../images/PlayfulImages/SleepingBanana.png";
import Pencil from "../../images/PlayfulImages/pencil.png";
import LightningBolt from "../../images/PlayfulImages/lightningbolt.png";
import HatchMarks from "../../images/PlayfulImages/hatchdrawing.png";
import ArrowDoodle from "../../images/PlayfulImages/arrowdoodle.png";

export const TableOfContentsMS = makeStyles((theme) => ({
  TOCButtons: {
    textTransform: "none",
    zIndex: "100",
    //fontWeight: 500,
    justifyContent: "inherit",
    textAlign: "left",
    color: "white",
    fontFamily: "'Bebas Neue', cursive",
    "&:hover": {
      backgroundColor: "#406296",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.375rem",
    },
    [theme.breakpoints.between(1000, 1280)]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.between(600, 1000)]: {
      fontSize: "1.125rem",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "1rem",
    },

    [theme.breakpoints.down(1000)]: {
      padding: 0,
    },
  },
  TOCSubButtons: {
    textTransform: "none",
    justifyContent: "inherit",
    textAlign: "left",
    fontFamily: "'Open Sans', sans-serif",
    zIndex: "100",
    //fontWeight: 400,
    color: "white",
    "&:hover": {
      backgroundColor: "#406296",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "0.75rem",
    },
    fontSize: "0.875rem",
    [theme.breakpoints.down(1000)]: {
      padding: 0,
    },
  },
  TableOfContentText: {
    fontFamily: "'Bebas Neue', cursive",
    [theme.breakpoints.down(1280)]: {
      fontSize: "1.375rem",
    },
    fontSize: "1.5rem",
    textAlign: "center",
    color: "rgba(250,195,19,1)",
    zIndex: "100",
    backgroundColor: "rgba(45, 63, 94,0.8)",
  },
  SleepingBananaImg: {
    position: "absolute",
    top: "100%",
    right: "0px",
    transform: "translate(0,-100%)",
    backgroundImage: `url(${SleepingBanana})`,
    [theme.breakpoints.down(1650)]: {
      width: "200px",
      height: "200px",
    },
    width: "300px",
    height: "300px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "right",
    filter: "invert(1)",
    zIndex: -1,
  },
  PencilImg: {
    position: "absolute",
    top: "35%",
    right: "-7%",
    transform: "translate(0,0%)",
    backgroundImage: `url(${Pencil})`,
    [theme.breakpoints.down(1280)]: {
      width: "120px",
      height: "102px",
    },
    width: "160px",
    height: "136px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "right",
    filter: "invert(1)",
    zIndex: -1,
  },
  LightningBoltImg: {
    position: "absolute",
    top: "0%",
    right: "95%",
    transform: "translate(100%,0%)",
    backgroundImage: `url(${LightningBolt})`,
    //width: "50%",
    width: "50px",
    height: "50px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    //backgroundPosition: "right",
    filter: "invert(1)",
    zIndex: -1,
  },
  HatchDrawingImg: {
    position: "absolute",
    top: "15%",
    right: "100%",
    transform: "translate(100%,0%)",
    backgroundImage: `url(${HatchMarks})`,
    //width: "50%",
    width: "160px",
    height: "125.25px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    //backgroundPosition: "right",
    filter: "invert(1)",
    zIndex: -1,
  },
  ArrowDoodleImg: {
    position: "absolute",
    top: "0%",
    right: "0%",
    //transform: "translate(100%,0%)",
    backgroundImage: `url(${ArrowDoodle})`,
    //width: "50%",
    width: "75px",
    height: "75px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    //backgroundPosition: "right",
    "&:-webkit-filter": "invert(1)",
    "&:-ms-filter": "invert(1)",
    filter: "invert(1)",
    transform: "rotate(270deg)",
    zIndex: -1,
  },
}));
