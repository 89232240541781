import React, { useState, useCallback, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import ParentVideoImage from "../../../images/parentvideolanding.png";
import SleepNumber from "../../../images/Partners/SleepNumberLogo.png";
import RedParentArrow from "../../../images/RedParentArrow.png";
import VideoBoxDialog from "../videoSection/VideoBoxDialog";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import IconButton from "@material-ui/core/IconButton";

const ParentsLCLandingPage = React.forwardRef((props, ref) => {
  const [openVideoBoxDialog, setOpenVideoBoxDialog] = useState(false);
  const [openVideoURL, setOpenVideoURL] = useState(
    "https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FHuffPost%2Fvideos%2F10153858424021130%2F&show_text=false&width=560&t=0",
  );

  const handleVideoBoxDialogOpen = () => {
    setOpenVideoBoxDialog(true);
  };

  const handleVideoBoxDialogClose = () => {
    setOpenVideoBoxDialog(false);
  };

  const playButtonRef = useRef(null);
  // const sleepNumberImageRef = useRef();
  // const [dimensionsImage, setDimensionsImage] = useState({
  //   width: 0,
  //   height: 0,
  // });

  return (
    <Grid
      container
      direction="row"
      item
      ref={ref}
      xs={8}
      sm={9}
      md={10}
      className={props.classes.ParentPictureAreaLearningCenter}
    >
      <Hidden smDown>
        <Grid container item className={props.classes.ParentSelectATopic}>
          <Grid xs={12} container justify="center">
            <Typography
              variant="h6"
              align="center"
              style={{
                color: "black",
                textAlign: "center",
                maxWidth: "250px",
                fontFamily: "'Bebas Neue', cursive",
                fontSize: "2rem",
              }}
            >
              Select a topic
            </Typography>
          </Grid>
          <Grid xs={2} container style={{ alignSelf: "center" }}>
            <img
              src={RedParentArrow}
              style={{ width: "300%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Hidden>
      <Grid
        container
        direction="row"
        item
        alignItems="flex-end"
        justify=""
        className={props.classes.ParentLandingPageContentUpper}
      >
        <Grid
          container
          direction="row"
          item
          justify="center"
          //alignItems="flex-end"
          style={{ width: "100%", marginBottom: "0.5rem" }}
        >
          <Grid
            container
            justify="center"
            xs={12}
            item
            className={props.classes.ParentTitleTextUpper}
          >
            <Typography
              align="center"
              className={props.classes.ParentTitleText}
            >
              Our kids are <span style={{ color: "#406296" }}>tired.</span>
            </Typography>
          </Grid>
          <Grid
            container
            justify="center"
            xs={12}
            item
            className={props.classes.ParentTitleTextMiddle}
          >
            <Typography
              align="center"
              className={props.classes.ParentTitleText}
            >
              Sleep is <span style={{ color: "red" }}>essential.</span>
            </Typography>
          </Grid>
          <Grid
            container
            justify="center"
            xs={12}
            item
            className={props.classes.ParentTitleTextLower}
          >
            <Typography
              align="center"
              className={props.classes.ParentTitleText}
            >
              It's time for a <span style={{ color: "#5BA300" }}>change.</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        item
        alignItems="center"
        justify=""
        className={props.classes.ParentLandingPageContentLower}
      >
        <Grid
          container
          direction="row"
          item
          justify="space-between"
          alignItems="center"
          style={{ flexWrap: "nowrap" }}
        >
          <Grid
            container
            //xs={4}
            direction="row"
            item
            justify="flex-start"
            className={props.classes.ParentPictureSleepNumber}
          >
            <Grid
              container
              item
              direction="column"
              alignItems="center"
              style={{ width: "50%", height: "100%" }}
            >
              <Typography className={props.classes.ParentSleepNumberText}>
                Thank you to our sponsor
              </Typography>
              <img
                src={SleepNumber}
                className={props.classes.ParentPictureSleepNumberImage}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    "https://www.sleepnumber.com/pages/sleep-science",
                    "_blank",
                  );
                }}
              />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid
              container
              //xs={4}
              direction="row"
              item
              justify="flex-end"
              onClick={handleVideoBoxDialogOpen}
              style={{}}
            >
              <Grid
                container
                item
                direction="column"
                alignItems="flex-end"
                style={{ width: "100%", height: "100%" }}
              >
                <Typography
                  variant="body1"
                  className={props.classes.LowerRightCornerParentVideoText}
                >
                  What's the big deal?
                </Typography>
                <IconButton style={{ padding: 0 }}>
                  <Grid
                    ref={playButtonRef}
                    src={ParentVideoImage}
                    className={props.classes.LowerRightCornerParentVideo}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <PlayCircleFilledIcon
                      //ref={playButtonRef}
                      style={{
                        zIndex: 100,
                        opacity: "90%",
                        fontSize: "3.5rem",
                        color: "#5BA300",
                        backgroundColor: "white",

                        borderRadius: "50%",
                      }}
                    ></PlayCircleFilledIcon>
                  </Grid>
                </IconButton>

                <VideoBoxDialog
                  open={openVideoBoxDialog}
                  onClose={handleVideoBoxDialogClose}
                  video={openVideoURL}
                  videoSource={"external"}
                />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default ParentsLCLandingPage;
