import React, { useState, useCallback, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "react-slick";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const AdminCLLandingPage = React.forwardRef((props, ref) => {
  const quoteSliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 15000,
    autoplaySpeed: 100,
    cssEase: "linear",
    arrows: false,
    draggable: false,
    swipe: false,
    touchMove: false,
  };

  return (
    <Grid
      container
      direction="row"
      item
      ref={ref}
      xs={8}
      sm={9}
      md={10}
      className={props.classes.AdminPictureAreaLearningCenter}
    >
      <Hidden smDown>
        <Grid
          container
          item
          className={props.classes.AdminSelectATopic}
        >
          <Grid xs={2} container style={{ alignSelf: "center" }}>
            <ArrowBackIosIcon
              className={props.classes.TeacherLandingBlinkingArrow}
              style={{
                color: "rgba(45,63,94,1)",
                fontSize: "3.5rem",
              }}
            ></ArrowBackIosIcon>
          </Grid>
          <Grid
            xs={10}
            container
            justify="flex-start"
            alignItems="center"
            style={{}}
          >
            <Typography
              variant="body1"
              align="center"
              className={props.classes.AdminSelectATopicText}
            >
              <b>SELECT A TOPIC</b>
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
      <Grid
        container
        direction="column"
        item
        alignItems="center"
        className={props.classes.AdminLandingPageContent}
      >
        <Hidden mdDown>
          <Grid item style={{ width: "100%" }}>
            <Slider
              {...quoteSliderSettings}
              style={{ maxWidth: "100%", height: "100%", overflow: "hidden" }}
            >
              {/* <div>This is the 1 text.</div>
              <div>This is the 2 text.</div> */}
              <div>
                <Typography
                  align="center"
                  className={props.classes.AdminLandingPageQuotes}
                >
                  “It was inspirational to see how strong leaders who care about
                  kids can make things happen.”
                </Typography>
              </div>
              <div>
                <Typography
                  align="center"
                  className={props.classes.AdminLandingPageQuotes}
                >
                  “So informative and fascinating!”
                </Typography>
              </div>
              <div>
                <Typography
                  align="center"
                  className={props.classes.AdminLandingPageQuotes}
                >
                  “Hope you are planning more [webinars] for school and
                  community leaders!”
                </Typography>
              </div>
            </Slider>
          </Grid>
        </Hidden>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justify="flex-start"
        >
          <Grid item>
            <Typography className={props.classes.AdminLandingPageTitle}>
              Let's Sleep Webinar Series
            </Typography>
          </Grid>
          <Grid
            item
            className={props.classes.AdminLandingPageDescriptionContainer}
          >
            <Typography
              variant="body1"
              align="center"
              className={props.classes.AdminLandingPageDescription}
            >
              A series of webinars featuring sleep scientists, school
              administrators, policymakers, health professionals, and more
              covering topics of relevance to superintendents, school board
              members, principals and other educators.
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            className={props.classes.AdminLandingPageButton}
            onClick={() => {
              window.open("http://sleep101.letssleep.org/webinar/");
            }}
          >
            <Typography className={props.classes.AdminLandingPageButtonText}>
              Upcoming Events
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default AdminCLLandingPage;
