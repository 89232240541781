import React, { useState, useEffect, useRef } from "react";
import LearningCenterDialogMS from "../../../makeStyles/LearningCenterDialogMS";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import { LCDPlayableVideo } from "./LCDPlayableVideo";
import { LCDPlayableImage } from "./LCDPlayableImage";
import { LCDArticulatePlayable } from "./LCDArticulatePlayable";
import { SubTopicNode } from "./SubTopicNode";
import { UnfurledParentNode } from "./UnfurledParentNode";
import PlayableButton from "./PlayableButton";
import { PlayableRectangleButton } from "./PlayableButton";

/**

  Note: I can use Button Link as a flag to use to show a button or not.
  Note: Later: Make video and text and image logic separate in browserPlayable function below. 
 */

function LCDialogPopup({
  onClose,
  open,
  subjectTitle,
  learningCenterTopics,
  role,
  changeRoleCallback,
  ...props
}) {
  const classes = LearningCenterDialogMS(props);
  const [selectedNode, setSelectedNode] = useState({});
  const [openParent, setOpenParent] = useState(false);
  const [openArticulatePlayable, setOpenArticlatePlayable] = useState(false);
  const [openBrowserPlayable, setOpenBrowserPlayable] = useState(false);
  const lessThan750px = useMediaQuery("(max-width:749px)");
  const contentRef = useRef(null);

  useEffect(() => {
    findNode(subjectTitle);
    return () => {};
  }, [subjectTitle]);

  const buildLearningCenterContent = () => {
    if (openBrowserPlayable) {
      return buildBrowserPlayables();
    } else if (openArticulatePlayable) {
      return buildArticulatePlayables();
    } else {
      return generateLearningStructure();
    }
  };

  //Untested in student and teacher sections
  const buildBrowserPlayables = () => {
    if (selectedNode.ButtonType === "Video") {
      let videoPlayable;
      videoPlayable = selectedNode.ButtonLink;

      return (
        <LCDPlayableVideo
          classes={classes}
          Video={videoPlayable}
          handleClose={handleClose}
        />
      );
    } else if (selectedNode.ButtonType === "Text and Image") {
      let playableContentImage = selectedNode.TextImage_Image.url;
      return (
        <LCDPlayableImage
          classes={classes}
          handleClose={handleClose}
          playableContentImage={playableContentImage}
          lessThan750px={lessThan750px}
          selectedNode={selectedNode}
        />
      );
    }
  };

  const buildArticulatePlayables = () => {
    return (
      <LCDArticulatePlayable
        InnerParentPlayable={classes.InnerParentPlayable}
        //Title={selectedNode.PlayableContent.Title}
        selectedNode={selectedNode}
        lessThan750px={lessThan750px}
        handleClose={handleClose}
      />
    );
  };

  const generateLearningStructure = () => {
    if (nodeIsSubTopic()) {
      let selectedNodeImage = selectedNode.Image;

      return (
        <SubTopicNode
          classes={classes}
          handleClose={handleClose}
          lessThan750px={lessThan750px}
          selectedNode={selectedNode}
          selectedNodeImage={selectedNodeImage}
          CreateButton={createPlayableButtonDirective}
          role={role}
          learningCenterTopics={learningCenterTopics}
        />
      );
    } else if (nodeIsMainTopic()) {
      return (
        <UnfurledParentNode
          classes={classes}
          handleClose={handleClose}
          contentRef={contentRef}
          lessThan750px={lessThan750px}
          selectedNode={selectedNode}
          CreateButton={createPlayableButtonDirective}
          role={role}
          learningCenterTopics={learningCenterTopics}
        />
      );
    }
  };

  function createPlayableButtonDirective(button, alternativeNode = null) {
    let node;
    if (alternativeNode !== null) {
      node = alternativeNode;
    } else {
      node = selectedNode;
    }
    if (button === "PlayOnImage") {
      return (
        <PlayableButton
          subTopicObject={node}
          setSelectedNode={setSelectedNode}
          setOpenBrowserPlayable={setOpenBrowserPlayable}
          setOpenArticlatePlayable={setOpenArticlatePlayable}
          classes={classes}
          role={role}
        ></PlayableButton>
      );
    } else if (button === "RectangleButton") {
      return PlayableRectangleButton(
        node,
        setSelectedNode,
        setOpenBrowserPlayable,
        setOpenArticlatePlayable,
        handleUnfurlParent,
        classes,
        role,
      );
    }
  }

  const nodeIsMainTopic = () => {
    if (learningCenterTopics.some((section) => section === selectedNode)) {
      return true;
    }
    return false;
  };

  const nodeIsSubTopic = () => {
    for (let index = 0; index < learningCenterTopics.length; index++) {
      for (let j = 0; j < learningCenterTopics[index].SubTopics.length; j++) {
        if (learningCenterTopics[index].SubTopics[j] === selectedNode) {
          return true;
        }
      }
    }
    return false;
  };

  const handleUnfurlParent = () => {
    setOpenParent(!openParent);
  };

  const handleClose = () => {
    onClose();
    resetStates();
  };

  const resetStates = () => {
    setSelectedNode({});
    setOpenBrowserPlayable(false);
    setOpenArticlatePlayable(false);
  };

  const findNode = (title) => {
    let node;

    for (let index = 0; index < learningCenterTopics.length; index++) {
      if (learningCenterTopics[index].SectionTitle === title) {
        node = learningCenterTopics[index];

        break;
      }
      for (let j = 0; j < learningCenterTopics[index].SubTopics.length; j++) {
        if (learningCenterTopics[index].SubTopics[j].Title === title) {
          node = learningCenterTopics[index].SubTopics[j];
          break;
        }
      }
    }

    setSelectedNode(node);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
      //disableEscapeKeyDown={true}
      style={{
        overflowY: "auto",
        overflowX: "hidden",
      }}
      PaperProps={{
        classes: { root: classes.DialogBox },
      }}
    >
      {buildLearningCenterContent()}
    </Dialog>
  );
}

export default LCDialogPopup;
