import React from "react";
//import LearningTree from "../Data/LearningCenterTree.json";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import Hidden from "@material-ui/core/Hidden";
import { TableOfContentsMS } from "../../makeStyles/TableOfContentsMS";

function TableOfContents(props) {
  const classes = TableOfContentsMS(props);
  const { LearningTree } = props;

  const generateTableOfContents = () => {
    let treeItems = [];
    let nodeId = 0;
    let subTopicCount = -1;

    treeItems.push(
      <div key={0}>
        <Typography className={classes.TableOfContentText}>
          Learning Center
        </Typography>
        <Divider
          variant="middle"
          light={true}
          style={{
            //flexGrow: "1",
            margin: "auto",
            width: "100%",
            height: "1px",
            backgroundColor: "rgba(250, 195, 19, 1)",
            zIndex: "100",
          }}
        />
      </div>,
    );
    //I think I could make the labels buttons, thereby separating
    //the arrow click functionality from clicking on the button
    //functionality so they don't get confused.
    for (let index = 0; index < Object.keys(LearningTree).length; index++) {
      treeItems.push(
        <div key={treeItems.length}>
          <TreeItem
            nodeId={(nodeId++).toString()}
            label={treeItemButton(LearningTree[index].SectionTitle)}
            style={{ backgroundColor: "rgba(45, 63, 94,0.8)" }}
          >
            {LearningTree[index].SubTopics.map((subTopic, j) => {
              let lastItem = false;
              if (LearningTree[index].SubTopics.length === j + 1) {
                lastItem = true;
              }
              return (
                <div key={j} style={{}}>
                  <TreeItem
                    nodeId={(nodeId++).toString()}
                    label={treeItemSubButton(subTopic.Title)}
                  />
                  {lastItem === false ? (
                    <Divider
                      light="true"
                      style={{
                        width: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        zIndex: "100",
                        position: "relative",
                      }}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              );
            })}
          </TreeItem>
          <Divider
            //variant="middle"
            light={true}
            style={{
              //flexGrow: "1",
              marginRight: "auto",
              width: "100%",
              backgroundColor: "rgba(250, 195, 19, 1)",
              zIndex: "100",
              position: "relative",
            }}
          />
        </div>,
      );
    }
    return treeItems;
  };

  const treeItemButton = (buttonName) => {
    return (
      <Button
        fullWidth
        onClick={() => sendLearningNode(buttonName)}
        className={classes.TOCButtons}
      >
        {buttonName}
      </Button>
    );
  };
  const treeItemSubButton = (buttonName) => {
    return (
      <Button
        fullWidth
        onClick={() => sendLearningNode(buttonName)}
        className={classes.TOCSubButtons}
      >
        {buttonName}
      </Button>
    );
  };

  const sendLearningNode = (title) => {
    window.scrollTo({ top: 0 });
    props.onDialogClick(title);
  };

  const placePlayfulImages = () => {
    return (
      <div>
        <Hidden smDown>
          <div className={classes.SleepingBananaImg}></div>
        </Hidden>
        <div className={classes.PencilImg}></div>
        <div className={classes.LightningBoltImg}></div>
        {/* <div className={classes.HatchDrawingImg}></div> */}
        <div className={classes.ArrowDoodleImg}></div>
      </div>
    );
  };

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon style={{ zIndex: "100" }} />}
      defaultEndIcon={<SubdirectoryArrowRightIcon style={{ zIndex: "100" }} />}
      defaultParentIcon={<ChevronRightIcon style={{ zIndex: "100" }} />}
      style={{
        flexGrow: "1",
        zIndex: "50",
        height: "100%",
        position: "relative",
      }}
    >
      {generateTableOfContents()}
      {placePlayfulImages()}
    </TreeView>
  );
}

export default TableOfContents;
