import React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import SocialMediaLinks from "../../../data/SocialMediaLinks.json";
import { ClassNameMap } from "@material-ui/styles";

type Props = {
  classes: ClassNameMap;
  localuSState: string;
};

export const LgUpSocialMediaSection = ({ classes, localuSState }: Props) => {
  function findAppropriateTwitter() {
    if (localuSState === "California") {
      return SocialMediaLinks.TwitterCA;
    } else {
      return SocialMediaLinks.Twitter;
    }
  }

  return (
    <Hidden mdDown>
      <Grid
        item
        style={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <IconButton
          onClick={() => {
            window.open(findAppropriateTwitter());
          }}
          className={classes.SocialMediaIcon}
        >
          <TwitterIcon
            className={classes.SocialMedia}
            style={{
              color: "#1DA1F2",
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => {
            window.open(SocialMediaLinks.YouTube);
          }}
          className={classes.SocialMediaIcon}
        >
          <YouTubeIcon
            className={classes.SocialMedia}
            style={{
              color: "#FF0000",
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => {
            window.open(SocialMediaLinks.Facebook);
          }}
          className={classes.SocialMediaIcon}
        >
          <FacebookIcon
            className={classes.SocialMedia}
            style={{
              color: "#4267B2",
            }}
          />
        </IconButton>
      </Grid>
    </Hidden>
  );
};
