import { makeStyles } from "@material-ui/core/styles";

const SponsorshipMainPageMS = makeStyles((theme) => ({
  SponsorsMainItem: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%"
    },
    maxWidth: 1240
  },
  SponsorshipTitle: {
    fontFamily: "'Bebas Neue', cursive",
    fontSize: "2rem",
    color: "white",
    textAlign: "center",
  },
  TextSponsorItem: {
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      width: "auto",
      minWidth: "auto",
      textAlign: "center"
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom:"8px"

    },
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "1.6rem",
    color: "white",
    height: "60px",
    //backgroundColor: "white",
    padding: "8px",
    minWidth: "385px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    paddingBottom:"20px"
  },
  ImageSponsorItem: {
    [theme.breakpoints.down("xs")]: {
      height: "auto", 
      maxHeight: "45px",     
      width: "auto",
    },
    [theme.breakpoints.down(300)]: {
      maxHeight: "30px",
    },
    //backgroundColor: "white",
    padding: "8px",
    width: "385px",
    height: "auto",
  },
  ImageSponsorItem2: {
    [theme.breakpoints.down("xs")]: {
      height: "auto", 
      maxHeight: "45px",     
      width: "auto",
    },
    [theme.breakpoints.down(300)]: {
      maxHeight: "30px",
    },
    //backgroundColor: "white",
    padding: "8px",
    width: "385px",
    height: "auto",
  },
}));

export default SponsorshipMainPageMS;
