import htmlentities from "he";

export function convertJsonToReadableArray(jsonRoleData) {
  var readableRoleData = [];
  constructMainTopics(jsonRoleData, readableRoleData);
  readableRoleData.sort((a, b) => a.Order - b.Order);

  constructSubTopics(jsonRoleData, readableRoleData);
  readableRoleData.forEach((element) => {
    element.SubTopics.sort((a, b) => a.Order - b.Order);
  });
  //console.log(readableRoleData)
  return readableRoleData;
}

function constructMainTopics(jsonRoleData, readableRoleData) {
  for (let index = 0; index < jsonRoleData.length; index++) {
    if (jsonRoleData[index].acf.parent_or_subtopic === "Main Topic")
      readableRoleData.push({
        SectionTitle: htmlentities.decode(jsonRoleData[index].title.rendered),
        SectionContent: jsonRoleData[index].acf.maintopic_description,
        Order: jsonRoleData[index].menu_order,
        Location: parseLocation(jsonRoleData[index]),
        SubTopics: [],
        ID: jsonRoleData[index].id,
      });
  }
}

function constructSubTopics(jsonRoleData, readableRoleData) {
  for (let index = 0; index < jsonRoleData.length; index++) {
    if (jsonRoleData[index].acf.parent_or_subtopic === "Subtopic") {
      const subTopicElement = jsonRoleData[index];
      for (
        let subTopicIndex = 0;
        subTopicIndex < readableRoleData.length;
        subTopicIndex++
      ) {
        if (readableRoleData[subTopicIndex].ID === subTopicElement.parent) {
          
          readableRoleData[subTopicIndex].SubTopics.push({
            Title: htmlentities.decode(subTopicElement.title.rendered),
            Order: subTopicElement.menu_order,
            ID: subTopicElement.id,
            SubtopicTitle: subTopicElement.acf.subtopic_title,
            Image: subTopicElement.acf.subtopic_image.url,
            Content: subTopicElement.acf.subtopic_description,
            ButtonLinkFlag: subTopicElement.acf.button_link,
            ButtonType: subTopicElement.acf.button_type,
            ButtonLink: subTopicElement.acf.button_link_url,
            ButtonLinkText: subTopicElement.acf.button_link_text,
            TextImage_Title: subTopicElement.acf.text_and_image_title,
            TextImage_Image: subTopicElement.acf.text_and_image_image,
            TextImage_Content: subTopicElement.acf.text_and_image_content,
            CurriculumStandardFlag: subTopicElement.acf.check_if_state_standard,
            CurriculumStandards: subTopicElement.acf.sleep_health_curriculum_standards,
          });
        }
      }
    }
  }
}

function parseLocation (topic) {
  if (topic.acf.location_on_picture === undefined){
    return;
  }
  if (topic.acf.location_on_picture.length > 0){
    return [
      parseInt(
        topic.acf.location_on_picture.substring(0, 1)
      ),
      parseInt(
        topic.acf.location_on_picture.substring(2)
      ),
    ];
  }
  else {
    return "";
  }
}