import React, { useRef, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import ForwardIcon from "@material-ui/icons/Forward";

//I made CreatePlayImage a component to deal with ref issues. The CreateRectangleButton I am leaving
//because it works and because I want to use it to remind me of the different perks of component vs
//function.
export default function PlayImageButton(props) {
  const {
    subTopicObject,
    setSelectedNode,
    setOpenBrowserPlayable,
    setOpenArticlatePlayable,
    classes,
    role,
  } = props;
  const playButtonRef = useRef(null);
  const [playButtonInfo, setPlayButtonInfo] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    assignPlayButtonInfo();
    return () => {};
  }, [playButtonRef]);

  function assignPlayButtonInfo() {
    if (playButtonRef.current !== null) {
      if (playButtonInfo.height === 0 || playButtonInfo.width === 0) {
        setPlayButtonInfo({
          height: playButtonRef.current.clientHeight,
          width: playButtonRef.current.clientWidth,
        });
      }
    }
  }

  function generateButton() {
    if (subTopicObject.ButtonLinkFlag === "Yes") {
      if (subTopicObject.ButtonType === "Video") {
        return (
          <IconButton
            onClick={() => {
              setSelectedNode(subTopicObject);
              setOpenBrowserPlayable(true);
            }}
          >
            <PlayCircleFilledIcon
              ref={playButtonRef}
              style={{
                zIndex: 100,
                fontSize: "7rem",
                color: "rgb(255, 255, 255, .9)",
                //backgroundColor: "rgb(255,255,255,0.5)",
                //backgroundColor: "radial-gradient(white, black)",
                borderRadius: "50%",
                // background: radial-gradient(circle at center, white 0, white 50%, transparent, 51%, transparent 100%)
              }}
            ></PlayCircleFilledIcon>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgb(45, 63, 94, .9)",
                height: playButtonInfo.height * 0.9,
                width: playButtonInfo.width * 0.9,
                borderRadius: "100%",
                boxShadow: "0px 5px 5px rgba(0,0,0,0.75)",
              }}
            ></div>
          </IconButton>
        );
      } else if (subTopicObject.ButtonType === "Articulate Storyline") {
        return (
          <IconButton
            onClick={() => {
              setSelectedNode(subTopicObject);
              setOpenArticlatePlayable(true);
            }}
          >
            <PlayCircleFilledIcon
              ref={playButtonRef}
              style={{
                zIndex: 100,
                fontSize: "7rem",
                color: "rgb(255, 255, 255, .9)",
                //backgroundColor: "rgb(255,255,255,0.5)",
                //backgroundColor: "radial-gradient(white, black)",
                borderRadius: "50%",
                // background: radial-gradient(circle at center, white 0, white 50%, transparent, 51%, transparent 100%)
              }}
            ></PlayCircleFilledIcon>
            <div
              style={{
                position: "absolute",
                backgroundColor: "rgb(45, 63, 94, .9)",
                height: playButtonInfo.height * 0.9,
                width: playButtonInfo.width * 0.9,
                borderRadius: "100%",
                boxShadow: "0px 5px 5px rgba(0,0,0,0.75)",
              }}
            ></div>
          </IconButton>
        );
      } else {
        return;
      }
    }
  }

  return <Grid item>{generateButton()}</Grid>;
}

export function PlayableRectangleButton(
  subTopicObject,
  setSelectedNode,
  setOpenBrowserPlayable,
  setOpenArticlatePlayable,
  handleUnfurlParent,
  classes,
  role,
) {
  if (subTopicObject.ButtonLinkFlag === "Yes") {
    if (
      subTopicObject.ButtonType === "Video" ||
      subTopicObject.ButtonType === "Text and Image"
    ) {
      return (
        <Button
          variant="outlined"
          onClick={() => {
            setSelectedNode(subTopicObject);
            setOpenBrowserPlayable(true);
          }}
          style={{
            color: "white",
            borderColor: "white",
            backgroundColor: "#c46f2d",
            width: "150px",
            borderRadius: 0,
            padding: "5px 5px",
          }}
        >
          <Grid container justify="space-between">
            <div style={{ width: "30px" }}></div>
            {subTopicObject.ButtonLinkText}
            <ForwardIcon style={{ fontSize: "30" }}></ForwardIcon>
          </Grid>
        </Button>
      );
    } else if (subTopicObject.ButtonType === "Articulate Storyline") {
      return (
        <Button
          variant="outlined"
          onClick={() => {
            setSelectedNode(subTopicObject);
            setOpenArticlatePlayable(true);
          }}
          style={{
            color: "white",
            borderColor: "white",
            backgroundColor: "#406296",
            width: "150px",
            borderRadius: 0,
            padding: "5px 5px",
          }}
        >
          <Grid container justify="space-between">
            <div style={{ width: "30px" }}></div>
            <Typography variant="button">
              {subTopicObject.ButtonLinkText}
            </Typography>
            <ForwardIcon style={{ fontSize: "30" }}></ForwardIcon>
          </Grid>
        </Button>
      );
    } else if (subTopicObject.ButtonType === "Website") {
      return (
        <Button
          variant="outlined"
          onClick={() => {
            window.open(`${subTopicObject.ButtonLink}`);
          }}
          className={classes.ExternalLinkButton}
        >
          <Typography variant="button">
            {subTopicObject.ButtonLinkText}
          </Typography>
        </Button>
      );
    }
  }
}
