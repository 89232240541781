import React from "react";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import USStateData from "../../../data/USStateData.json";
import InputLabel from "@material-ui/core/InputLabel";

export const StateFormControl = (props) => (
  <FormControl variant="outlined" className={props.classes.FormControlState}>
    <InputLabel className={props.classes.InputLabelForm}>
      State/Location
    </InputLabel>
    <Select
      label="State"
      value={props.localuSState}
      onChange={props.handleUSStateChange}
      className={props.classes.SelectButtons}
      inputProps={{
        classes: {
          root: props.classes.InnerSelectButtons,
        },
      }}
    >
      {USStateData.map((usState, index) => (
        <MenuItem key={index} value={usState.state}>
          <Typography
            variant={props.TypographySize}
            className={props.classes.SelectButtonText}
          >
            {usState.state}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
