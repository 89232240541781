import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { ClassNameMap } from "@material-ui/styles";

type Props = {
  classes: ClassNameMap
}

function WhoWeAreDescription({classes}: Props){
  //CSS/makeStyles from parent component AboutUs.js
  return (
    <Grid
      item
      container
      direction="row"
      justify="center"
      className={classes.InnerSectionTopMargin +
        " " +
        classes.InnerSectionBottomMargin +
        " " +
        classes.SectionSidePadding}
      style={{
        maxWidth: "100%",
      }}
    >
      <Grid
        container
        item
        justify="center"
        xs={12}
        className={classes.InnerSectionBottomMargin}
      >
        <Typography variant="h5" display="block" style={{ color: "black" }}>
          Who We Are
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Typography variant="body1" display="block" style={{ color: "black" }}>
        Let’s Sleep! is a joint initiative of Brigham & Women’s Hospital’s Division of Sleep & Circadian Disorders, led by Harvard Medical School’s sleep pioneer Dr. Charles Czeisler, and the nonprofit Healthy Hours, dedicated to promoting sleep health and raising public awareness about the relationship of sleep and developmentally appropriate school hours to physical, psychological, and educational well-being. Our team consists of sleep researchers, pediatricians and other medical professionals, psychologists, writers, and educators who share a common vision of creating compelling, accessible, and scientifically accurate sleep education materials for students, parents, teachers, and school administrators across the United States.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default WhoWeAreDescription;

