import { useEffect, useState } from "react";
import GenerateRoleLearningCenterRoutes from "../components/siteGenerator/helperFunctions/GenerateRoleLearningCenterRoutes";
import useVideoSectionAPI from "../hooks/useVideoSectionAPI";

export function useGenerateRoleRoutesEffect(
  generatedRoleRoutes,
  functionCallbacksForRoutes,
  stateInfo,
  windowHeight,
) {
  const videoSectionList = useVideoSectionAPI();
  const [roleRoutes, setRoleRoutes] = useState({
    studentRoutes: [],
    teacherRoutes: [],
    adminRoutes: [],
    parentRoutes: [],
  });
  useEffect(() => {
    //There's a better way to do this if statement.
    if (
      (videoSectionList.length > 1 &&
        generatedRoleRoutes.studentData.length > 1) ||
      (videoSectionList.length > 1 &&
        generatedRoleRoutes.teacherData.length > 1) ||
      (videoSectionList.length > 1 &&
        generatedRoleRoutes.teacherData.length > 1) ||
        (videoSectionList.length > 1 &&
          generatedRoleRoutes.parentData.length > 1)
    )
      setRoleRoutes({
        studentRoutes: GenerateRoleLearningCenterRoutes(
          generatedRoleRoutes,
          "Students",
          functionCallbacksForRoutes,
          stateInfo,
          windowHeight,
          videoSectionList,
        ),
        teacherRoutes: GenerateRoleLearningCenterRoutes(
          generatedRoleRoutes,
          "Teachers",
          functionCallbacksForRoutes,
          stateInfo,
          windowHeight,
          videoSectionList,
        ),
        adminRoutes: GenerateRoleLearningCenterRoutes(
          generatedRoleRoutes,
          "Administrators",
          functionCallbacksForRoutes,
          stateInfo,
          windowHeight,
          videoSectionList,
        ),
        parentRoutes: GenerateRoleLearningCenterRoutes(
          generatedRoleRoutes,
          "Parents",
          functionCallbacksForRoutes,
          stateInfo,
          windowHeight,
          videoSectionList,
        ),
      });

    return () => {};
  }, [generatedRoleRoutes, videoSectionList]);

  return roleRoutes;
}
