import { useEffect } from "react";

export function useResetContactMoveEffect(goToContact, setGoToContact) {
  useEffect(() => {
    if (goToContact) {
      setGoToContact(false);
    }

    return () => { };
  }, [goToContact]);
}
