import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useFirstPathname() {
  const [firstPath, setFirstPath] = useState("");
  const pathLocation = useLocation();

  useEffect(() => {
    setFirstPath(FindFirstPath(pathLocation));
  }, [pathLocation]);
  return firstPath;
}

function FindFirstPath(path) {
  var firstPathPart = path.pathname;
  var splitPathParts = firstPathPart.split("/");
  return splitPathParts[1];
}
