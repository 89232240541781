import { makeStyles } from "@material-ui/core/styles";

export const GridForStudentsLCMC = makeStyles((theme) => ({
  IconButtonPlacement: {
    color: "white",
    display: "block",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
  },
  PulsingButton: {
    background: "#2D3F5E",
    borderRadius: "50%",
    boxShadow: "0 0 0 0 rgba(0, 0, 0, 1)",
    //height: "200px",
    //width: "200px",
    transform: "scale(1)",
    "-moz-animation": `$pulseEffect 4000ms infinite`,
    animation: `$pulseEffect 4000ms infinite`,
    "-webkit-animation-name": `$pulseEffect`,
    "-webkit-animation-duration": "4000ms",
    "-webkit-animation-iteration-count": "infinite",
    // animation: `$pulseEffect 4000ms infinite`,
    // animation: `$pulseEffect 4000ms infinite`,
  },
  "@keyframes pulseEffect": {
    "0%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(45, 63, 94, 1)",
    },
    "50%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 20px rgba(45, 63, 94, 0)",
    },
    "100%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0px rgba(250, 195, 19, 0.75)",
    },
  },

  "@-webkit-keyframes pulseEffect": {
    "0%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(45, 63, 94, 1)",
    },
    "50%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 20px rgba(45, 63, 94, 0)",
    },
    "100%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0px rgba(250, 195, 19, 0.75)",
    },
  },
}));
