import React, { useState, useEffect } from "react";
import axios from "axios";

function useStateInfoAPI() {
  const [stateData, setStateData] = useState([]);

  const backendStateInfoRoute =
    "https://backendwp.letssleep.org/wp-json/wp/v2/state_info_section/?per_page=100";

  useEffect(() => {
    async function fetchMyAPI() {
      const [stateInfoResponse] = await Promise.all([
        axios.get(backendStateInfoRoute),
      ]);
      setStateData(convertStateJsonToArray(stateInfoResponse.data));
    }

    fetchMyAPI();
    return () => {};
  }, []);
  return stateData;
}

function convertStateJsonToArray(jsonStateData) {
  var readableStateData = [];

  for (let index = 0; index < jsonStateData.length; index++) {
    readableStateData.push({
      StateName: jsonStateData[index].acf.state_name,
      StateInformation: jsonStateData[index].acf.state_information,
    });
  }
  readableStateData.sort((a, b) => a.StateName.localeCompare(b.StateName));

  return readableStateData;
}

export default useStateInfoAPI;
