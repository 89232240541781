import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import { RoleFormControl } from "./helperFunctions/RoleFormControl";
import { StateFormControl } from "./helperFunctions/StateFormControl";
import LSLogo from "../../images/lslogo.jpg";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import LetSleepLandingPageMS from "../makeStyles/LetSleepLandingPageMS";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

type Props = {
  onClose: (state: string, role: string) => {};
  open: boolean;
};

function LetsSleepLandingPage({ onClose, open }: Props) {
  const classes = LetSleepLandingPageMS();
  const [role, setRole] = useState("Students");
  const [uSState, setUSState] = useState("California");

  const handleClose = () => {
    //Temporary: //Also it's capital S in Student to pass. Go figure.
    onClose(uSState, role);
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value);
  };

  const handleUSStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUSState(event.target.value);
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-951791051";
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  (window as any).dataLayer = (window as any).dataLayer || [];

  //The whole google ads logic needs to be encapsulated into its own
  //usEffect that needs then to be only called once I believe.
  useEffect(() => {
    gtag("js", new Date());

    gtag("config", "AW-951791051");
    return () => {};
  }, [(window as any).dataLayer]);

  function gtag(...args: any[]) {
    (window as any).dataLayer.push(args);
  }

  function gtag_report_conversion(url: any = "undefined") {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    gtag("event", "conversion", {
      send_to: "AW-951791051/-mfZCKaNxroCEMvb7MUD",
      event_callback: callback,
    });
    return false;
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperProps={{
          classes: { root: classes.DialogBox },
        }}
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.Welcome}
        >
          <Grid item className={classes.LogoContainer}>
            <img
              src={LSLogo}
              alt="Let's Sleep Logo"
              className={classes.Logo}
            ></img>
          </Grid>
          <Grid item>
            <Typography variant="h1" className={classes.WelcomeTitle}>
              Welcome to Let's Sleep!
            </Typography>
          </Grid>
          <Grid item className={classes.SubtitleContainer}>
            <Typography variant="h4" className={classes.Subtitle}>
              Your destination for resources on teen sleep health
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          className={classes.SelectionAndDescription}
        >
          <Grid item>
            <Typography variant="h5" className={classes.Instructions}>
              Please select a role and location so we can customize your
              experience:
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="center"
            className={classes.SubSelectionAndDesciption}
          >
            <Grid
              item
              container
              lg={4}
              md={8}
              xs={11}
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.DescriptionBox}
            >
              <Typography variant="h6" className={classes.DescriptionRoleTitle}>
                Students
              </Typography>
              <Typography variant="h6" className={classes.DescriptionText}>
                Explore videos, games, interactive activities, and other
                information that will help improve your sleep.
              </Typography>
              <Typography variant="h6" className={classes.DescriptionRoleTitle}>
                Parents
              </Typography>
              <Typography variant="h6" className={classes.DescriptionText}>
                Explore articles, videos, and webinars that will help you support your teen in getting better sleep. 
              </Typography>
              <Typography variant="h6" className={classes.DescriptionRoleTitle}>
                Teachers
              </Typography>
              <Typography variant="h6" className={classes.DescriptionText}>
                Access free resources such as our award-winning online program,
                Sleep 101, to teach students about sleep health.
              </Typography>
              <Typography variant="h6" className={classes.DescriptionRoleTitle}>
                School Administrators
              </Typography>
              <Typography variant="h6" className={classes.DescriptionText}>
                Learn how to improve sleep health in your community. Attend one
                of our free webinars (or view the video archive).
              </Typography>
            </Grid>
            <Grid
              item
              container
              lg={4}
              md={8}
              xs={11}
              direction="column"
              justify="center"
              spacing={6}
              className={classes.InputArea}
            >
              <Grid
                item
                container
                direction="row"
                className={classes.InputRows}
              >
                <Typography variant="h6" className={classes.OuterInputText}>
                  I want information for...
                </Typography>
                <RoleFormControl
                  classes={classes}
                  localRole={role}
                  handleRoleChange={handleRoleChange}
                  TypographySize={"h6"}
                ></RoleFormControl>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={classes.InputRows}
              >
                <Typography variant="h6" className={classes.OuterInputText}>
                  in...
                </Typography>
                <StateFormControl
                  classes={classes}
                  localuSState={uSState}
                  handleUSStateChange={handleUSStateChange}
                  TypographySize={"h6"}
                ></StateFormControl>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={classes.InputRows}
              >
                <Button
                  variant="contained"
                  className={classes.ContinueButton}
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={(event) => {
                    gtag_report_conversion();
                    handleClose();
                  }}
                >
                  <Typography variant="h6" className={classes.ButtonText}>
                    Continue
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
}

export default LetsSleepLandingPage;
