import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

//const API_PATH = "https://letssleep.org/api/contact/index.php";
//const API_PATH = + " /api/contact/index.php";
const API_PATH = window.location.origin + "/api/contact/index.php";

const useStyles = makeStyles((theme) => ({
  UserInfo: {
    paddingRight: 32,
    [theme.breakpoints.down(600)]: {
      paddingRight: 0,
    },
  },
  MessageSection: {
    paddingLeft: 32,
    [theme.breakpoints.down(600)]: {
      paddingLeft: 0,
      paddingTop: 16,
    },
  },
}));

function ContactUsForm(props) {
  const classes = useStyles();

  const handleContactSubmission = (event) => {
    event.preventDefault();
    axios({
      method: "post",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: informationSubmission,
    })
      .then((result) => {
        setInformationSubmission({
          ...informationSubmission,
          mailSent: result.data.sent,
        });
      })
      .catch((error) => {
        setInformationSubmission({
          ...informationSubmission,
          error: error.message,
        });
      });
  };

  const [informationSubmission, setInformationSubmission] = useState({
    fname: "",
    lname: "",
    email: "",
    message: "",
    mailSent: false,
    error: null,
  });

  return (
    <Grid
      container
      item
      direction="row"
      justify="center"
      style={{ maxWidth: 1280 }}
    >
      <Grid
        container
        sm={4}
        xs={12}
        item
        direction="column"
        justify="center"
        className={classes.UserInfo}
      >
        <Grid item style={{ padding: "0px 0 8px 0" }}>
          <TextField
            type="text"
            id="fname"
            name="firstname"
            fullWidth
            label="First Name"
            variant="filled"
            value={informationSubmission.fname}
            onChange={(e) =>
              setInformationSubmission({
                ...informationSubmission,
                fname: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item style={{ padding: "8px 0 8px 0" }}>
          <TextField
            type="text"
            id="lname"
            name="lastname"
            fullWidth
            label="Last Name"
            variant="filled"
            value={informationSubmission.lname}
            onChange={(e) =>
              setInformationSubmission({
                ...informationSubmission,
                lname: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item style={{ padding: "8px 0 0px 0" }}>
          <TextField
            type="email"
            id="email"
            name="email"
            fullWidth
            label="Email"
            variant="filled"
            value={informationSubmission.email}
            onChange={(e) =>
              setInformationSubmission({
                ...informationSubmission,
                email: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        sm={8}
        xs={12}
        justify="center"
        className={classes.MessageSection}
      >
        <TextField
          id="subject"
          name="subject"
          label="Message"
          multiline
          rows={8}
          //defaultValue=""
          variant="filled"
          fullWidth
          value={informationSubmission.message}
          onChange={(e) =>
            setInformationSubmission({
              ...informationSubmission,
              message: e.target.value,
            })
          }
        />
      </Grid>
      <Grid
        container
        item
        justify="center"
        xs={12}
        style={{ paddingTop: "1rem" }}
      >
        <Button
          type="submit"
          value="Submit"
          variant="contained"
          style={{ backgroundColor: "#2D3F5E", color: "white" }}
          onClick={(e) => handleContactSubmission(e)}
        >
          Submit
        </Button>
      </Grid>
      <Grid
        container
        item
        justify="center"
        xs={12}
        style={{ paddingTop: "1rem" }}
      >
        {informationSubmission.mailSent && (
          <div>Thank you for contacting us!</div>
        )}
      </Grid>
    </Grid>
  );
}

export default ContactUsForm;
