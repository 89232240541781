import { useEffect, useState } from "react";

export function useAllLearningRoutes(roleRoutes) {
  const [allLearningRoutes, setAllLearningRoutes] = useState([]);
  useEffect(() => {
    let arrayOfAllLCRoutes = [];

    for (const role in roleRoutes) {
      arrayOfAllLCRoutes = arrayOfAllLCRoutes.concat(roleRoutes[role]);
    }

    setAllLearningRoutes(arrayOfAllLCRoutes);
    
    return () => {};
  }, [roleRoutes]);

  return allLearningRoutes;
}
