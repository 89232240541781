import React, { useState, useCallback, useEffect } from "react";
import LearningCenterDialog from "./learningCenterDialog/LearningCenterDialog";
import LCDialogPopup from "./learningCenterDialog/LCDialogPopup";
import Grid from "@material-ui/core/Grid";
import LearningCenterSectionMS from "../../makeStyles/LearningCenterSectionMS";
import GridForStudentPictureLearningCenter from "./GridForStudentPictureLearningCenter";
import TableOfContents from "./TableOfContents";
import TeacherLCLandingPage from "./TeacherLCLandingPage";
import AdminCLLandingPage from "./AdminCLLandingPage";
import OverlayPictures from "./OverlayPictures";
import ArrowUp from "../../../images/ArrowUp.png";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import ParentsLCLandingPage from "./ParentsLCLandingPage";

function LearningCenterSection(props) {
  const classes = LearningCenterSectionMS(props);
  const {
    windowHeight,
    openDialog,
    learningCenterContent,
    roleChosen,
    handleChangeRole,
    studentLearningCenterData,
    teacherLearningCenterData,
    adminLearningCenterData,
    parentLearningCenterData,
  } = props;
  const [openLearningCenterDialog, setLearningCenterDialog] = useState(false);
  const [subjectToLearningCenter, setSubjectToLearningCenter] = useState("");
  const [updateRect, setUpdateRect] = useState(false);
  const [activeLearningRect, activeLearningRectRef] = useClientRect();
  const updateLearningRect = () => {
    setUpdateRect(true);
  };
  const updateTOCMessage = () => {
    if (!getTOCPosition) {
      setTOCPosition(true);
    }
  };
  const [studentTOCPositionX, setStudentTOCPositionX] = useState(undefined);
  const [studentTOCPositionY, setStudentTOCPositionY] = useState(undefined);
  const [getTOCPosition, setTOCPosition] = useState(false);

  //I need to get the x and y position and add something to indicate that is where it is.
  function SetValueStudentTOCPosition(pos) {
    setStudentTOCPositionX(pos.x);
    setStudentTOCPositionY(pos.y);
    setTOCPosition(false);
  }

  function useClientRect() {
    const [rect, setRect] = useState(null);
    const ref = useCallback(
      (node) => {
        if (node !== null) {
          setRect(node.getBoundingClientRect());
          setUpdateRect(false);
        }
      },
      [updateRect],
    );
    return [rect, ref];
  }
  useEffect(() => {
    updateTOCMessage();
    return () => {};
  }, []);

  const handleLearningCenterDialogOpen = (sectionTitle) => {
    setSubjectToLearningCenter(sectionTitle);
    setLearningCenterDialog(true);
  };

  const handleLearningCenterDialogClose = () => {
    setLearningCenterDialog(false);
    setSubjectToLearningCenter("");
  };

  const changeRoleCallback = (role) => {
    handleChangeRole(role);
  };

  useEffect(() => {
    if (openDialog) {
      handleLearningCenterDialogOpen(learningCenterContent);
    }

    return () => {};
  }, [openDialog]);

  useEffect(() => {
    window.addEventListener("resize", updateLearningRect);
    window.addEventListener("resize", updateTOCMessage);

    return () => {
      window.removeEventListener("resize", updateLearningRect);
      window.addEventListener("resize", updateTOCMessage);
    };
  }, []);

  function roleDescriptionIntro() {
    if (roleChosen === "Students") {
      return "Explore the resources below to help you get the sleep you deserve";
    } else if (roleChosen === "Teachers") {
      return "Let’s Sleep is your one-stop shop for resources and tools to educate your students on sleep, an essential component to any health curriculum";
    } else if (roleChosen === "Administrators") {
      return "Let’s Sleep! offers you all the resources you need to make sleep a priority in your district";
    } else if (roleChosen === "Parents") {
      return "Let’s Sleep! is your one-stop shop for useful information on sleep for you and your teen";
    }
  }

  const showByRole = () => {
    //For developing other sections:
    if (roleChosen === "Students") {
      return showStudentSection();
    } else if (roleChosen === "Teachers") {
      return showTeacherSection();
    } else if (roleChosen === "Administrators") {
      return showAdminSection();
    } else if (roleChosen === "Parents") {
      return showParentsSection();
    } else {
      return showStudentSection();
    }
  };

  //Consider moving ShowStudent/Teacher/Etc section their own components. I know there might be duplicate code. Or maybe not.
  const showStudentSection = () => {
    return (
      <Grid container item xs={12} className={classes.AspectRatioContainer}>
        <div className={classes.BinderRingsPlacement}></div>

        <Grid item xs={4} sm={3} md={2} className={classes.TableOfContents}>
          <TableOfContents
            onDialogClick={handleLearningCenterDialogOpen}
            LearningTree={studentLearningCenterData}
          />
        </Grid>
        {/* Consider making the below a "StudentLCLAndingPage" component like the teacher one */}
        <Grid
          container
          direction="row"
          item
          ref={activeLearningRectRef}
          xs={8}
          sm={9}
          md={10}
          className={classes.StudentPictureAreaLearningCenter}
        >
          <OverlayPictures classes={classes} />
          <GridForStudentPictureLearningCenter
            onDialogClick={handleLearningCenterDialogOpen}
            LearningTree={studentLearningCenterData}
            //assignButtonInstructions={assignTOCInstructions}
            GetStudent1_3Pos={SetValueStudentTOCPosition}
            SendResizeUpdate={getTOCPosition}
          />
          {studentTOCPositionX !== undefined ? (
            <Hidden smDown>
              <Grid
                container
                item
                direction="column"
                style={{
                  position: "absolute",
                  //marginLeft: "2rem",
                  //background: "rgba(45,63,94,0.75)",
                  left: studentTOCPositionX - 70 + "px",
                  top: "65%",

                  maxWidth: "200px",
                }}
              >
                <Grid
                  container
                  item
                  direction="row"
                  style={{ justifyContent: "center" }}
                >
                  <img
                    src={ArrowUp}
                    alt="Arrow Up"
                    className={classes.StudentButtonArrowIndication}
                  />
                </Grid>
                <Grid item container style={{ justifyContent: "center" }}>
                  <Typography
                    variant="body1"
                    align="center"
                    className={classes.StudentButtonTextIndication}
                    style={{
                      color: "black",
                      textAlign: "center",
                      maxWidth: "200px",
                      //textShadow: "white 0px 0px 10px",
                    }}
                  >
                    <b> CLICK ON AN ITEM</b>
                  </Typography>
                  <Typography
                    variant="caption"
                    align="center"
                    className={classes.StudentButtonTextIndicationUnder}
                    style={{
                      color: "black",
                      textAlign: "center",
                      display: "inline-block",

                      //textShadow: "black 0px 0px 5px",
                    }}
                  >
                    or select a topic in the Learning Center
                  </Typography>
                </Grid>
              </Grid>
            </Hidden>
          ) : (
            <div></div>
          )}
        </Grid>

        <LCDialogPopup
          open={openLearningCenterDialog}
          onClose={handleLearningCenterDialogClose}
          activeLearningRect={activeLearningRect}
          subjectTitle={subjectToLearningCenter}
          windowHeight={props.windowHeight}
          learningCenterTopics={studentLearningCenterData}
          role={roleChosen}
          changeRoleCallback={changeRoleCallback}
        ></LCDialogPopup>
      </Grid>
    );
  };

  const showTeacherSection = () => {
    return (
      <Grid container item xs={12} className={classes.AspectRatioContainer}>
        <div className={classes.BinderRingsPlacement}></div>

        <Grid item xs={4} sm={3} md={2} className={classes.TableOfContents}>
          <TableOfContents
            onDialogClick={handleLearningCenterDialogOpen}
            LearningTree={teacherLearningCenterData}
          />
        </Grid>
        <TeacherLCLandingPage
          ref={activeLearningRectRef}
          classes={classes}
          handleLearningCenterDialogOpen={handleLearningCenterDialogOpen}
        />

        <LCDialogPopup
          open={openLearningCenterDialog}
          onClose={handleLearningCenterDialogClose}
          activeLearningRect={activeLearningRect}
          subjectTitle={subjectToLearningCenter}
          windowHeight={props.windowHeight}
          learningCenterTopics={teacherLearningCenterData}
          role={roleChosen}
          changeRoleCallback={changeRoleCallback}
        ></LCDialogPopup>
      </Grid>
    );
  };

  const showAdminSection = () => {
    return (
      <Grid container item xs={12} className={classes.AspectRatioContainer}>
        <div className={classes.BinderRingsPlacement}></div>

        <Grid item xs={4} sm={3} md={2} className={classes.TableOfContents}>
          <TableOfContents
            onDialogClick={handleLearningCenterDialogOpen}
            LearningTree={adminLearningCenterData}
          />
        </Grid>
        <AdminCLLandingPage
          ref={activeLearningRectRef}
          classes={classes}
          handleLearningCenterDialogOpen={handleLearningCenterDialogOpen}
        />

        <LCDialogPopup
          open={openLearningCenterDialog}
          onClose={handleLearningCenterDialogClose}
          activeLearningRect={activeLearningRect}
          subjectTitle={subjectToLearningCenter}
          windowHeight={props.windowHeight}
          learningCenterTopics={adminLearningCenterData}
          role={roleChosen}
          changeRoleCallback={changeRoleCallback}
        ></LCDialogPopup>
      </Grid>
    );
  };

  const showParentsSection = () => {
    return (
      <Grid container item xs={12} className={classes.AspectRatioContainer}>
        <div className={classes.BinderRingsPlacement}></div>

        <Grid item xs={4} sm={3} md={2} className={classes.TableOfContents}>
          <TableOfContents
            onDialogClick={handleLearningCenterDialogOpen}
            LearningTree={parentLearningCenterData}
          />
        </Grid>
        {/* <AdminCLLandingPage
          ref={activeLearningRectRef}
          classes={classes}
          handleLearningCenterDialogOpen={handleLearningCenterDialogOpen}
        /> */}
        <ParentsLCLandingPage
          ref={activeLearningRectRef}
          classes={classes}
          handleLearningCenterDialogOpen={handleLearningCenterDialogOpen}
        ></ParentsLCLandingPage>

        <LCDialogPopup
          open={openLearningCenterDialog}
          onClose={handleLearningCenterDialogClose}
          activeLearningRect={activeLearningRect}
          subjectTitle={subjectToLearningCenter}
          windowHeight={props.windowHeight}
          learningCenterTopics={parentLearningCenterData}
          role={roleChosen}
          changeRoleCallback={changeRoleCallback}
        ></LCDialogPopup>
      </Grid>
    );
  };

  return (
    <div
      style={
        {
          // borderTop: "1px solid #2D3F5E",
          // borderRight: "1.5px solid #2D3F5E",
          // borderLeft: "1.5px solid #2D3F5E",
          // borderBottom: "1.5px solid #2D3F5E",
          // width: "90%",
          // margin: "auto",
          // marginTop: "3.125rem"
        }
      }
    >
      <Grid
        container
        item
        direction="row"
        justify="center"
        alignItems="center"
        style={{
          width: "100%",
          //margin: "auto",
          //height: "76px",
          minHeight: "76px",
          maxHeight: "500px",
          backgroundColor: "#FAC313",
          borderBottom: "2px solid #2D3F5E",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
        }}
      >
        <Grid xs={12} item>
          <Typography
            variant="h5"
            style={{ fontFamily: "'Bebas Neue', cursive", textAlign: "center" }}
          >
            Welcome {roleChosen}!
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography
            style={{
              fontFamily: "'Open Sans', sans-serif",
              textAlign: "center",
            }}
          >
            {roleDescriptionIntro()}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.WholeGridContainer}>
        {showByRole()}
      </Grid>
    </div>
  );
}

export default LearningCenterSection;
