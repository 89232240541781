import { makeStyles } from "@material-ui/core/styles";
import StudentLearningCenterPicture from "../../images/LearningCenterPicture.jpg";
import TeacherLearningCenterPicture from "../../images/TeacherLearningCenterPicture.png";
import AdminLearningCenterPicture from "../../images/AdminLearningCenterPicture.jpg";
import ParentLearningCenterPicture from "../../images/ParentsLearningCenterPicture.jpg";
import ParentLearningCenterPictureSmall from "../../images/ParentsLearningCenterPictureSmall.jpg";
import BinderRings from "../../images/PlayfulImages/SingleColumnBinderRings.png";
import TeacherLearningCenterPicture2 from "../../images/TeacherLearningCenterPicturev3.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
//import ParentVideoImage from "/../images/parentvideolanding.png";
import ParentVideoImage from "../../images/parentvideolanding.png";

const LearningCenterSectionMS = makeStyles((theme) => ({
  WholeGridContainer: {
    position: "relative",
    //Creates our aspect ratio
    width: "100%",
    paddingTop: "40%",
    height: 0,
    boxSizing: "border-box",
    borderBottom: "2px solid #2D3F5E",
    //border: "1.5px solid black,
    //margin: "auto"
  },
  AspectRatioContainer: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
    left: 0,
    // borderTop: "1px solid black",
    // borderBottom: "1px solid black"
  },
  TableOfContents: {
    width: "100%",
    height: "100%",
    backgroundColor: "#2D3F5E",
    color: "white",
    overflowY: "auto",
    overflowX: "hidden",
    // "scrollbar-width": "thin",
    // "scrollbar-color": "white #7a7a7a",
    // "&::-webkit-scrollbar": {
    //   width: "0.3rem",
    // },
    // "&::-webkit-scrollbar-track": {
    //   backgroundColor: "#7a7a7a",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "white",
    // },
    "scrollbar-color": "white #7a7a7a",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#525252",
      border: "1px solid white",
    },
    "&::-webkit-scrollbar-button:single-button": {
      backgroundColor: "white",
      display: "block",
      borderStyle: "solid",
      height: "12px",
      width: "16px",
    },
    "&::-webkit-scrollbar-button:single-button:vertical:decrement": {
      borderWidth: "0 5px 15px 5px",
      borderColor: "transparent transparent #525252 transparent",
    },
    "&::-webkit-scrollbar-button:single-button:vertical:increment": {
      borderWidth: "15px 5px 0px 5px",
      borderColor: "#525252 transparent transparent transparent",
    },
  },
  StudentPictureAreaLearningCenter: {
    backgroundImage: `url(${StudentLearningCenterPicture})`,
    backgroundPosition: "50% 60%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",

    //position: "relative"
  },
  TeacherPictureAreaLearningCenter: {
    //Old, no transparency:
    // backgroundImage: `url(${TeacherLearningCenterPicture})`,
    // backgroundPosition: "50% 30%",
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // width: "100%",
    // height: "100%",

    backgroundImage: `url(${TeacherLearningCenterPicture2})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 55%",
    backgroundSize: "100%",
    width: "100%",
    height: "100%",
    //For the purposes of the backgroundImage having opacity
    // position: "relative",
    // height: "100%",
    // width: "100%",

    // "&:before": {
    //   content: '""',
    //   backgroundImage: `url(${TeacherLearningCenterPicture2})`,
    //   backgroundSize: "cover",
    //   backgroundPosition: "50% 50%",
    //   position: "absolute",
    //   top: "0px",
    //   right: "0px",
    //   bottom: "0px",
    //   left: "0px",
    //   opacity: "1",
    // },
  },
  AdminPictureAreaLearningCenter: {
    backgroundImage: `url(${AdminLearningCenterPicture})`,
    backgroundPosition: "50% 37%",
    backgroundSize: "116%",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
    //Add breakpoint for widescreens.
    [theme.breakpoints.between(1300, 1600)]: {
      backgroundPosition: "50% 20%",
    },
    [theme.breakpoints.between(1100, 1300)]: {
      backgroundPosition: "50% 0%",
    },
    [theme.breakpoints.between(0, 1100)]: {
      backgroundImage: "none",
      backgroundColor: "#9ACED9",
      overflow: "auto",
    },
  },
  AdminLandingPageContent: {
    maxWidth: "100%",
    maxHeight: "50%",
    position: "relative",
    justifyContent: "space-around",

    //backgroundColor: "red",
    [theme.breakpoints.between(1300, 1600)]: {
      maxHeight: "62%",
    },
    [theme.breakpoints.between(1100, 1300)]: {
      maxHeight: "76%",
    },
    [theme.breakpoints.between(0, 1100)]: {
      maxHeight: "none",
      justifyContent: "space-evenly",
      // overflow: "none",
      // height: "100%"
      // position: "relative",
      // overflowX: "hidden",
    },
  },
  AdminSelectATopic: {
    [theme.breakpoints.down(3000)]: {
      top: "35%",
    },
    [theme.breakpoints.down(2000)]: {
      top: "45%",
    },
    [theme.breakpoints.down(1600)]: {
      top: "50%",
    },
    [theme.breakpoints.down(1500)]: {
      top: "55%",
    },
    [theme.breakpoints.down(1300)]: {
      top: "75%",
    },
    position: "absolute",
    //top: "55%",
    top: "35%",
    marginLeft: "2rem",
    //background: "rgba(45,63,94,0.75)",
    maxWidth: "450px",
  },
  AdminSelectATopicText: {
    [theme.breakpoints.down(1300)]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down(1100)]: {
      fontSize: "1rem",
    },
    color: "rgba(45,63,94,1)",
    textAlign: "center",
    fontSize: "1.75rem",
  },
  ParentLandingPageContentUpper: {
    [theme.breakpoints.down(960)]: {
      height: "50%",
    },
    [theme.breakpoints.down(400)]: {
      height: "auto",
    },
    maxWidth: "100%",
    height: "40%",
    position: "relative",
    justifyContent: "flex-end",

    //backgroundColor: "red",
    // [theme.breakpoints.between(1300, 1600)]: {
    //   maxHeight: "62%",
    // },
    // [theme.breakpoints.between(1100, 1300)]: {
    //   maxHeight: "76%",
    // },
    // [theme.breakpoints.between(0, 1100)]: {
    //   maxHeight: "none",
    //   justifyContent: "space-evenly",

    // },
  },
  ParentLandingPageContentLower: {
    maxWidth: "100%",
    maxHeight: "60%",
    position: "relative",
    justifyContent: "flex-end",
  },
  ParentPictureAreaLearningCenter: {
    backgroundImage: `url(${ParentLearningCenterPicture})`,
    backgroundPosition: "50% 70%",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
    //Add breakpoint for widescreens.
    [theme.breakpoints.down(960)]: {
      backgroundPosition: "50% 0%",
      backgroundImage: `url(${ParentLearningCenterPictureSmall})`,
    },
    [theme.breakpoints.between(1300, 1600)]: {
      //backgroundPosition: "50% 20%",
    },
    [theme.breakpoints.between(1100, 1300)]: {
      //backgroundPosition: "50% 0%",
    },
    [theme.breakpoints.between(0, 1100)]: {
      // backgroundImage: "none",
      // backgroundColor: "#9ACED9",
      overflow: "auto",
    },
  },
  ParentSelectATopic: {
    [theme.breakpoints.up(1950)]: {
      marginLeft: "2.5rem",
    },
    position: "absolute",
    top: "30%",
    marginLeft: "0.5rem",
    //background: "rgba(45,63,94,0.75)",
    maxWidth: "250px",
  },
  ParentSleepNumberText: {
    [theme.breakpoints.down(2561)]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down(1980)]: {
      fontSize: "1.25rem",
      //marginLeft: "3%"
    },
    [theme.breakpoints.down(1280)]: {
      fontSize: "0.85rem",
    },
    [theme.breakpoints.down(900)]: {
      fontSize: "0.75rem",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "0.65rem",
    },
    fontSize: "2rem",
    fontFamily: "'Open Sans', sans-serif",
    whiteSpace: "nowrap",
  },
  ParentPictureSleepNumber: {
    [theme.breakpoints.down(960)]: {
      marginLeft: "0.15rem",
      marginTop: "0rem",
    },
    marginLeft: "3rem",
    marginTop: "2rem",
  },
  ParentPictureSleepNumberImage: {
    [theme.breakpoints.down(960)]: {
      width: "85%",
    },
    [theme.breakpoints.down(600)]: {
      // width: "147.25px",
      // height: "23.95px",
      width: "100%",
      height: "auto",
    },
    [theme.breakpoints.down(400)]: {
      // width: "147.25px",
      // height: "23.95px",
      width: "100%",
      height: "auto",
    },
    width: "100%",
    height: "auto",
    cursor: "pointer",
  },
  ParentTitleText: {
    [theme.breakpoints.down(1280)]: {
      fontSize: "1.75rem",
    },
    [theme.breakpoints.down(1024)]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down(900)]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "0.85rem",
    },
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "2.5rem",
    //fontWeight: "bold",
    fontFamily: "'Bebas Neue', cursive",
  },
  ParentTitleTextUpper: {
    [theme.breakpoints.down(900)]: {
      marginRight: "4rem",
    },
    marginRight: "14rem",
  },
  ParentTitleTextMiddle: {
    [theme.breakpoints.down(900)]: {
      marginRight: "2rem",
    },
    marginRight: "8rem",
  },
  ParentTitleTextLower: {
    [theme.breakpoints.down(900)]: {
      marginLeft: "2rem",
    },
    marginLeft: "2rem",
  },
  LowerRightCornerParentVideo: {
    [theme.breakpoints.down(9000)]: {
      width: "323px",
      height: "174.4px",
    },
    [theme.breakpoints.down(2000)]: {
      width: "282.8px",
      height: "152.6px",
    },
    [theme.breakpoints.down(1550)]: {
      width: "226px",
      height: "122px",
    },
    [theme.breakpoints.down(1280)]: {
      width: "169.5px",
      height: "91.5px",
    },
    [theme.breakpoints.down(1000)]: {
      width: "146.9px",
      height: "79.3px",
    },

    backgroundImage: "url(" + ParentVideoImage + ")",
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  LowerRightCornerParentVideoText: {
    [theme.breakpoints.down(1280)]: {
      fontSize: "0.8rem",
    },
    fontFamily: "'Bebas Neue', cursive",
    fontSize: "1.25rem",
    whiteSpace: "nowrap",
  },
  PillPlacement: {
    [theme.breakpoints.between(600, 960)]: {
      left: "34.5%",
    },
    [theme.breakpoints.down("xs")]: {
      left: "41.5%",
    },
    maxWidth: "10%",
    maxHeight: "10%",
    position: "absolute",
    display: "block",
    transform: "translate(-50%,-50%)",
    top: "61%",
    left: "27.5%",
  },
  BinderRingsPlacement: {
    [theme.breakpoints.up(1950)]: {
      width: "70px",
    },
    [theme.breakpoints.up(2700)]: {
      width: "90px",
    },
    [theme.breakpoints.up(3300)]: {
      width: "100px",
    },
    backgroundImage: `url(${BinderRings})`,
    width: "60px",
    height: "98%",
    position: "absolute",
    top: "1.75%",
    left: "15.25%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    zIndex: "200",
    pointerEvents: "none",
    [theme.breakpoints.between(960, 1404)]: {
      left: "14.9%",
      width: "40px",
      opacity: 0.9,
    },
    [theme.breakpoints.between(600, 960)]: {
      width: "35px",
      left: "22.5%",
      opacity: 0,
    },
    [theme.breakpoints.down(600)]: {
      width: "30px",
      left: "30%",
      opacity: 0,
    },
  },
  TeacherLandingQuoteBlock: {
    [theme.breakpoints.up(960)]: {
      overflow: "hidden",
    },
    [theme.breakpoints.between(960, 1280)]: {
      minHeight: "100%",
    },
    [theme.breakpoints.down(960)]: {
      height: "100%",
      width: "100%",
    },
    width: "300px",
    height: "500px",
    maxHeight: "100%",
    backgroundColor: "rgba(0,0,0, 0.8)",
    overflowY: "auto",

    "scrollbar-width": "thin",
    "scrollbar-color": "white #7a7a7a",
    "&::-webkit-scrollbar": {
      width: "0.3rem",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "lightgray",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "gray",
    },
  },
  TeacherBlockQuote: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginInlineStart: "1em",
    marginInlineEnd: "1em",
  },
  TeacherLandingQuoteText: {
    [theme.breakpoints.between(0, 960)]: {
      fontSize: "0.9375rem",
    },
    [theme.breakpoints.between(960, 1280)]: {
      fontSize: "1rem",
    },
    color: "white",
    overflowWrap: "anywhere",
    fontSize: "1.125rem",
  },
  Sleep101AdvertContainer: {
    [theme.breakpoints.down(960)]: {
      paddingLeft: "0rem",
      maxHeight: "100%",
      height: "100%",
      width: "auto",
    },
    width: "100%",
    height: "auto",
    maxHeight: "100%",
  },
  Sleep101AdvertImage: {
    [theme.breakpoints.up(960)]: {
      width: "100%",
      height: "auto",
    },
    [theme.breakpoints.down(960)]: {
      width: "auto",
      height: "100%",
      borderBottom: "0px",
      borderTop: "0px",
    },
    "&:hover": {
      //opacity: 0.85,
      boxShadow: "2.5px 2.5px 5px #999",
      filter: "brightness(102%)",
      cursor: "pointer",
    },
    border: "6px solid #2D3F5E",
  },
  OverlayPictures: {
    "&:hover": {
      //opacity: 0.85,
      border: "2px solid #406296",
      boxShadow: "2.5px 2.5px 5px #999",
      filter: "brightness(104%)",
      cursor: "pointer",
    },
    //filter: "brightness(90%)",
    width: "50%",
    height: "auto",
    border: "2px solid #406296",
    //transition: ".1s ease",
  },
  AdminLandingPageQuotes: {
    fontFamily: "'Open Sans', sans-serif",
    fontStyle: "italic",
    color: "#2D3F5E",
    fontSize: "1.25rem",
    [theme.breakpoints.between(0, 1600)]: {
      fontSize: "1rem",
    },
  },
  AdminLandingPageTitle: {
    color: "#2D3F5E",
    fontFamily: "'Bebas Neue', cursive",
    fontSize: "3.75rem",
    textAlign: "center",
    [theme.breakpoints.between(1100, 1600)]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.between(0, 1100)]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.between(0, 600)]: {
      fontSize: "1.5rem",
    },
  },
  AdminLandingPageDescription: {
    fontFamily: "'Open Sans', sans-serif",
    color: "#2D3F5E",
  },
  AdminLandingPageDescriptionContainer: {
    width: "75%",
    [theme.breakpoints.between(0, 1100)]: {
      width: "90%",
    },
  },
  AdminLandingPageButton: {
    color: "white",
    backgroundColor: "#2D3F5E",
    border: "1px solid white",
    [theme.breakpoints.between(0, 600)]: {
      marginTop: "1rem",
    },
  },
  AdminLandingPageButtonText: {
    fontFamily: "'Bebas Neue', cursive",
    fontSize: "1.5rem",
    [theme.breakpoints.between(0, 1600)]: {
      fontSize: "1.25rem",
    },
  },
  StudentButtonArrowIndication: {
    [theme.breakpoints.down(1280)]: {
      height: "70px",
      width: "70px",
    },
    height: "100px",
    width: "100px",
  },
  StudentButtonTextIndication: {
    [theme.breakpoints.down(1280)]: {
      fontSize: "0.7rem",
    },
    fontSize: "1rem",
  },
  StudentButtonTextIndicationUnder: {
    [theme.breakpoints.down(1280)]: {
      maxWidth: "150px",
      fontSize: "0.7rem",
    },
    fontSize: "0.75rem",
  },
  TeacherLandingBlinkingArrow: {
    animation: `$moveArrowLeft 5000ms 3`,
    "-webkit-animation-name": `$moveArrowLeft`,
    "-webkit-animation-duration": "5000ms",
    "-webkit-animation-iteration-count": "3",
  },
  "@keyframes moveArrowLeft": {
    "0%": {
      opacity: 0,
      transform: "translateX(40px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(20px)",
    },
  },
  "@-webkit-keyframes moveArrowLeft": {
    "0%": {
      opacity: 0,
      transform: "translateX(40px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(20px)",
    },
  },

  //Use this to center an element when the parent is position:relative:
  // style={{position: "absolute", top:"50%", left: "50%", backgroundColor: "black", transform: "translate(-50%, -50%)"  }}
}));

export default LearningCenterSectionMS;
