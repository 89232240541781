import React from "react";
import { Route } from "react-router-dom";
import HeaderSection from "../headerSection/HeaderSection";
import LearningCenterSection from "../learningCenterSection/LearningCenterSection";
import VideoSection from "../videoSection/VideoSection";
import StateInformationSection from "../stateInformationSection/StateInformationSection";
import ContactUsSection from "../contactUsSection/ContactUsSection";
import SponshorshipMainPage from "../sponsorshipMainPage/SponshorshipMainPage";

function GenerateRoleLearningCenterRoutes(
  generatedRoleRoutes,
  role,
  functionCallbacks,
  stateInfo,
  windowHeight,
  videoSectionList,
) {
  let roleData = getRelevantRoleData(role, generatedRoleRoutes);
  //Did I need to use this roleData null check?
  //if (roleData === null) return;

  let treeItems = [];
  var LearningTree = roleData;

  //Generates Student, Teacher, Admin, etc
  generateLearningCenterRoutes();

  generateVideoRoutes();

  //There is a great need to standardize the string replacements so they are standard between GenerateroleLearningCenterRoutes, UnfurledParentNode, SubTopicNode, and VideoCards...ugh.

  function generateVideoRoutes() {
    for (let index = 0; index < videoSectionList.length; index++) {
      let pathTitle = videoSectionList[index].VideoTitle;
      pathTitle = pathTitle.replace(/[ ]/g, "_");
      pathTitle = pathTitle.replace(/[?!,]/g, "");
      pathTitle = pathTitle.replace(/[:-]/g, "");
      pathTitle = pathTitle.replace("/", "_");
      //console.log("/" + role + "/videos/" + pathTitle)
      treeItems.push(
        <Route path={"/" + role + "/videos/" + pathTitle}>
          <HeaderSection
            roleChosen={role}
            stateInfo={stateInfo}
            resetStateAndRole={functionCallbacks.handleSetStateAndRole}
            goToContactSection={functionCallbacks.handleGoToContactSection}
          />
          <LearningCenterSection
            windowHeight={windowHeight}
            roleChosen={role}
            handleChangeRole={functionCallbacks.handleChangeRole}
            studentLearningCenterData={generatedRoleRoutes.studentData}
            teacherLearningCenterData={generatedRoleRoutes.teacherData}
            adminLearningCenterData={generatedRoleRoutes.adminData}
            parentLearningCenterData={generatedRoleRoutes.parentData}
          />
          <VideoSection
            openedVideo={videoSectionList[index]}
            openDialog={true}
            scrollToVideoSection={true}
          />
          <StateInformationSection stateInfo={stateInfo} />
          <ContactUsSection
            scrollDown={functionCallbacks.goToContact}
            role={stateInfo.state}
          />
          <SponshorshipMainPage />
        </Route>,
      );
    }
  }

  function generateLearningCenterRoutes() {
    for (let index = 0; index < LearningTree.length; index++) {
      let pathTitle = LearningTree[index].SectionTitle.replace(/[ ]/g, "_");
      pathTitle = pathTitle.replace(/[?!]/g, "");
      pathTitle = pathTitle.replace(/[:-]/g, "");
      pathTitle = pathTitle.replace("/", "_");
      treeItems.push(
        <Route path={"/" + role + "/" + pathTitle}>
          <HeaderSection
            roleChosen={role}
            stateInfo={stateInfo}
            resetStateAndRole={functionCallbacks.handleSetStateAndRole}
            goToContactSection={functionCallbacks.handleGoToContactSection}
          />
          <LearningCenterSection
            windowHeight={windowHeight}
            openDialog={true}
            learningCenterContent={LearningTree[index].SectionTitle}
            roleChosen={role}
            handleChangeRole={functionCallbacks.handleChangeRole}
            studentLearningCenterData={generatedRoleRoutes.studentData}
            teacherLearningCenterData={generatedRoleRoutes.teacherData}
            adminLearningCenterData={generatedRoleRoutes.adminData}
            parentLearningCenterData={generatedRoleRoutes.parentData}
          />
          <VideoSection />
          <StateInformationSection stateInfo={stateInfo} />
          <ContactUsSection
            scrollDown={functionCallbacks.goToContact}
            role={stateInfo.state}
          />
          <SponshorshipMainPage />
        </Route>,
      );
      if (LearningTree[index].SubTopics.length > 0) {
        for (let j = 0; j < LearningTree[index].SubTopics.length; j++) {
          let pathTitle = LearningTree[index].SubTopics[j].Title.replace(
            /[ ]/g,
            "_",
          );
          pathTitle = pathTitle.replace(/[?!]/g, "");
          pathTitle = pathTitle.replace(/[:-]/g, "");
          pathTitle = pathTitle.replace("/", "_");
          treeItems.push(
            <Route path={"/" + role + "/" + pathTitle}>
              <HeaderSection
                roleChosen={role}
                stateInfo={stateInfo}
                resetStateAndRole={functionCallbacks.handleSetStateAndRole}
                goToContactSection={functionCallbacks.handleGoToContactSection}
              />
              <LearningCenterSection
                windowHeight={windowHeight}
                openDialog={true}
                learningCenterContent={LearningTree[index].SubTopics[j].Title}
                roleChosen={role}
                handleChangeRole={functionCallbacks.handleChangeRole}
                studentLearningCenterData={generatedRoleRoutes.studentData}
                teacherLearningCenterData={generatedRoleRoutes.teacherData}
                adminLearningCenterData={generatedRoleRoutes.adminData}
                parentLearningCenterData={generatedRoleRoutes.parentData}
              />
              <VideoSection />
              <StateInformationSection stateInfo={stateInfo} />
              <ContactUsSection
                scrollDown={functionCallbacks.goToContact}
                role={stateInfo.state}
              />
              <SponshorshipMainPage />
            </Route>,
          );
        }
      }
    }
  }
  return treeItems;
}

function getRelevantRoleData(role, generatedRoleRoutes) {
  switch (role) {
    case "Students":
      return generatedRoleRoutes.studentData;

    case "Teachers":
      return generatedRoleRoutes.teacherData;

    case "Administrators":
      return generatedRoleRoutes.adminData;

    case "Parents":
      return generatedRoleRoutes.parentData;
    default:
      return null;
  }
}

export default GenerateRoleLearningCenterRoutes;
