import { useEffect } from "react";
import ReactGA from "react-ga";

export function useGoogleAnalyticsEffect() {
  useEffect(() => {
    const trackingID = "UA-182173361-1";
    ReactGA.initialize(trackingID);
    ReactGA.pageview("Lets Sleep 'Home Page'");
    //Demographics?
    //Average time on website
    //About section clicks for different pages
    return () => { };
  }, []);
}
