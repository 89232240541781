import { makeStyles } from "@material-ui/core/styles";

const LetSleepLandingPageMS = makeStyles((theme) => ({
  DialogBox: {
    background: "#2D3F5E",
    display: "-webkit-flex",
    display: "flex",
  },
  Welcome: {
    background: "white",
    //height: "33%",
    maxWidth: "100%",
    paddingTop: "3rem",
    paddingBottom: "3rem",
    flex: "0 0 auto",
    "-webkit-flex": "0 0 auto",

    [theme.breakpoints.down("md")]: {
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
  },
  LogoContainer: {
    [theme.breakpoints.up("lg")]: {
      alignSelf: "flex-end",
      // marginBottom: "auto",
      position: "absolute",
      top: 12,
    },
    alignSelf: "center",
    marginBottom: "0",
  },
  Logo: {
    maxHeight: "125px",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "0px",
    },
  },
  WelcomeTitle: {
    fontFamily: "'Bebas Neue', cursive",
    color: "#2D3F5E",
    textAlign: "center",
  },
  SubtitleContainer: {
    [theme.breakpoints.up("lg")]: {
      //marginBottom: "auto",
    },
    marginBottom: "0",
  },
  Subtitle: {
    fontFamily: "'Open Sans', sans-serif",
    color: "#FAC313",
    fontWeight: 500,
    textAlign: "center",
  },
  SelectionAndDescription: {
    background: "#2D3F5E",
    flex: "0 0 auto",
    "-webkit-flex": "1 0 auto",
  },
  SubSelectionAndDesciption: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  Instructions: {
    color: "white",
    marginTop: "1rem",
    marginBottom: "1rem",
    fontFamily: "'Open Sans', sans-serif",
    textAlign: "center",
  },
  DescriptionBox: {
    borderRadius: "60px",
    border: "2px solid #FAC313",
    paddingBottom: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    order: 2,
    [theme.breakpoints.up("lg")]: {
      order: 0,
      //maxWidth: "500px",
    },
    // maxHeight: "500px",
    // maxWidth: "500px",
  },
  InputArea: {
    // maxHeight: "500px",
    // maxWidth: "500px",
    order: 1,
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      alignItems: "flex-end",
      //maxWidth: "500px",
    },
    [theme.breakpoints.down("lg")]: {
      marginBottom: "2rem",
    },
  },
  InputRows: {
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-end",
      alignItems: "center",
    },
  },
  DescriptionRoleTitle: {
    color: "#FAC313",
    textAlign: "center",
    marginTop: "1rem",
    fontFamily: "'Bebas Neue', cursive",
  },
  DescriptionText: {
    color: "white",
    textAlign: "center",
    fontFamily: "'Open Sans', sans-serif",
  },
  OuterInputText: {
    color: "white",
    display: "inline",
    fontFamily: "'Open Sans', sans-serif",
  },
  SelectButtons: {
    backgroundColor: "rgb(114,125,146)",
    color: "white",
  },
  InnerSelectButtons: {
    //padding: "0px 7px",
    paddingTop: "8px",
    paddingLeft: "8px",
    paddingBottom: "8px",
    paddingRight: "48px !important",
  },
  SelectButtonText: {
    fontFamily: "'Bebas Neue', cursive",
    letterSpacing: "0rem",
  },
  InputLabelForm: {
    fontFamily: "'Bebas Neue', cursive",
    color: "white",
    fontSize: "1rem",
    "&.Mui-focused": {
      color: "white",
    },
  },
  ContinueButton: {
    backgroundColor: "#FAC313",
  },
  ButtonText: {
    fontFamily: "'Bebas Neue', cursive",
  },
}));

export default LetSleepLandingPageMS;
