import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import IconButton from "@material-ui/core/IconButton";

export function LCDPlayableImage(props) {
  return (
    <Grid container justify="center" className={props.classes.InnerParentGrid}>
      <div className={props.classes.ExitWindow}>
        <div style={{ position: "absolute", right: "16px" }}>
          <IconButton
            onClick={props.handleClose}
            style={{
              color: "white",
              padding: "0",
              display: "block",
            }}
          >
            <CancelPresentationIcon className={props.classes.CancelIcon} />
          </IconButton>
        </div>
      </div>
      <Grid
        container
        item
        xs={12}
        className={props.classes.ContentDefinition}
        style={{
          backgroundImage: "url(" + props.playableContentImage + ")",
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          container
          direction="column"
          item
          xs={props.lessThan750px ? 12 : 6}
          style={{
            backgroundColor: "rgba(255,255,255,0.85)",
            color: "black",
            padding: "1rem",
          }}
        >
          <Grid item style={{ paddingBottom: "1rem" }}>
            <Typography variant="h5">
              {props.selectedNode.TextImage_Title.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {/*
              old way before we brought in json data from WordPress API
              {props.selectedNode.PlayableContent.Content.split("\n").map(
                (text, index) => (
                  <React.Fragment key={`-`}>
                    {text}
                    <br />
                    <br />
                  </React.Fragment>
                )
              )} */}
              <div
                dangerouslySetInnerHTML={{
                  __html: props.selectedNode.TextImage_Content,
                }}
              ></div>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
