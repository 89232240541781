import { createMuiTheme } from "@material-ui/core/styles";

export const LetsSleepTheme = createMuiTheme({
  palette: {
    primary: {
      //Light blue
      main: "#406296",
    },
    secondary: {
      //Green
      main: "#5BA300",
    },
    info: {
      //Dark blue
      main: "#2D3F5E",
    },
    warning: {
      //Yellow
      main: "#FAC313",
    },
    //#333 for bottom section about legality if we add that.
  },
});
