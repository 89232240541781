import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

type Props = {
  LogoAndText: string
}

export const BylineSection = ({LogoAndText}: Props) => (
  <Grid item className={LogoAndText}>
    <Grid container justify="center" direction="column">
      <Grid item>
        <Typography
          variant="subtitle2"
          display="block"
          style={{
            color: "black",
          }}
        >
          Practical advice for teens
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle2"
          display="block"
          style={{
            color: "black",
          }}
        >
          to sleep and live better
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);
