import React, { useState, useCallback, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Sleep101TeacherAdvert from "../../../images/TeacherSectionLanding/Sleep101TeacherAdvert.png";
import TeacherQuestionBlackboard from "../../../images/TeacherSectionLanding/teacherquestionblackboard.jpg";
import TroxelTeacher from "../../../images/TeacherSectionLanding/TroxelteacherSection.png";
import WebinarTeacher from "../../../images/TeacherSectionLanding/WebinarTeachers.jpg";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const TeacherLCLandingPage = React.forwardRef((props, ref) => {
  return (
    <Grid
      container
      direction="row"
      item
      ref={ref}
      xs={8}
      sm={9}
      md={10}
      className={props.classes.TeacherPictureAreaLearningCenter}
    >
      <Grid
        container
        direction="row"
        item
        justify="space-between"
        alignItems="center"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          position: "relative",
        }}
      >
        <Hidden smDown>
          <Grid
            container
            item
            md={4}
            sm={6}
            direction="row"
            alignItems="flex-start"
            style={{
              paddingTop: "5rem",
              alignSelf: "start",
              marginLeft: "1rem",
            }}
          >
            <Grid
              container
              xs={2}
              item
              justify="flex-end"
              style={{ alignSelf: "center" }}
            >
              <ArrowBackIosIcon
                className={props.classes.TeacherLandingBlinkingArrow}
                style={{ color: "white", fontSize: "4rem" }}
              ></ArrowBackIosIcon>
            </Grid>
            <Grid xs={10} item style={{ alignSelf: "center" }}>
              <Typography
                //variant="body1"
                style={{
                  color: "white",
                  fontSize: "1.75rem",
                  fontFamily: "'Bebas Neue', cursive",
                }}
              >
                <b>Select a topic</b>
              </Typography>
            </Grid>
          </Grid>
        </Hidden>

        <Grid
          container
          item
          className={props.classes.Sleep101AdvertContainer}
          md={4}
          sm={6}
          xs={12}
          justify="center"
          alignItems="center"
          style={{ margin: "auto" }}
        >
          <img
            src={Sleep101TeacherAdvert}
            className={props.classes.Sleep101AdvertImage}
            onClick={() => {
              props.handleLearningCenterDialogOpen("Sleep 101");
            }}
          ></img>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default TeacherLCLandingPage;
