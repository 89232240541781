import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  AppBar: {
    position: "sticky",
    borderBottom: "1.5px solid #2D3F5E",
    [theme.breakpoints.down("sm")]: {
      position: "static",
    },
    paddingRight: "0px !important",
  },
  TopHeaderGrid: {
    maxWidth: 1595,
    backgroundColor: "white",
    height: 76,
  },
  RoleStateButtons: {
    marginRight: "auto",
    [theme.breakpoints.down("lg")]: {
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  SelectButtons: {
    backgroundColor: "white",
    color: "#2D3F5E",
    //border: "1px solid #2D3F5E",
  },
  InnerSelectButtons: {
    padding: "0px 7px",
  },
  InputLabelForm: {
    color: "black",
    fontSize: "0.75rem",
  },
  FormControlState: {},
  FormControlRole: {},
  SecondRoleStateBox: {
    backgroundColor: "lightgray",
    padding: "1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.25rem",
    },
  },
  SocialMedia: {
    fontSize: "1.5rem",
    [theme.breakpoints.down(1200)]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  LogoAndText: {
    [theme.breakpoints.down("md")]: {
      marginRight: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "auto",
    },
    marginRight: "auto",
  },
  SocialMediaIcon: {
    padding: "10px",
  },
  SelectButtonText: {},
}));
