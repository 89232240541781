import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SleepNumberLogo from "../../../images/Partners/SleepNumberLogo.png";
import AASMLogo from "../../../images/Partners/AASMLogo.png";
import SponsorshipMainPageMS from "../../makeStyles/SponsorshipMainPageMS";
import SleeNumberLogoWhite from "../../../images/Partners/sleepnumber_white_logo.png";

function SponshorshipMainPage() {
  const classes = SponsorshipMainPageMS();

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      justify="center"
      //className={classes.WholeGridContainer}
      style={{
        backgroundColor: "#333",
        paddingTop: "3.125rem",
        paddingBottom: "3.125rem",
      }}
    >
      <Grid
        item
        xs={12}
        //className={classes.TitleSection}
        style={{ marginBottom: "3.125rem" }}
      >
        <Typography
          variant="h5"
          display="block"
          className={classes.SponsorshipTitle}
        >
          Thank you to our partners & sponsors
        </Typography>
      </Grid>
      <Grid
        container
        item
        direction="row"
        justify="center"
        spacing={6}
        className={classes.SponsorsMainItem}
      >
        <Grid
          container
          item
          sm={12}
          md={6}
          justify="center"
          alignItems="center"
        >
          <Typography className={classes.TextSponsorItem}>
            The Abbaszadeh Foundation
          </Typography>
        </Grid>
        <Grid
          container
          item
          sm={12}
          md={6}
          justify="center"
          alignItems="center"
        >
          <a href="https://www.sleepnumber.com/science">
            <img
              src={SleeNumberLogoWhite}
              alt="Sleep Number Logo"
              className={classes.ImageSponsorItem2}
            ></img>
          </a>
        </Grid>
        <Grid container item sm={12} md={6} justify="center">
          <Typography
            variant="body1"
            display="block"
            style={{
              fontFamily: "'Open Sans', sans-serif",
              fontSize: "1.25rem",
              color: "white",
            }}
          >
            <a href="https://aasm.org/">
              <img
                src={AASMLogo}
                alt="Sleep Number Logo"
                className={classes.ImageSponsorItem}
              ></img>
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SponshorshipMainPage;
