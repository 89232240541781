import React, { useState, useEffect } from "react";
import axios from "axios";
import { convertVideoJsonToReadableArray } from "../API/convertVideoJsonToReadableArray";

function useVideoSectionAPI() {
  const [videoBackendData, setVideoBackendData] = useState([]);

  const backendVideoRoute =
    "https://backendwp.letssleep.org/wp-json/wp/v2/featured_videos_section/?per_page=100";

  useEffect(() => {
    async function fetchMyAPI() {
      const [videoResponse] = await Promise.all([axios.get(backendVideoRoute)]);

      setVideoBackendData(convertVideoJsonToReadableArray(videoResponse.data));
    }
    fetchMyAPI();
  }, []);
  return videoBackendData;
}

export default useVideoSectionAPI;
