import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
//import LearningTree from "../Data/LearningCenterTree.json";
import Tooltip from "@material-ui/core/Tooltip";
import { GridForStudentsLCMC } from "../../makeStyles/GridForStudentsLCMC";

const GridForStudentPictureLearningCenter = (props) => {
  const { LearningTree } = props;
  //const { assignButtonInstructions } = props;
  const { GetStudent1_3Pos } = props;
  const { SendResizeUpdate } = props;
  const [randomIconLeftPosition, setRandomIconPosition] = useState(
    Math.random() * 4 + 48,
  );
  const [firstCycle, setFirstCycle] = useState(true);
  const buttonRef = useRef();

  const createSlightRandomPosition = () => {
    let randomPercentage = randomIconLeftPosition.toFixed(0).toString() + "%";
    return randomPercentage;
  };
  const classes = GridForStudentsLCMC();

  useEffect(() => {
    if (SendResizeUpdate) {
      getButtonPos();
    }
    return () => {};
  }, [SendResizeUpdate]);

  function getButtonPos() {
    if (buttonRef.current !== undefined){
      GetStudent1_3Pos(buttonRef.current.getBoundingClientRect());
    }
    
  }

  useEffect(() => {
    if (!firstCycle){
      //console.log("cycling")
      getButtonPos();
    }
    return () => {
    
    }
  }, [firstCycle])

  const build4x4Grid = () => {
    let grid = [];

    //This will get replaced with some properties passed into the stateless function from our data.
    let iconLocations = [];

    //console.log(LearningTree)
    //console.log(generatedGrid)

    if (LearningTree.length === 0) {
      return;
    }
    LearningTree.map((section) => {
      return iconLocations.push(section.Location);
    });

    //A 4x4 grid that is also equal to the amount of Grid xs={3} needed to get to 12.
    for (let index = 1; index <= 16; index++) {
      grid.push(
        <Grid
          key={index}
          item
          xs={3}
          style={{
            position: "relative",
            width: "100%",
          }}
        >
          {placePossibleIcon(iconLocations, index) && (
            <Tooltip
              title={getTitle(iconLocations, index, false)}
              arrow
              placement="right-start"
            >
              <IconButton
                size="small"
                ref={index === 3 ? buttonRef : null}
                onClick={() =>
                  props.onDialogClick(getTitle(iconLocations, index, true))
                }
                className={classes.IconButtonPlacement}
                style={{
                  left: createSlightRandomPosition(),
                }}
              >
                <AddCircleIcon
                  className={classes.PulsingButton}
                  fontSize="large"
                ></AddCircleIcon>
              </IconButton>
            </Tooltip>
          )}
        </Grid>,
      );
    }
    // assignTOCInstructions();
    //console.log(buttonRef.current.getBoundingClientRect())
    if (firstCycle){
      setFirstCycle(false);
    }
    return grid;
  };

  function getTitle(iconLocations, gridIndex, scrollToTopFlag) {
    if (scrollToTopFlag) {
      window.scrollTo({ top: 0 });
    }
    let locationIndex = iconLocations.filter(
      (location) => gridIndex === location[0] * 4 + (location[1] - 4),
    );

    let LearningTreeTitle = LearningTree.filter(
      (section) => section.Location === locationIndex[0],
    );
    let titleToSend = LearningTreeTitle[0].SectionTitle;
    return titleToSend;
  }

  function placePossibleIcon(iconLocations, gridIndex) {
    let locationHasIcon = iconLocations.filter(
      (location) => gridIndex === location[0] * 4 + (location[1] - 4),
    );

    if (locationHasIcon[0] !== undefined) {
      var location = JSON.stringify(locationHasIcon[0]);

      if (location === "[1,3]") {
        //console.log("true")
      }
    }

    return locationHasIcon.length > 0;
  }

  return (
    <Grid
      container
      item
      direction="column"
      alignContent="flex-start"
      alignItems="flex-start"
    >
      {build4x4Grid()}
    </Grid>
  );
};

export default GridForStudentPictureLearningCenter;
